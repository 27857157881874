@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  .cityStyles {
    color: #222222;
    font-family: Lato;
    font-size: $thirteen-pixel;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: $fifteen-pixel;
  }
  .stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    // width: 100%;
    width: 325px;
    position: relative;
    margin-top: 20px;
    .step {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      position: relative;

      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1.5px solid #696969;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #696969;
        z-index: 2;
      }

      .stepContent {
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: -22px;
        .stops {
          color: #696969;
          font-family: Lato;
          font-size: $nine-pixel;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: $eleven-pixel;
          margin-bottom: 8px;
          text-transform: uppercase;
        }
        .airportCard {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          .arrowIcon {
            width: 12px;
            height: 12px;
            cursor: pointer;
            transform: rotate(180deg);
          }
          .city {
            @extend .cityStyles;
            overflow: hidden;
            width: 130px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
          }
        }
        .stop {
          color: #696969;
          font-family: lato;
          font-size: $eight-pixel;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: $ten-pixel;
          text-transform: uppercase;
          margin-bottom: 5px;
        }
        .title {
          color: #222222;
          font-family: lato;
          font-size: $thirteen-pixel;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: $fifteen-pixel;
          word-wrap: break-word;
          word-break: break-word;
          overflow-wrap: break-word;
        }

        .card {
          width: 400px;
          min-height: 50px;
          box-sizing: border-box;
          margin-top: 10px;
        }
      }
      .lastStep {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -4px;
        gap: 8px;
        .arrivalImg {
          height: 16px;
          width: 20px;
        }
        .destination {
          @extend .cityStyles;
        }
      }

      .line {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        background-color: #696969;
        z-index: 1;
      }
    }
  }
}
