.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  background-color: #ffffff;
  .container {
    width: 100%;
    height: 100%;
    overflow: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    position: absolute;
    .content {

      position: relative;
      .titleCard {
        position: relative;
        display: block;
        padding-left: 20px;
        padding-top: 30px;
        padding-bottom: 10px;
        .title {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: bold;
        }
        .desc {
          color: #272727;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 12px;
        }
      }
      .list {
        position: relative;

        .listCard {
          position: relative;

          .showCard,
          .packageShowMoreCard {
            display: flex;
            text-align: center;
            justify-content: center;

            .showMoreBtn {
              color: #696969;
              font-weight: 600;
              font-family: lato;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: 0.2px;
              .showMoreIcon {
                width: auto;
                height: 20px;
                cursor: pointer;
              }
            }
            .showMoreBtn:hover {
              outline: none;
              background: none !important;
            }
          }
          .showCard {
            height: 50px;
          }
          .packageShowMoreCard {
            height: 50px;
          }
        }
        .buttonCard {
          margin-top: 150px;
          margin-bottom: 100px;
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
