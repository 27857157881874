.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  user-select: none;
  // padding-right: 28px;
  // margin-right: -19px;
  flex-grow: 1;
  }
  
  .rowContainer {
    display: flex;
    // gap: 25px;
    background-color: #FEFEFE;
    // background-color: blue;
  user-select: none;
}

.cardContainer {
  // background-color: blue;
  // z-index: 1;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    cursor: default;
  }
  user-select: none;
  width: 100%;
  position: relative;
  border: 1px solid rgb(34, 34, 34, 0.1);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5px 0px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .leftContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    user-select: none;
    .imageContainer {
      overflow: hidden;
      height: 65px;
      width: 65px;
      border-radius: 4px 0 0 4px;
      user-select: none;
      flex-shrink: 0;
      .imgTag {
        object-fit: cover;
        height: 65px;
        width: 65px;
        user-select: none;
      }
    }
    .cityName {
      font-size: 12px;
      line-height: 16px;
      color: #222222;
      font-family: Lato;
      text-transform: capitalize;
      user-select: none;
    }
  }
  .checkbox {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 5px;
    transform: translate(-50%, -50%);
    width: 18px;
    height: 18px;
    user-select: none;
  }
}

.dragIconContainer {
  // border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 0px;
  margin-right: -19px;
  height: 65px;
  // padding: 0 20px;
  width: 80px;
  align-self: flex-end;
  cursor: move;
  .dragTag {
    user-select: none;
    width: 12px;
    @media screen and (max-width: 600px) {
    }
  }
}

.btnCard {
  position: relative;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  padding-top: 10px;
  gap: 2px;
  
  //   z-index: 2;
  .cartBtn {
    border: 1px solid #222222;
    box-sizing: border-box;
    border: 1px solid #a3a9ad;
    border-radius: 50px;
    color: #7cbeea;
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0px;
    font-weight: 600;
    height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
    .iconBtnLeft {
      height: 100%;
      border-radius: 50px 0 0 50px;
      padding: 0 8px;
    }
    .iconBtnRight {
      height: 100%;
      border-radius: 0 50px 50px 0;
      padding: 0 8px;
    }
    .count {
      margin-left: 5px;
      margin-right: 5px;
      width: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img {
      height: 100%;
      width: 10px !important;
    }
  }

  .days {
    color: #8f8f8f;
    font-family: Lato;
    font-size: 10px;
    font-weight: Lato;
    letter-spacing: 0px;
    text-align: center;
  }
}

.opacityContainer {
  position: absolute;
  background-color: #fff;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  opacity: 0;
  // z-index: 1;
  z-index: 0;
}
