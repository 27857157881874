@import "../../../variables/font_size";
.mainContainer{
  margin: 0 -4px;
}
.headingItinerary {
  font-size: $twenty-pixel;
  font-weight: $bold;
  // color: #272727;
  color: #696969;
  padding-left: 25px;
  padding-bottom: 10px;
  padding-top: 8px;
}

.planeDepartureIcon {
  height: 16px;
  // width: 20px;
  cursor: pointer;
  margin-right: 15px;
}

.mainHeaderContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: -4px;
}

// Heading Component
.headingContainer {
  display: flex;
  gap: 1px;
  flex-direction: column;
  & h2 {
    font-weight: $bold;
    font-size: 12px;
    color: #272727;
    width: 100%;
  }
  .subHeadingContainer {
    display: flex;
    gap: 20px;
    & h1 {
      font-weight: $bold;
      font-size: 9px;
      text-transform: uppercase;
      color: #272727;
    }
  }
}

.activityContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.timeInADayContainer {
  display: flex;
  & span {
    position: absolute;
    left: -15px;
    top: 10px;
  }
}

.timeInADay {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  // border: 1px solid #efefef;
  border-radius: 4px;
  background-color: #ffffff;
  // box-shadow:
  //   rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  & h1 {
    font-weight: $bold;
    // color: #272727;
    color: #696969;
    text-transform: uppercase;
    padding-bottom: 11px;
    font-size: $thirteen-pixel;
  }
}

.descriptionContainer {
  display: flex;
  // padding-top: 2px;
  gap: 3px;
  color: #696969;
  font-size: $twelve-pixel;
}

.description {
  // color: #272727;
  color: #696969;
  font-size: $twelve-pixel;
  word-wrap: break-word;
  padding-bottom: 13px;
}
.description li {
  margin-left: 16px;
}

.customStyle {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.customFlightStyles {
  @extend .customStyle;
}
.summaryFlightClass {
  margin: 0 !important;
  padding-left: 10px !important;
  padding-right: 26px !important;
}
.flightAccord {
  margin-top: 5px;
  .headingFlightComp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .dayAndCity {
      color: #272727;
      font-family: Lato;
      font-size: 8.5px;
      font-weight: $bold;
      letter-spacing: 0;
      line-height: 10px;
    }
    .accordDesc {
      color: #272727;
      font-family: Lato;
      font-size: 12px;
      font-weight: $bold;
      letter-spacing: 0;
      line-height: 15px;
    }
    .planeDepartureIcon {
      // height: 14px;
      // width: 20px;
      cursor: pointer;
      margin-right: 15px;
    }
  }
  .accordFlightDetailsClass {
    padding: 0px !important;
  }
  .stepper {
    padding: 0px !important;
    .stepperDetails {
      padding-left: 40px;
      padding-top: 20px;
      padding-bottom: 25px;
    }
    .divider {
      //   border-bottom: 1px solid #696969;
    }
  }
}

.weatherWidgetContainer {
  margin-right: 15px;
}

/** */
.stepperContainer {
  width: 100%;
  position: relative;
  .circle {
    position: absolute;
    left: -21px;
    // left: -10px;
    width: 11px;
    height: 11px;
    border: 1px solid #696969;
    border-radius: 50%;
    top: 11px;
  }
  .line {
    // background-color: #696969;
    // width: 1px;
    border-left: 1px solid #696969;
    position: absolute;
    left: -16px;
    top: 25px;
    bottom: -9px;
  }
}
