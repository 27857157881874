.columnAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato !important;
    gap: 6px;
    & img {
      // width: 30px;
      height: 16px;
    }
  }