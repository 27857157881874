@import "/src/variables/font_size";
.mainContainer {
  .container {
    position: relative;
    .bookingLabel {
      letter-spacing: 0.21px;
      line-height: $eight-pixel;
      margin-bottom: 5px;
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $eleven-pixel;
      font-weight: 400;
      font-style: Regular;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .bookBtnStyles {
    border: 1px solid #979797;
    border-radius: 1px;
    box-sizing: border-box;
    height: 32px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    cursor: pointer;
  }
  .travelBookBtn,
  .activeBookTravelBtn {
    height: 40px;
    // width: 120px;
    width: min(50vw, 120px);
    @extend .bookBtnStyles;
    .dateCard {
      display: flex;
      align-items: center;
      justify-content: center;
      .dateBtn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        .calIcon {
          width: 13px;
          height: 15px;
          cursor: pointer;
          margin-left: 5px;
        }
        .dateInfo {
          font-size: $twelve-pixel !important;
          color: #222222;
          font-family: Lato;
          font-weight: $bold;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
        }

        .travelerLabel {
          color: #222222;
          font-family: Lato;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
          font-size: $nine-pixel;
          text-transform: uppercase;
        }
      }
    }
  }
  .travelBookBtn {
    background-color: #ffffff;
  }
  .activeBookTravelBtn {
    background-color: #d8efff;
  }

  .main {
    margin: 0;
    padding: 0;

    .label {
      color: #696969;
      font-family: Lato;
      font-size: $eight-pixel;
      font-weight: bold;
      letter-spacing: 0.58px;
      line-height: $ten-pixel;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-left: 2px;
    }
    .btnStyles {
      border: 1px solid #979797;
      border-radius: 4px;
      box-sizing: border-box;
      height: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      padding-left: 5px;
      padding-right: 10px;
      cursor: pointer;
    }
    .btn,
    .activeTravelBtn {
      @extend .btnStyles;
      background-color: #ffffff;

      .calendarIcon {
        height: 15px;
        width: 13px;
      }
      .btnName {
        color: #222222;
        font-family: Lato;
        font-size: $ten-pixel;
        font-weight: bold;
        letter-spacing: 0.71px;
        line-height: $thirteen-pixel;
      }
    }
    .activeTravelBtn {
      @extend .btnStyles;
      background-color: #d8efff;
    }
  }
  .main::before,
  .main::after {
    margin: 0;
    padding: 0;
  }
}
