@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .searchCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    .search {
      opacity: 1;
      width: 20px;
      height: 20px;
      cursor: pointer;
      margin: 5px;
    }
    .title {
      opacity: 1;
      color: rgba(105, 105, 105, 1);
      font-family: Lato;
      font-size: $fifteen-pixel;
      font-weight: 600;
      font-style: Bold;
      letter-spacing: 0.32px;
      text-align: left;
    }
  }
  .hideBtn {
    display: flex;
    align-items: center;
    gap: 5px;
    outline: none;
    border: none;
    background-color: transparent !important;
    .expandTitle {
      opacity: 1;
      color: rgba(105, 105, 105, 1);
      font-family: "Lato-Bold", sans-serif;
      font-size: $twelve-pixel;
      font-weight: $bold;
      font-style: Bold;
      letter-spacing: 0.2px;
      text-align: right;
      width: 50px;
      cursor: pointer;
    }
    .expandIcon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      transform: rotate(0deg);
      transition: transform 0.3s ease-in-out;
    }

    .hideIcon {
      cursor: pointer;
      width: 14px;
      height: 14px;
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
    }

    .expandIcon:hover,
    .hideIcon:hover {
      transform: rotate(360deg);
    }
  }
}
