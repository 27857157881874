@import "/src/variables/font_size";

.travelCard {
  padding-bottom: 20px;
  .main {
    margin: 0;
    padding: 0;
    .label {
      color: #696969;
      font-family: Lato;
      font-size: $eight-pixel;
      font-weight: bold;
      letter-spacing: 0.58px;
      line-height: $ten-pixel;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-left: 2px;
    }
    .airportNotAvail {
      color: #BF150D;;
      font-family: Lato;
      font-size: $nine-pixel;
      letter-spacing: 0.2px;
      line-height: $eleven-pixel;
      margin-bottom: 10px;
      margin-top: 5px;
      word-break: break-all;
      width: 90%;
      @media (min-width: 360px) and (max-width: 395px) {
        width: 280px; 
      }
    }
    .btnStyles {
      border: 1px solid #979797;
      border-radius: 4px;
      box-sizing: border-box;
      height: 32px;
      width: 314px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      padding-left: 10px;
      padding-right: 10px;
      @media (min-width: 360px) and (max-width: 395px) {
        width: 285px;    
      }
    }
    .btn,
    .activeBtn {
      @extend .btnStyles;
      background-color: #ffffff;
      cursor: pointer;
      .arrowIcon {
        height: 10px;
        width: 10px;
      }
      .card {
        position: relative;
        .cityButtonStyles {
          color: #222222;
          font-family: Lato;
          letter-spacing: 0.21px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 130px;
          @media (min-width: 360px) and (max-width: 395px) {
            width: 110px;    
          }
        }
        .btnName {
          @extend .cityButtonStyles;
          font-size: $ten-pixel;
          font-weight: bold;
          line-height: $thirteen-pixel;
        }
        .city {
          @extend .cityButtonStyles;
          font-weight: 500;
          line-height: $ten-pixel;
          font-size: $eight-pixel;
        }
      }
    }
    .activeBtn {
      @extend .btnStyles;
      background-color: #d8efff;
    }
  }
  .main::before,
  .main::after {
    margin: 0;
    padding: 0;
  }
}
