.imageContainer {
  display: flex;
  position: relative;
  overflow: hidden;
  // overflow-x: scroll;
  width: 100%;
  //   height: 130px;
  aspect-ratio: 1 / 0.5;
  // gap: 10px;
  // @media screen and (min-width: 768px) {
  //   // aspect-ratio: 1 / 0.3;
  //   width: 100%;
  //   height: 257px;
  // }
  .horizontalImages {
    width: 100%;
    // height: 1000px;
    position: absolute;
    aspect-ratio: 1 / 0.5;
    // object-fit: cover;
    /* THIS creates the animation! */
    // transform: translateX(100%);
    // @media screen and (min-width: 768px) {
    //   // aspect-ratio: 1 / 0.3;
    //   width: 100%;
    //   height: 257px;
    // }
    transition: transform 1s ease-out;
    padding: 0 1px;
    border-radius: 4.9px;

    &.addExtraPadd {
      padding: 0 3.11px;
    }
  }
  .dotsContainer {
    position: absolute;
    bottom: 7px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 2px;
    z-index: 2;

    .dot {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #ffffff;
      cursor: pointer;
      transition: all 0.3s ease;

      @media screen and (min-width: 769px) {
        &:hover {
          background-color: #272727;
          transform: scale(1.2);
        }
      }
    }

    .activeDot {
      background-color: #272727;
      width: 7px;
      height: 7px;
      border: 0.99px solid #ffffff;
      // box-shadow: 0 0 4px #ffffff;
    }
  }
  .rightBtn {
    position: absolute;
    z-index: 1;
    right: 2px;
    top: 50%;
    bottom: 50%;
    border-radius: 3.73px 0 0 3.73px;
    transform: translate(0, -50%);
    // aspect-ratio: 1 / 0.5;
    width: 42px;
    // background-color: rgba(0, 0, 0, 0.8);
    // fill: transparent;

    &.removeRightPadd {
      right: 0;
    }

    &.leftSwipeBtn {
      left: 2px;
      border-radius: 0 3.73px 3.73px 0;

      &.removeLeftPadd {
        left: 0;
      }
    }
  }
}
