@import "/src/variables/font_size";

.list {
  position: relative;
  .flightLoader {
    padding-top: 0px;
  }

  .nightsAndRoomsContainer {
    margin-bottom: 7px;
    padding-right: 14px;
    width: 100%;
    text-align: right;
    font-family: lato;
    font-size: 11px;
    font-weight: 400;
    color: rgb(39, 39, 39);

    span {
      font-size: 12px;
      font-weight: bold;
    }
  }

  .selectHotelText {
    width: 100%;
    text-align: center;
    font-family: lato;
    font-size: 13px;
    font-weight: 700;
    color: #cc0900;
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .listCard {
    position: relative;
    padding: 0px 2px 0px 2px;
    @media screen and (max-width: 767px) {
      padding: 0px 5px 0px 2px;
    }
    .radioControl {
      width: 100%;
      .radioGroup {
        width: 100%;
      }
    }
    .listCardContainer {
      padding: 0 !important;
      margin: 0 !important;
      .customFlightStyles {
        padding: 0 !important;
        margin: 0 !important;
      }
      .summaryClass {
        padding-left: 0px !important;
        margin: 0 !important;
      }
      .accordFlightDetailsClass {
        margin: 0 !important;
        padding: 10px 0px !important;
      }
      .headingFlightComp {
        margin-left: 15px !important;
        margin-right: 12px !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .checkInCheckout {
          color: #272727;
          font-family: Lato;
          font-size: $eight-pixel;
          font-weight: bold;
          letter-spacing: 0;
          line-height: $fourteen-pixel;
          text-transform: uppercase;
        }
        .city {
          color: #222222;
          font-family: Lato;
          font-size: $fourteen-pixel;
          font-weight: bold;
          letter-spacing: 0.3px;
          line-height: $sixteen-pixel;
          text-transform: uppercase;
        }
      }
      .hotelListing {
        position: relative;
        .showMoreBox {
          display: flex;
          align-items: center;
          justify-content: center;
          .showMoreBtn {
            color: #696969;
            font-weight: 600;
            font-family: lato;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.2px;
            .showMoreIcon {
              width: auto;
              height: 20px;
              cursor: pointer;
            }
          }
          .showMoreBtn:hover {
            outline: none;
            background: none !important;
          }
        }
      }
    }
    .showCard,
    .packageShowMoreCard {
      display: flex;
      text-align: center;
      justify-content: center;

      .showMoreBtn {
        color: #696969;
        font-weight: 600;
        font-family: lato;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.2px;
        .showMoreIcon {
          width: auto;
          height: 20px;
          cursor: pointer;
        }
      }
      .showMoreBtn:hover {
        outline: none;
        background: none !important;
      }
    }
    .showCard {
      height: 80px;
    }
    .packageShowMoreCard {
      height: 50px;
    }
  }
  .buttonCard {
    margin-top: 150px;
    margin-bottom: 20px;
  }
}
