.tabsContainer {
  display: flex;
  justify-content: flex-start;
  padding: 0 30px 0 20px;
  transition: all 300ms ease-in-out;
  border-bottom: 1px solid #9fcbee;
  // box-shadow: -0.001px 0.5px 0px #1d75bc;
  // box-shadow: inset 0px -0.5px 0px #1d75bc;
  width: 100%;
  overflow: hidden;
  gap: 15px;
  position: relative;

  @media screen and (max-width: 768px) {
    // box-shadow: 0px 0.5px 0px #1d75bc;
    transition: all 1s linear;
  }

  .testHover {
    position: absolute;

    &.active {
      font-weight: bold;
      width: var(--setSelectedWidth);
      height: 100%;
      left: var(--setLeftOffset);
      border: 1px solid #9fcbee;
      // box-shadow:
      //   -0.5px 0 0 #1d75bc,
      //   inset 0 0.5px 0 #1d75bc,
      //   0.5px 0 0 #1d75bc;
      border-bottom: none;
      border-radius: 3px 3px 0 0;
      background-color: #d8efff;
      // transform: translateX(-100%);
      display: none;

      @media screen and (max-width: 768px) {
        transition: all 500ms linear;
        display: block;
      }
      z-index: 0;
    }
  }

  .tab {
    color: #696969;
    font-family: Lato;
    font-size: 10px;
    z-index: 1;
    cursor: pointer;
    letter-spacing: 0.2px;
    line-height: 11.2px;
    text-align: center;
    padding: 5px 4px 3px 12px;

    @media only screen and (min-width: 768px) {
      padding: 5px 10px 3.5px 10px;
    }

    min-width: 57.7px;

    @media screen and (max-width: 768px) {
      transition: all 300ms linear;
    }

    @media screen and (max-width: 430px) {
      font-size: 2.6vw;
    }

    &.active {
      font-weight: bold;
      transition: all 300ms ease-in-out;
      border-radius: 3px 3px 0 0;
      @media screen and (min-width: 768px) {
        border: 0.5px solid #91c6f1;
        border-bottom: none;
        // box-sizing: border-box;
        // border: 1px solid #1d75bc;
        // box-shadow:
        //   -0.5px 0 0 #1d75bc,
        //   inset 0 0.6px 0 #1d75bc,
        //   0.5px 0 0 #1d75bc;
        // border-bottom: none;
        border-radius: 3.7px 3.7px 0 0;
        background-color: #d8efff;
      }
    }
  }
}
