@import "/src/variables/font_size";
.myTripsContainer {
  background-color: #fff;
  width: 100%;
  height: calc(100dvh - 60px);
  overflow: auto;
  scrollbar-width: none;
  padding: 24px 3.5px;

  .today {
    color: #696969;
    font-family: Lato;
    font-size: $twenty-pixel;
    font-weight: bold;
    letter-spacing: 0.4px;
    line-height: 22px;
    text-transform: capitalize;
    margin-bottom: 29px;
    margin-left: 34px;
  }

  .tabsClass {
    margin-bottom: 2px;
  }

  .upcomingTripSubContainer {
    margin-bottom: 33.33px;
    // padding-left: 4px;
    // padding-right: 4px;
    position: relative;

    & .bookedDate {
      text-align: end;
      margin-right: 2px;
      margin-top: 4px;
      font-size: $eight-pixel;
      color: #696969;
    }

    .iconWithBookAgainText {
      display: flex;
      position: absolute;
      left: 26.6px;
      bottom: -20.9px;
      padding-top: 4px;
      padding-left: 6.8px;
      padding-right: 9px;
      padding-bottom: 5px;
      border-radius: 0 0 3.75px 3.75px;
      display: flex;
      align-items: center;
      gap: 8px;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.5);
      p {
        font-size: $ten-pixel;
        color: #ffffff;
        // font-weight: 400;
        font-weight: bold;
        opacity: 0.9;
      }
      .nextIcon {
        width: 9.3px;
      }
    }
    .bookedDate {
      text-align: end;
      margin-right: 2px;
      margin-top: 4px;
      font-size: $eight-pixel;
      color: #696969;
      position: absolute;
      right: 0;
      bottom: -15px;
    }
  }
}
