.superContainer {
  position: relative;
  @media screen and (min-width: 700px) {
    overflow: hidden;
    height: calc(100dvh - 60px);
    // border: 5px solid green;
  }
}
.mainContainer {
  // border: 2px solid green;
  @media screen and (min-width: 700px) {
    overflow: auto;
  }
  // height: calc(100dvh - 60px);
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  // overflow: auto;
  align-items: flex-start;
  position: relative;
  padding: 30px 15px;
  padding-bottom: 110px;
  min-height: calc(100dvh - 60px);
  gap: 17px;
  height: 100%;
  padding-top: 100px;
  background-color: #FEFEFE;
}
.backIconBtn {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
  width: max-content !important;
  z-index: 2 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  gap: 5px !important;
  border-radius: 5px !important;
  color: #696969 !important;
  & img {
    width: 20px;
    margin-top: 0.15em;
  }
}

.chatIcon {
  width: 32px;
}
.heading {
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
}

.headingInfo {
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  margin-left: 52px;
  margin-bottom: 10px;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  position: absolute;
  top: -15%;
  bottom: 0;
  left: 0;
  right: 0;
  .logo {
    width: 152px;
  }
  & h1 {
    color: #47174c;
    font-weight: 800;
    font-size: 16px;
  }
}

.sectionContainer {
  display: flex;
  flex-direction: column;
  // gap: 3px;
  align-items: flex-start;
  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

.inputContainer {
  bottom: 0;
  position: fixed;
  @media screen and (min-width: 700px) {
    position: absolute;
  }
  left: 0px;
  right: 0px;
  padding: 0 10px;
  // padding-top: 15px;
  background-color: #FEFEFE;
  z-index: 1;
  // border: 1px solid green;
}

.input {
  color: #696969;
  // width: 100%;
  font-size: 13px;
  border: none;
  padding: 14px;
  padding-left: 20px;
  padding-right: 35px;
  outline-color: #2596be;
  outline-width: 1px;
  border-radius: 25px;
  // resize: none;
  outline: 1px solid #a4d1f0;
  -webkit-appearance: none;
}
.input::placeholder {
  color: #a3a9ad !important;
}

.constText {
  z-index: 2;
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  align-self: flex-start;
  margin-left: 52px;
  & h1 {
    padding-left: 52px;
    padding-right: 15px;
    font-size: 13px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
  }
  & p {
    padding-left: 52px;
    padding-right: 15px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
  }
}
