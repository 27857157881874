.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .heading {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    font-family: Lato;
  }
  .routeCard {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .info {
    color: #bf150d;
    font-family: Lato;
    font-size: 8px;
    letter-spacing: 0.51px;
    line-height: 10px;
  }
  
  .classChipContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}

