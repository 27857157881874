// .main {
//   position: relative;
//   .dropdownWrapper {
//     position: relative;
//     display: inline-block;
//     width: fit-content;
//     .dropDown {
//       appearance: none;
//       -webkit-appearance: none;
//       -moz-appearance: none;
//       background-color: #fff;
//       color: #333;
//       border: none !important;
//       border-radius: 5px;
//       padding: 10px;
//       padding-right: 40px;
//       cursor: pointer;
//       width: 100%;
//       .options {
//         background-color: #ffffff !important;
//         padding: 5px;
//         opacity: 1;
//         color: rgba(105, 105, 105, 1);
//         font-family: "Lato";
//         font-size: 10px;
//         font-weight: 500;
//         font-style: Bold;
//         letter-spacing: 0.22px;
//         text-align: left;
//         line-height: 18px;
//       }
//       .options:checked {
//         background-color: #F9F9F9 !important; /* Custom selected background color */
//       }

//       /* Style for the hover state */
//       .options:hover {
//         background-color: #F9F9F9 !important; /* Custom hover background color */
//       }
//     }
//   }

//   .dropDown:focus {
//     outline: none !important;
//     border: none !important;
//     box-shadow: none !important;
//   }
//   /* Style for the custom SVG icon */
//   .icon {
//     position: absolute;
//     right: 10px;
//     top: 50%;
//     transform: translateY(-50%);
//     width: 16px; /* Adjust icon size */
//     height: 16px;
//     pointer-events: none; /* Prevent interaction with the icon */
//   }
// }

.disable {
  pointer-events: none !important;
}
.main,
.disable {

  .dropdownWrapper {
    position: relative;
  }

  .dropDown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    width: 110px;
    .selectedValue {
      opacity: 1;
      color: rgba(105, 105, 105, 1);
      font-family: Lato;
      font-size: 10px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0.2px;
      text-align: right;
    }

    .upIcon,
    .dropIcon {
      width: 12px;
      height: 12px;
      transition: transform 0.3s ease;
    }

    .upIcon {
      transform: rotate(0deg);
    }

    .dropIcon {
      transform: rotate(180deg);
    }
  }

  .optionsList {
    position: absolute;
    top: 100%;
    left: -20px;
    width: 140px;
    border-radius: 5px;
    margin: 0;
    padding: 5px 10px 5px 10px;
    list-style: none;
    z-index: 1000;
    border: 1px solid rgba(151, 151, 151, 1);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
    @media only screen and (max-width: 767px) {
      left: -30px;
    }
  }
  .optionList {
    position: relative;
    .listCard,
    .listActiveCard {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .labelStyles {
        font-size: 14px;
        cursor: pointer;
        opacity: 1;
        font-family: "lato";
        font-size: 10px;
        font-weight: 600;
        font-style: Bold;
        letter-spacing: 0.24px;
        text-align: left;
        line-height: 18px;
      }
      .label {
        @extend .labelStyles;
        color: #696969;
      }
      .cancelLabel {
        @extend .labelStyles;
        color: #cc0900;
      }
      .dividerLine {
        border: 1px solid rgba(151, 151, 151, 1);
        opacity: 1;
        width: 100%;
      }
      .optionIcon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    }
    .listActiveCard {
      background-color: #f0f0f0;
    }

    .listCard:hover {
      background-color: #f0f0f0;
      color: #000;
    }
    .dividerLine {
      border-top: 0.1px solid #979797;
      opacity: 1;
    }
  }
}
