.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;

    .container {
        width: 100%;
        height: 100vh;
        background-color: #ffff;
        overflow: auto;
        -webkit-animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) both;
        animation: slide-in-left .5s cubic-bezier(.25, .46, .45, .94) both;

        .closeCard {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 15px 15px 0px 0px;
            cursor: pointer;

            .closeIcon {
                width: auto;
                height: 20px;
                cursor: pointer;
            }
        }
    }

    .emptyBox {
        background-color: #F3F3F3;
        width: 100%;
        height: 100vh;
    }

    @-webkit-keyframes slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0
        }

        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1
        }
    }

    @keyframes slide-in-left {
        0% {
            -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
            opacity: 0
        }

        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1
        }
    }
}

.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}