.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  // background-color: #E5E5E5;
  background-color: #f3f3f3;
  height: 100vh;
  overflow: hidden;
  @media only screen and (min-width: 1000px) {
    display: flex;
  }
  .layout {
    position: relative;
    .container {
      position: relative;
      display: flex;
      gap: 0px;

      .chat {
        margin-right: 10px;
      }

      .topDest {
        width: 100%;
        height: 100vh;
        overflow: auto;
        position: relative;
        background-color: #ffff;
        margin-right: 10px;
        @media only screen and (min-width: 1000px) {
          min-width: 450px;
          max-width: 600px;
        }
      }

      .itineraryDetails {
        width: 100%;
        height: 100vh;
        overflow: auto;
        position: relative;
        background-color: #f3f3f3;
        @media only screen and (min-width: 1000px) {
          min-width: 450px;
          max-width: 600px;
        }
      }

      // .selected {
      //     background-color: #E5E5E5 !important;
      // }
      .emptyCard {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding-right: 35px;
        padding-top: 20px;

        .contentStyles {
          font-family: Lato;
          color: #222222;
        }

        .userName {
          @extend .contentStyles;
          font-size: 14px;
          line-height: 1;
          font-weight: 600;
        }

        .email {
          @extend .contentStyles;
          font-size: 12px;
          line-height: 1;
          font-weight: 400;
        }

        .phone {
          @extend .contentStyles;
          font-size: 12px;
          line-height: 1;
          font-weight: 500;
        }

        .address {
          @extend .contentStyles;
          font-size: 12px;
          line-height: 1;
          font-weight: 500;
        }
      }
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}
::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
