.mainContainer {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 3px 20px !important;
  transition: padding 0.3s ease, margin 0.3s ease;
}

.activeMainContainer {
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0px 20px !important;
  transition: padding 0.3s ease, margin 0.3s ease;
}

.summary {
  margin: 0px -20px !important;
  padding: 0px 40px !important;
  color: #272727 !important;
  background-color: #F4F4F4 !important;
  border-top: 1px solid #bebebe !important;
  border-bottom: 1px solid #bebebe !important;
  min-height: 55px !important;
  transition: padding 0.3s ease, margin 0.3s ease, background-color 0.3s ease;
}

.lastSummary {
  border-top: none !important;
}

.activeSummary {
  margin: 0px -20px !important;
  padding: 0px 40px !important;
  color: #272727 !important;
  border-bottom: none !important;
  background-color: transparent !important;
  min-height: 50px !important;
  max-height: 50px !important;
  transition: padding 0.3s ease, margin 0.3s ease, background-color 0.3s ease;
}

.expandIcon {
  width: 15px;
}

.desc {
  width: 95%;
  margin-bottom: 10px !important;
  margin-left: 5px !important;
  transition: margin 0.3s ease;
}
