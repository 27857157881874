@import "/src/variables/font_size";

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding-right: 15px;
  margin-bottom: 17px;
  opacity: 0.9;
  .header {
    color: #ffffff;
    font-family: Lato;
    font-size: $seventeen-pixel;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 25.75px;
  }
}

.policiesContainer {
  max-height: 70dvh;
  overflow: auto;
  .policyRow {
    display: flex;
    flex-direction: column;
    gap: 14px;
    gap: 2.5vw;
    margin-top: 14px;
    margin-top: 3.09vw;

    // font common styles
    color: #272727;
    font-family: Lato;
    text-align: left;

    @media screen and (min-width: 768px) {
      gap: 12px;
      margin-top: 14px;
    }

    .heading {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;

      @media screen and (min-width: 768px) {
        font-size: $twelve-pixel;
        line-height: 18px;
      }
    }

    .description {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0;

      @media screen and (min-width: 768px) {
        font-size: $eleven-pixel;
        letter-spacing: 0.01px;
        line-height: 17px;
      }
      // p {

      // }
    }
  }
}
