.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #979797;
  @media only screen and (min-width: 767px) {
    border-bottom: none;
  }
  .container,
  .inActiveContainer {
    width: 100%;
    background-color: #ffffff;
    position: relative;
    // padding-bottom: 30px;
    .closeCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 10px 30px;
      @media only screen and (min-width: 767px) {
        display: none;
      }
      .closeButton {
        color: #696969;
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        font-family: Lato;
        letter-spacing: 0.2px;
        padding: 0 !important;
        margin-right: -2px !important;
        .closeIcon {
          cursor: pointer;
          height: 1em;
          width: auto;
        }
      }
    }

    .profileCard {
      position: relative;
      padding: 20px 18px 10px 30px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (min-width: 767px) {
        padding: 0vh 0vh 4vh 0vh;
      }
      .profileBox {
        display: flex;
        gap: 10px;
        @media only screen and (min-width: 767px) {
          gap: 5px;
        }
        .avatar {
          color: #7cbeea;
          background-color: #ffffff;
          border-color: #7cbeea;
          border: 1px solid #7cbeea;
        }

        .profileInfo {
          position: relative;
          display: block;
          margin: 0;
          .userName {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0px;
            color: #222222;
            text-transform: uppercase;
            @media only screen and (min-width: 767px) {
              font-size: 12px;
            }
          }

          .addresStyles {
            font-size: 12px;
            letter-spacing: 0px;
            font-family: Lato;
            font-weight: 600;
            @media only screen and (min-width: 767px) {
              font-size: 10px;
            }
          }

          .email {
            @extend .addresStyles;
          }

          .number {
            @extend .addresStyles;
          }

          .address {
            @extend .addresStyles;
          }
        }
      }
      .editCard {
        cursor: pointer;
        padding-right: 0px;
      }
    }

    .persBtnCard {
      position: absolute;
      bottom: 5px;
      right: 15px;
      color: white;
      border: none;
      cursor: pointer;
      padding-bottom: 30px;
      .personaliseBtn {
        color: #222222;
        font-size: 12px;
        letter-spacing: 0.2px;
        font-family: Lato;
        font-weight: 600;
        opacity: 0.7;
      }

      .arrowIcon {
        height: 1em;
        width: auto;
        margin-left: 5px;
        opacity: 1;
      }
    }
    .DeskBtnCard {
      margin-left: -5px;
      .deskBtn {
        color: #222222;
        font-size: 11px;
        letter-spacing: 0px;
        font-family: Lato;
        font-weight: 600;
        opacity: 0.7;
      }
      .arrowDeskIcon {
        height: 1em;
        width: auto;
        opacity: 1;
      }
    }
  }
  .container {
    padding-bottom: 30px;
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
