@import "/src/variables/font_size";
.main {
  // margin: 0;
  // padding: 0;
  // box-sizing: border-box;
  // justify-content: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  z-index: 1;
  .mainCard {
    width: 100%;
  }
  .closeIcon {
    width: 20px;
    cursor: pointer;
  }
  .container {
    overflow: auto;
    background-color: #fefefe;
    border-radius: 8px;
    width: 100%;
    .card {
      padding: 24px;
      .socialMedia {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        .facebookBtn {
          border: 1px solid #979797;
          border-radius: 4px;
          width: 100%;
          .facebookCard {
            cursor: pointer;
            background-color: #ffff;
            border-radius: 4px;
            height: 42px;
            align-items: center;
            flex-direction: row;
            .fbImg {
              height: 100%;
              object-fit: cover;
            }
            .facebookTitle {
              width: 100%;
              color: #272727;
              font-size: 11px;
              font-family: lato;
              letter-spacing: 0.5px;
              text-align: center;
              align-self: center;
            }
          }
        }
        .googleBtn {
          border: 1px solid #979797;
          border-radius: 4px;
          width: 100%;
          .googleCard {
            border-radius: 4px;
            align-items: center;
            background-color: #ffff;
            cursor: pointer;
            flex-direction: row;
            height: 42px;
            .googleImg {
              height: 100%;
              object-fit: cover;
            }
            .googleTitle {
              margin-left: -14px;
              width: 100%;
              color: #272727;
              font-size: 11px;
              font-family: lato;
              letter-spacing: 0.5px;
              text-align: center;
              align-self: center;
            }
          }
        }
      }
      .orCard {
        text-align: center;
        .orTitle {
          font-family: lato;
          font-weight: 600;
          font-size: $fourteen-pixel;
          letter-spacing: 0.9px;
        }
      }
      .inputCard {
        width: 100%;
        padding-left: 16px;
        padding-right: 16px;
        .nameCard {
          position: relative;
          align-self: start;
          width: 100%;
          .name {
            font-size: $eleven-pixel;
            margin-bottom: 6px;
            color: #5f6a70;
            font-weight: 500;
            font-family: Lato;
            opacity: 0.9;
          }
        }
        .emailCard {
          position: relative;
          align-self: start;
          width: 100%;
          .email {
            font-size: $eleven-pixel;

            margin-bottom: 6px;
            color: #5f6a70;
            font-weight: 500;
            font-family: Lato;
            letter-spacing: 0.1px;
          }
        }
      }
      .privacyCard {
        width: 100%;
        padding-left: 16px;
        .createAccount {
          color: #8f8f8f;
          font-family: Lato;
          font-size: $fourteen-pixel;
          a {
            text-decoration: none;
          }
          .privacy {
            color: #27a9e0;
            cursor: pointer;
            font-family: lato;
          }
        }
      }
    }
  }
  .createBtn {
    height: 80px;
    background-color: #1b75ba;
    border: none;
    box-shadow: none;
    outline: none;
    .btnName {
      color: #ffffff;
      font-weight: 600;
      font-size: 18px;
      font-family: lato;
      padding: 20px 0;
    }
  }
  .createBtn:hover {
    background-color: #1b75ba;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
