.arrivalDateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-top: -5px;
}
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  margin-bottom: -15px;
  z-index: 1 !important;
  .stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    // width: 280px;
    // position: relative;
    // word-wrap: break-word;
    // overflow-wrap: break-word;
    // white-space: normal;

    .step {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      position: relative;

      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1.5px solid #696969;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #696969;
        z-index: 2;
      }

      .stepContent {
        display: flex;
        flex-direction: column;
        position: relative;
        .arrivalOnDiff {
          padding-left: 25px !important;
        }
        .title {
          display: flex;
          flex-wrap: wrap;
          color: #696969;
          font-family: Lato;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          margin-top: -4px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          white-space: normal;
        }

        .card {
          width: 100%;
          margin-top: 10px;
          .subCard {
            display: flex;
            gap: 20px;
            flex-direction: column;
            position: relative;
            .airlineImgCard {
              position: absolute;
              top: 0%;
              left: -70px;
              z-index: 4 !important;
              .airlineImg {
                width: 30px;
                height: 30px;
              }
            }
          }
          .seatCard {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            .boardingBtn {
              color: #696969;
              font-family: Lato;
              font-size: 7.5px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 20px;
              // width: 100%;
              margin-top: -10px;
              .passIcon {
                width: 20px;
                height: 28px;
              }
            }
          }
        }
      }

      .line {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        background-color: #696969;
        z-index: 1;
      }
      .dashLine {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        border-left: 2px solid #ffce07;
        z-index: 1;
      }
      .square {
        position: absolute;
        top: 70%;
        left: -4.5px;
        transform: translateY(-5px);
        width: 8px;
        height: 8px;
        background-color: #fff;
        border: 1.5px solid #ffce07;
        z-index: 1;
      }
    }
  }
}
