.modal {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    // align-items: flex-start;
  }
  background: rgba(0, 0, 0, 0.85);
  overscroll-behavior: contain !important;
  //   padding-bottom: 50px;
  z-index: 101;
  .backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    display: none;
  }

  &.isOpen {
    // animation-name: modalShow;
    // animation-duration: 5s;
    -webkit-animation: modalShow 1s; /* Chrome, Safari, Opera */
    animation: modalShow 1s;
    // z-index: 100000;
    // display: flex;

    // @media screen and (max-width: 800px) {
    //   overflow-x: none;
    // }
    @keyframes modalShow {
      from {
        opacity: 0;
        display: none;
      }
      to {
        opacity: 1;
        display: flex;
      }
    }
  }
  &.hide {
    -webkit-animation: modalHide 0.5s; /* Chrome, Safari, Opera */
    animation: modalHide 0.5s;
    display: none;
    opacity: 0;
    @keyframes modalHide {
      from {
        opacity: 1;
        display: flex;
      }
      to {
        opacity: 0;
        display: none;
      }
    }
  }
}
