@import "/src/variables/font_size";

.customCheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  .label {
    color: #696969;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    text-align: left;
    cursor: pointer;
  }
  .selected {
    color: #272727;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 12px;
  }
  .inputBox {
    position: relative;
    .customCheckbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .disableCheck {
      height: 14px;
      width: 14px;
      background-color: transparent;
      border: 1.5px solid rgba(105, 105, 105, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      cursor: none;
      pointer-events: none;
    }
    .checkmark {
      height: 14px;
      width: 14px;
      background-color: transparent;
      border: 1.5px solid #696969;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      cursor: pointer;
    }
    .customCheckbox:checked ~ .checkmark {
      background-color: transparent;
    }

    .customCheckbox:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      content: "";
      width: 2px;
      height: 6px;
      margin-top: -2px;
      border: solid #696969;
      border-width: 0 1.5px 2px 0;
      transform: rotate(45deg);
      display: none;
      cursor: pointer;
    }
  }
}

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
  .label {
    color: #696969;
    font-family: Lato;
    font-size: $thirteen-pixel;
    line-height: 12px;
    font-weight: $bold;
    letter-spacing: 0.2px;
    text-align: left;
    cursor: pointer;
    height: 0px;
  }
  .selected {
    color: #272727;
    font-family: Lato;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.3px;
    line-height: 12px;
  }
  .inputBox {
    position: relative;
    width: 20px;
    height: 20px;
    border: none;
    outline: none;
    background-color: transparent !important;
    .customCheckbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    .disableCheck {
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1.5px solid rgba(105, 105, 105, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      cursor: none;
      pointer-events: none;
      margin-top: 5px;
    }
    .checkmark {
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1.5px solid #696969;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 5px;
    }
    .customCheckbox:checked ~ .checkmark {
      background-color: transparent;
    }

    .customCheckbox:checked ~ .checkmark::after {
      display: block;
    }
    .checkmark::after {
      content: "";
      width: 3px;
      height: 8px;
      margin-top: -2px;
      border: solid #696969;
      border-width: 0 1.5px 2px 0;
      transform: rotate(45deg);
      display: none;
      cursor: pointer;
    }
  }
}
