@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  position: relative;
  .airportCard {
    padding: 0px 0px 20px 20px;
    .content {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        .contentBox,
        .disableContentBox {
            position: relative;
            margin-top: 10px;
            .cityInfoCard {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 15px;
              margin-bottom: 20px;
              margin-top: -2px;
              .cityInfo {
                opacity: 1;
                color: #222222;
                font-family: "Lato-Bold", sans-serif;
                font-size: $fourteen-pixel;
                font-weight: $bold;
                font-style: Bold;
                letter-spacing: 0.3px;
                text-align: left;
              }
              .arrowIcon {
                width: 10px;
                height: 10px;
                transform: rotate(180deg);
              }
            }
            .newInputCard2 {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;
              .rightLeftIcon {
                width: 14px;
                height: 14px;
                margin-top: 10px;
                pointer-events: none;
              }
            }
            .datePicker {
              margin: 20px 0px 20px 0px;
              width: 120px;
              @media screen and (max-width: 600px) {
                width: 100px;
              }
            }
          }
          .disableContentBox {
            opacity: 0.5 !important;
            pointer-events: none !important;
          }
    }
 
  }
}
