@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  .flightCostCard {
    height: 100% !important;
    width: 100%;
    min-width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    // @media screen and (max-width: 600px) {
    //   min-width: 50px;
    // }
    .checkedIcon {
      width: 30px;
      height: 30px;
      pointer-events: none;
      margin-bottom: 10px;
      @media screen and (max-width: 600px) {
        width: 25px;
        height: 24px;
      }
    }
    .cost {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $twenty-pixel;
      font-weight: $bold;
      font-style: Bold;
      letter-spacing: 0.3px;
      text-align: center;
      line-height: $thirteen-pixel;
      padding: 0px 10px;
      // @media screen and (max-width: 600px) {
      //   font-size: $eightteen-pixel;
      //   line-height: $eleven-pixel;
      // }
    }
    .tax,
    .tax1 {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $nine-pixel;
      font-weight: $bold;
      font-style: Bold;
      letter-spacing: 0.1px;
      text-align: center;
      @media screen and (max-width: 600px) {
        font-size: $seven-pixel;
      }
    }
    .tax1 {
      margin-bottom: 5px;
    }
  }
}
