@import "/src/variables/font_size";
.mainContainer {
  justify-content: space-between;
  display: flex;
  align-items: center;
  // @media screen and (max-width: 350px) {
  //   width: 290px;
  // }
  // @media screen and (max-width: 360px) {
  //   width: 300px;
  // }
  // @media screen and (max-width: 370px) {
  //   width: 310px;
  // }
  // width: 300px;
  width: 243px;
  .counterTitleContainer {
    display: flex;
    flex-direction: column;
    & h1 {
      color: #696969;
      font-size: $eleven-pixel;
      font-family: Lato;
    }
    & p {
      color: #7cbeea;
      font-size: $seven-pixel;
      font-family: Lato;
    }
  }
}

.counterContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
  .button,
  .inActiveButton,
  .customDisabled {
    box-sizing: border-box;
    height: 33px;
    width: 30px;
    border: 1px solid #979797;
    border-radius: 3px;
    background-color: #ffffff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
    .buttonIcon {
      width: 14px;
    }
  }
  .count,
  .inActiveCount {
    box-sizing: border-box;
    height: 23px;
    width: 23px;
    border: 1px solid #979797;
    background-color: #d8efff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family: Lato;
  }
  .inActiveCount {
    background-color: transparent;
  }
  .inActiveButton {
    opacity: 0.2;
    cursor: pointer !important;
  }
  .customDisabled {
    &:disabled {
      cursor: pointer;
      pointer-events: all;
    }
  }
}
