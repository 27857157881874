.desktopScrollContainer {
    position: relative;
    @media screen and (min-width: 700px) {
      height: calc(100dvh - 60px);
      overflow: hidden;
    }
    @media screen and (min-width: 700px) {
      margin-bottom: 60px;
    }
  }
  
  .mainContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    // z-index: 999;
    padding-top: 15px;
    // padding-bottom: 100px;
    @media screen and (min-width: 700px) {
      height: 100%;
      overflow: auto;
    }
  }
  
  .shareSaveAdjustContainer {
    // background-color: blue;
    // position: absolute;
    // top: -50px;
    // right: 0;
    // padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
  }
  
  .stickyHeading {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    align-items: flex-start;
    .closeBtnContainer {
      padding-top: 5px;
      margin-left: 15px;
      margin-right: 13px;
      display: flex;
      justify-content: space-between;
      .priceContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        & h1 {
          font-size: 11px;
          font-weight: 600;
        }
        .price {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .buttonContainer {
      display: flex;
      gap: 5px;
      align-items: flex-start;
    }
  }
  
  .headingContainer {
    margin-top: -12px;
    margin-bottom: -5px;
    display: flex;
    align-items: center;
    // margin-left: 12px;
    margin-right: 24px;
    gap: 5px;
    justify-content: space-between;
    margin-left: 14px;
  
    .backBtn {
      // z-index: 2;
      height: 35px;
  
      margin-bottom: -1px;
      cursor: pointer;
      @media only screen and (min-width: 767px) {
        display: none;
      }
      @media screen and (max-width: 767px) {
        cursor: default;
      }
  
      // @media only screen and (min-width: 767px) {
      //   display: none;
      // }
    }
  
    .headingText {
      color: #696969;
      font-family: Lato;
  
      letter-spacing: 0;
      line-height: 24px;
      padding-left: 20px;
  
      & h1 {
        font-size: 15px;
        font-weight: 800;
      }
      & h2 {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
      @media only screen and (min-width: 767px) {
        padding-left: 25px;
        margin-bottom: 5px;
      }
    }
  }
  
  .placeAndPromptContainer {
    display: flex;
    justify-content: space-between;
    padding: 0 36px;
    padding-top: 20px;
    gap: 20px;
  
    .placeText {
      // width: 175px;
      word-wrap: break-word;
      color: #272727;
      font-family: Lato;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      font-weight: 500;
    }
  
    .dateDaysContainer {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
  
      .daysDateText {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: right;
      }
    }
  }
  
  .promptText {
    padding: 27px 36px;
    color: #696969;
    font-family: Lato;
    font-size: 14px;
    letter-spacing: 0.3px;
    line-height: 17px;
    padding-bottom: 32px;
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato;
  }
  
  .adjustButton {
    margin-top: 20px;
  }
  
  .iconButton {
    width: 24px;
  }
  
  // .rowAlignButton:disabled {
  //   background-color: #d5d5d5 !important;
  // }
  .rowAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato;
    padding-top: 14px !important;
    & span{
      margin-top: -2px;
    }
  }
  
  .columnAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    font-size: 12px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato !important;
    gap: 6px;
    & img {
      width: 24px;
    }
  }
  
  .inputContainer {
    margin-top: 20px;
    border-top: 1px solid #cacaca;
    background-color: #f4f4f4;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // padding-top: 20px;
    // @media screen and (min-width: 700px) {
    //    position: absolute;
    // }
    position: relative;
  }
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 88px;
    right: 0;
    z-index: 2;
    // height: 100%;
    // @media screen and (min-width: 700px) {
    //   height: 100dvh;
    // }
    .loaderContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  