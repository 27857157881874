@import "/src/variables/font_size";

.mainContainer {
  display: flex;
  flex-direction: row;
  // align-items: space-between;
  // justify-content: flex-start;
  width: 100%;
  padding: 20px 25px 20px 25px;
  .descriptionContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex-grow: 1;
    line-height: 12px;
    .headingText {
      font-size: $sixteen-pixel;
      font-weight: $bold;
      color: #696969;
      margin-bottom: 11px;
    }
    & > h1 {
      font-size: $twelve-pixel;
      font-weight: $bold;
      // color: #686868;
      color: #696969;
    }
    & > p {
      font-size: $twelve-pixel;
      // color: #818181;
      color: #696969;
    }
  }
}

.shareButtonContainer {
  align-self: flex-start;
  justify-self: flex-end;
  margin-top: -12px;
  margin-right: -30px;
  .rowAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: max-content;
    font-size: $nine-pixel !important;
    // transform: translateY(10px);
    font-weight: $bold !important;
    color: #696969 !important;
    font-family: Lato;
    // margin-top: -15px;
    & img {
      // width: 35px;
      width: 30px;
      // transform: translateY(15px);
    }
  }
}
