@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    position: relative;
    .label {
      letter-spacing: 0.21px;
      line-height: $eight-pixel;
      margin-bottom: 5px;
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $eleven-pixel;
      font-weight: 400;
      font-style: Regular;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .buttonStyles {
    border: 1px solid #979797;
    border-radius: 1px;
    box-sizing: border-box;
    height: 32px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    cursor: pointer;
  }
  .travelBtn,
  .activeTravelBtn {
    height: 40px;
    width: 120px;
    @extend .buttonStyles;
    .travelersCard {
      display: flex;
      align-items: center;
      justify-content: center;
      .travelerBtn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        .calendarIcon {
          width: 13px;
          height: 15px;
          cursor: pointer;
          margin-left: 5px;
        }
        .totalTravelers {
          font-size: $twelve-pixel !important;
          color: #222222;
          font-family: Lato;
          font-weight: $bold;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
        }

        .travelerLabel {
          color: #222222;
          font-family: Lato;
          font-weight: 400;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
          font-size: $nine-pixel;
          text-transform: uppercase;
        }
      }
    }
  }
  .travelBtn {
    background-color: #ffffff;
  }
  .activeTravelBtn {
    background-color: #d8efff;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
