.main {
  .onlyRoomCard {
    margin-bottom: 20px;
    .roomHeading {
      color: #222222;
      font-size: 14px;
      font-weight: bold;
      font-family: Lato;
 
    }
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0 5px;
  }
  
  .heading {
    color: #222222;
    font-size: 14px;
    font-weight: bold;
    font-family: Lato;
  }
  
  .counterContainer {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  
  }
  
}