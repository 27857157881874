@import "/src/variables/font_size";
.loaderContainer {
  z-index: 999;
  background: rgba(0, 0, 0, 0.9);
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContainer {
  background-color: #fff;
  width: 100%;
  height: calc(100dvh - 60px);
  overflow: auto;
  scrollbar-width: thin;
  padding: 24px 3.5px;
  .tabsClass {
    margin-bottom: 15px;
  }
}
/* Hide scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.mainContainer::-webkit-scrollbar {
  width: 0; /* Hides the vertical scrollbar */
  height: 0; /* Hides the horizontal scrollbar */
}

/* Hide scrollbar for Firefox */
.mainContainer {
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
}

.headerContainer {
  padding: 0px 5px;
  padding-right: 15px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  // margin-bottom: -10px;
  .columnAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato !important;
    gap: 6px;
    & img {
      width: 22px;
      // height: 22px;
    }
  }

  .divider {
    left: 0;
    border: 0.1px solid #979797;
    width: calc(100% + 27px);
    transform: translateX(-8.5px);
    margin-top: 13px;
    opacity: 0.7;
    margin-bottom: 10px;
  }

  .modifyHotelsHeader {
    width: 100%;
    text-align: center;
    font-family: Lato;
    font-weight: bold;
    font-size: 18.7px;
    // padding-left: 8px;

    &.valueDifference {
      width: 100%;
      font-weight: 700;
      text-align: end;
    }
  }
}

.customStyle {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flightAccord {
  position: relative;
  margin-top: 10px;
  .customFlightStyles {
    @extend .customStyle;
    .summaryFlightClass {
      margin: 0 !important;
      padding-left: 10px !important;
      padding-right: 26px !important;
      .headingFlightComp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dayAndCity {
          color: #272727;
          font-family: Lato;
          font-size: $eight-pixel + 0.5px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 11px;
        }
        .accordDesc {
          color: #272727;
          font-family: Lato;
          font-size: $twelve-pixel;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 15px;
        }
        .traveler {
          color: #272727;
          font-family: Lato;
          font-size: $nine-pixel;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 12px;
        }
        .planeDepartureIcon {
          height: 14px;
          width: 20px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
  }
  .accordFlightDetailsClass {
    padding: 0px !important;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 6.3px;
    margin-top: 12.45px;
    margin-bottom: 9px;
    padding-top: 6.3px;
    padding-bottom: 6.3px;
    padding-right: 0px;
    // border: 0.5px solid #979797;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%;

    .twoSectionsContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }
    .card3 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .flightBtn {
        font-family: Lato;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: none;
        padding: 0;
        // margin-right: -60%;
        .viewDetails {
          color: #696969;
          font-family: Lato;
          font-size: $six-pixel + 0.6px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 8px;
          text-align: center;
        }
        .iconAndText {
          display: flex;
          flex-direction: column;
          align-items: center;
          .expIcon {
            width: 14px;
            height: 10px;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
      .flightBtn:hover {
        background-color: transparent !important;
      }
    }
    .hotelImg {
      // width: 66px;
      height: 63.23px;
      border: 0.51px solid #979797;
      border-radius: 4px;
      box-sizing: border-box;
      object-fit: cover;
      aspect-ratio: 1;
    }
    .packageImg {
      width: 40px;
      height: 40px;
      border: 0.51px solid #979797;
      border-radius: 8px;
      box-sizing: border-box;
    }
    .contentCard {
      position: relative;
      display: block;
      // margin-top: -8px;
      // min-width: 130px;
      // max-width: 100%;
      .starContainer {
        display: flex;
        align-items: center;
        gap: 2.5px;
        margin-left: -3px;

        .starPropertyImg {
          width: 9px;
          @media screen and (min-width: 768px) {
            width: 16.35px;
          }
        }
        .star {
          color: #272727;
          font-family: Lato;
          font-size: $six-pixel;
          letter-spacing: 0.12px;
          line-height: 5.4px;

          @media screen and (min-width: 768px) {
            font-size: $ten-pixel + 0.8px;
            letter-spacing: 0.221px;
          }
        }
      }
      .hotelName {
        color: #252525;
        font-family: lato;
        font-size: $nine-pixel + 0.4px;
        letter-spacing: 0.64px;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        margin-top: 5px;

        // width: 130px;
      }
      .location {
        color: #252525;
        font-family: Lato;
        font-size: $seven-pixel + 0.4px;
        letter-spacing: 0.19px;
        line-height: 10.44px;
        text-transform: uppercase;
        // margin-top: -4px;
        margin-bottom: 8px;
      }

      .roomDetailsContainer {
        display: flex;
        flex-direction: column;

        .room {
          font-family: Lato;
          font-weight: bold;
          color: #252525;
          font-size: $six-pixel + 0.23px;
          line-height: 7.5px;
          letter-spacing: 0.13px;
        }
      }
      .amenities {
        color: #696969;
        font-family: Lato;
        font-size: $six-pixel + 0.6px;
        letter-spacing: 0.2px;
        line-height: 8px;
      }
    }
  }
}

.footerSection {
  width: 100%;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tapAboveToSelect {
    text-align: center;
    color: #cc0900;
    font-family: Lato;
    font-weight: bold;
    font-size: 12.06px;
    margin-bottom: 18px;
  }
  .hoteltBtn {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0;
    padding-bottom: 0.5px;
    height: 30px;

    &.notSelected {
      opacity: 0.2;
    }
  }
  .ignoreStyles {
    margin-top: 11px;
    text-align: center;
    color: #696969;
    font-family: Lato;
    font-size: $eleven-pixel;
  }
}

.noHotel {
  text-align: center;
  color: #696969;
  font-family: Lato;
  font-size: 14px;
}
