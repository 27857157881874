@import "/src/variables/font_size";

.mainContainer {
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #fdfdfd;
  box-shadow: 0 0.6px 1px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  padding: 15px;
  padding-bottom: 90px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  // @media screen and (max-width: 350px) {
  //   max-width: 340px;
  // }
  // @media screen and (max-width: 360px) {
  //   max-width: 350px;

  // }
  // @media screen and (max-width: 370px) {
  //   max-width: 360px;
  // }
  max-width: 350px;
}
.anchorElement {
  //   background-color: blue;
}
.buttonCard {
  width: 100%;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
 
  .buttonSubCard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    gap: 10px;
    .instructionCard {
      position: relative;
      text-align: left;
      .instructions {
        opacity: 1;
        color: #696969;
        font-family: Lato;
        font-size: $ten-pixel;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.2px;
        text-align: left;
      }
    }
    .button {
      font-weight: 600 !important;
      font-family: Lato !important;
      font-size: 12px !important;
    }
  }
}
