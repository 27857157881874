@import "/src/variables/font_size";

.input {
  padding: 10px;
  width: 100%;
  flex-grow: 1;
}
.calendarCard {
  display: flex;
  gap: 30px;
  padding: 0 20px 20px 20px;
}
.travelersCard {
  display: flex;
  gap: 10px;
  padding-left: 20px;
  padding-bottom: 25px;
}

.main {
  padding-top: 5px;
  .container {
    opacity: 1;
    background-color: transparent;
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    .searchContainer {
      .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkBox {
          display: flex;
          gap: 20px;
          padding: 20px 20px 20px 20px;
          .checkBoxLabel {
            opacity: 1;
            color: #696969;
            font-family: "Lato-Bold", sans-serif;
            font-size: $thirteen-pixel;
            font-weight: $bold;
            font-style: Bold;
            letter-spacing: 0.2px;
            text-align: left;
            text-transform: uppercase;
          }
        }
        .showDetailsBtn {
          display: none;
        }
      }
      .airportCard {
        padding: 10px 0px 30px 20px;

        .newInputCard2 {
          .card2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
          }
          .cityInfoCard {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 20px;
            .cityInfo {
              opacity: 1;
              color: #222222;
              font-family: Lato;
              font-size: $fourteen-pixel;
              font-weight: $bold;
              font-style: Bold;
              letter-spacing: 0.3px;
              text-align: left;
            }
            .arrowIcon {
              width: 10px;
              height: 10px;
              transform: rotate(180deg);
            }
          }
          .rightLeftIcon {
            width: 14px;
            height: 14px;
            margin-top: 10px;
            pointer-events: none;
          }
        }
      }
      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
