@import "/src/variables/font_size";

.main {
  padding: 0;
  margin-top: 10px;
  .container,
  .activeContainer {
    padding: 20px 10px 20px 10px;
    opacity: 1;
    width: 100%;
    height: max-content;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #696969;
    cursor: pointer;
    .newDetailCard {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 8px;
      .flightBox {
        display: flex;
        align-items: stretch;
        gap: 5px;
        @media only screen and (max-width: 767px) {
          gap: 10px;
        }
        .flightSubBox {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          height: 100%;
        }
        .verticalLine {
          width: 1px;
          opacity: 0.2;
          background-color: #696969;
        }
      }
    }
    .detailsCard {
      display: none;
      align-items: center;
      justify-items: flex-start;
      .details {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .flightDetailsCard {
          display: flex;
          align-items: center;
          justify-items: flex-start;
          gap: 10px;
          padding-right: 15px;
          @media screen and (max-width: 375px) {
            padding-right: 8px;
          }

          .flightIcon {
            width: 20px;
            height: 20px;
            pointer-events: none;
          }
          .flightDetails {
            width: 100%;
          }
        }
      }
      .flightCostCard {
        height: 100% !important;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        border-left: 1px solid rgba(151, 151, 151, 1);
        .checkedIcon {
          width: 30px;
          height: 30px;
          pointer-events: none;
          margin-bottom: 10px;
        }
        .cost {
          opacity: 1;
          color: #696969;
          font-family: "Lato-Bold", sans-serif;
          font-size: $twenty-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.3px;
          text-align: center;
          line-height: $thirteen-pixel;
          @media screen and (max-width: 600px) {
            font-size: $eightteen-pixel;
            line-height: $eleven-pixel;
          }
        }
        .tax {
          opacity: 1;
          color: #696969;
          font-family: "Lato-Bold", sans-serif;
          font-size: $nine-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.1px;
          text-align: center;
          @media screen and (max-width: 600px) {
            font-size: $seven-pixel;
          }
        }
      }
    }
    .viewFlightDetailsCard {
      position: relative;
      .baggageCard {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        height: 50px;
        .baggage {
          opacity: 1;
          color: #1b75ba;
          font-family: "Lato-Bold", sans-serif;
          font-size: $twelve-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.2px;
          text-align: left;
        }
      }
      .flightBox {
        position: relative;
        width: 100%;
      }
    }
    .hideViewFlightDetails {
      display: none;
    }
  }
  .container {
    border: 1px solid #696969;
  }
  .activeContainer {
    border: 2px solid #fbb040;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
