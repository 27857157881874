.flightPreferencesContainer {
  .header {
    font-size: 14px;
    letter-spacing: 0.5px;
    // text-transform: uppercase;
    color: #696969;
    font-family: Lato;
    font-weight: bold;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }
  .customCheckboxContainer {
    display: flex;
    gap: 8px;
    .label {
      color: #696969;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      text-align: left;
      cursor: pointer;
    }
    .selected {
      color: #272727;
      font-family: Lato;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 12px;
    }
    .inputBox {
      position: relative;
      .customCheckbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        height: 14px;
        width: 14px;
        background-color: transparent;
        border: 1.5px solid #696969;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;
      }
      .customCheckbox:checked ~ .checkmark {
        background-color: transparent;
      }
  
      .customCheckbox:checked ~ .checkmark::after {
        display: block;
      }
      .checkmark::after {
        content: "";
        width: 2px;
        height: 6px;
        margin-top: -2px;
        border: solid #696969;
        border-width: 0 1.5px 2px 0;
        transform: rotate(45deg);
        display: none;
        cursor: pointer;
      }
    }
  }
  .sliderComponent {
    margin-bottom: 2vh;

    @media screen and (max-width: 768px) {
      margin-bottom: 1vh;
    }
  }

  .chipCard {
    // display: flex;
    // gap: 1vh;
    width: 100%;
    // flex-wrap: wrap;
    // max-width: 400px;
    margin-top: 2vh;
    padding-bottom: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2vh;
    column-gap: 2vw;

    .chipContainer {
      display: flex;
      flex-direction: column;
      gap: 0.8vh;
      .normalChip {
        width: 100%;
        font-weight: bold;
        &.activeChip {
          color: #222222;
          font-size: 12px;
          font-weight: 600;
          line-height: 1.2;
          font-family: Lato;
          background-color: #d8efff;
          border-radius: 40px;
          border-color: #a4d1f0;
          padding-left: 5px;
          padding-right: 5px;
          letter-spacing: 0.2px;

          @media screen and (max-width: 768px) {
            font-size: 11px;
            // line-height: 0.7;
            letter-spacing: 0.1px;
          }
        }

        &.chip {
          //   color: #696969;
          color: #222222;
          font-size: 12px;
          line-height: 1.2;
          font-family: Lato;
          background-color: #ffff;
          border-radius: 40px;
          font-weight: 600;
          padding-left: 5px;
          padding-right: 5px;
          letter-spacing: 0.2px;
          border-color: #a4d1f0;

          @media screen and (max-width: 768px) {
            font-size: 11px;
            // line-height: 0.7;
            letter-spacing: 0.1px;
          }
        }
      }

      .timeContainer {
        width: 100%;
        text-align: center;
        color: #222222;
        font-size: 11px;
        // font-weight: bold;
        line-height: 1.2;
        font-family: Lato;
        letter-spacing: 0.2px;

        @media screen and (max-width: 768px) {
          font-size: 11px;
          //   line-height: 0.5;
          letter-spacing: 0.09px;
        }
      }
    }
  }

  .toggleSwitchContainer {
    margin-left: 7px;
    margin-bottom: 3vh;
  }
  .selectCard {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 10px;
    .checkSection {
      width: fit-content;
      .checkBoxLabel {
        
      }
      .checkBox {
        height: 13.3px;
        width: 13.3px;
        margin-right: 10px;
      }
      .checkBoxClass {
      }

      .labelClass {
        font-weight: bold;
      }
    }
  }
}
