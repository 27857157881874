.imageContainer {
    display: flex;
    position: relative;
    overflow: hidden;
    // overflow-x: scroll;
    width: 100%;
    //   height: 130px;
    aspect-ratio: 1 / 0.5;
    // gap: 10px;
    @media screen and (min-width: 768px) {
      // aspect-ratio: 1 / 0.3;
      width: 100%;
      height: 257px;
    }
    .horizontalImages {
      width: 100%;
      // height: 1000px;
      position: absolute;
      aspect-ratio: 1 / 0.5;
      // object-fit: cover;
      /* THIS creates the animation! */
      // transform: translateX(100%);
      @media screen and (min-width: 768px) {
        // aspect-ratio: 1 / 0.3;
        width: 100%;
        height: 257px;
      }
      transition: transform 0.5s ease-out;
      padding: 0 5px;
      padding-right: 1px;
      margin: 0 -2px;
      border-radius: 10px;
  
      &.addExtraPadd {
        padding: 0 3.11px;
      }
    }
    .rightBtn {
      position: absolute;
      z-index: 1;
      right: 0px;
      top: 38%;
      bottom: 50%;
      border-radius: 3.73px 0 0 3.73px;
      transform: translate(0, -50%);
      // aspect-ratio: 1 / 0.5;
      width: 32px;
      // background-color: rgba(0, 0, 0, 0.8);
      // fill: transparent;
      @media screen and (min-width: 700px) {
        top: 27%;
      }
  
      &.removeRightPadd {
        right: 0;
      }
  
      &.leftSwipeBtn {
        left: 0px;
        border-radius: 0 3.73px 3.73px 0;
  
        &.removeLeftPadd {
          left: 0;
        }
      }
    }
  }
  