@import "/src/variables/font_size";
.editTripContainer {
  .heading {
    color: #272727;
    font-family: Lato;
    font-size: $eightteen-pixel + 0.691px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-left: 38.5px;
  }

  .tripCard {
    margin-top: 27px;
    padding-right: 6.5px;
    padding-left: 6.5px;

    .card {
      height: 114px;

      .headingContainerClassName {
        padding-top: 8px;
        padding-left: 27.25;
        padding-bottom: 10px;

        .headingClassName {
          font-size: $ten-pixel + 0.4px;
          line-height: 12.4px;
        }

        .travellersClassName {
          font-size: $seven-pixel + 0.85px;
          line-height: 9.35px;
          letter-spacing: 0.17px;
        }
      }
    }
  }

  .tripNameContainer {
    margin-top: 25px;
    padding: 0 38.9px;
    margin-bottom: 25px;
  }
}
