@import "/src/variables/font_size";

.continueBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 0;
  padding-bottom: 40px;
  .continueBtn {
    text-transform: initial;

    font-size: $seventeen-pixel;
    border-radius: 20px;
    padding: 1px 35px;
    font-weight: $bold;
    font-family: Lato;
    margin-bottom: 100px;
    background-color: #000000;
    opacity: 0.2;
    color: #ffffff !important;
    &.activeBtn {
      opacity: 1;
    }
  }
  .continueBtn:focus {
    background-color: #000000;
  }
  .continueBtn:hover {
    background-color: #000000;
  }
  & p {
    font-size: $twelve-pixel;
    font-weight: 400;
    color: #696969;
    padding-top: 10px;
  }
}
