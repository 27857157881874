.main {
  margin: 0;
  padding: 0;
  // overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  overflow: hidden;
  .formCard {
    width: 100%;
    height: 100%;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    background-color: #fdfdfd;
    // border: none;
    // box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.5);
    // box-shadow: 0px -0.1px 0 0.5px #979797;
    padding: 25px 26px;
    // margin: 1px;

    .cardStyles {
      display: flex;
      gap: 1px;
      width: 100%;
      min-height: 50px;
      overflow-x: auto;
      scrollbar-width: thin;
      scroll-behavior: smooth;
      // padding-left: 2px;
    }
    .titleStyles {
      color: #272727;
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 15px;
    }
    .nameCard {
      @extend .cardStyles;
      gap: 4px;
    }
    .dateCard {
      @extend .cardStyles;
    }
    .dateCard > :last-child {
      margin-left: 10px;
    }
    .contactInfo {
      margin-top: 15px;
      @extend .cardStyles;
      flex-direction: column;
      margin-bottom: 14px;
      .contactTitle {
        @extend .titleStyles;
      }
    }
    .phoneCard {
      @extend .cardStyles;
      .phoneInput {
        min-width: 139px !important;
      }
    }
    .flyProgramCard {
      display: block;
      position: relative;
      margin-top: 15px;
      .flyTitle {
        @extend .titleStyles;
        margin-bottom: 10px;
      }
      .flyBox {
        @extend .cardStyles;
        .programName {
          min-width: 139px !important;
        }
      }
      .datesContainer {
        @extend .cardStyles;
        display: flex;

        // overflow-x: visible;
        @media screen and (min-width: 1190px) {
          overflow-x: visible;
        }
        @media screen and (max-width: 969px) {
          flex-direction: column;
        }
      }
    }
    .passportCard {
      @extend .cardStyles;
      flex-direction: column;
      margin-top: 15px;
      .passTitle {
        @extend .titleStyles;
        margin-bottom: 0px;
      }
    }
    .mealCard {
      margin-top: 15px;
      .mealTitle {
        @extend .titleStyles;
        margin-bottom: 10px;
      }
      .programName {
        // min-width: 40%;
        width: max-content;
        max-width: 100% !important;
      }
    }
    .specialNeeds {
      margin-top: 20px;
      .specialTitle {
        @extend .titleStyles;
      }
      .selectCard {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin-top: 24px;
        margin-bottom: 36px;
        // padding: 16px;
        .checkSection {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  overflow: auto;
}
.main::-webkit-scrollbar {
  display: none;
}

.genderContainer {
  padding-left: 5px;
}
