@import "/src/variables/font_size";

.customTabPanelContainer {
  // padding: 0 28px;
  padding: 0 3px;
  @media only screen and (min-width: 767px) {
    padding: 0 20px;
  }
}

.imageContainer {
  // margin: 0 0px;
  margin-bottom: 20px;
  // height: 150px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  @media only screen and (min-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.modifyAndImagesContainer {
  display: flex;
}

.tabTextContainer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 15px;
  @media screen and (min-width: 700px) {
    padding-left: 20px;
  }

  .tabHeading {
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 2px;
    // color: #272727;
  }
  & h1 {
    font-size: 15px;
    font-weight: bold;
  }
  & h2 {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .notSelected {
    color: #c4c4c4;
  }
}

.modifyButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  .doneButton:hover {
    background-color: #1f8539;
  }
  .doneButton {
    background-color: #1f8539;
    color: #ffffff !important;
    font-size: 10px;
    font-weight: 500;
    padding: 6px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-right: -22px;
    border-radius: 6px 0 0 6px;
    padding-top: 7px;

    position: fixed;
    @media screen and (min-width: 700px) {
      position: fixed;
    }
    z-index: 2;
  }
  .doneIcon {
    width: 18px;
    margin-bottom: 3px;
  }
}

.cityDescription {
  padding: 10px 25px 20px 25px;
  color: #272727;
  font-family: Lato, sans-serif;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 17px;

  @media only screen and (min-width: 767px) {
    margin-left: 10px;
    width: 90%;
  }
}

.activityContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}

.timeInADayContainer {
  display: flex;
  position: relative;
  .modifyBtn {
    position: absolute;
    right: -40px;
    top: -13px;
    // top: -5px;
    // right: -25px;
    z-index: 7;
    & img {
      width: 17px;
      height: 17px;
    }
  }

  & span {
    position: absolute;
    left: -15px;
    top: 10px;
  }
}

.timeInADay {
  position: relative;
  padding: 8px;
  box-sizing: border-box;
  // border: 1px solid #efefef;
  border-radius: 4px;
  background-color: #ffffff;
  // box-shadow:
  //   rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  & h1 {
    font-weight: bold;
    // color: #272727;
    color: #9e9e9e;
    text-transform: uppercase;
    padding-bottom: 13px;
    font-size: $fourteen-pixel;
  }
}

.descriptionContainer {
  display: flex;
  // padding-top: 2px;
  gap: 3px;
  color: #696969;
  font-size: $fourteen-pixel;
}

.description {
  // color: #272727;
  color: #696969;
  font-size: $fourteen-pixel;
  word-wrap: break-word;
  padding-bottom: 13px;
}
.description li {
  margin-left: 16px;
}

.budgetEstimatesContainer {
  margin: 0 -20px;
}

// Heading Component
.headingFlightComp {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: -4px;
  text-align: start;
  .dayAndCity {
    color: #272727;
    font-family: Lato;
    font-size: 8.5px;
    font-weight: bold;
    letter-spacing: 0;
  }
  .accordDesc {
    color: #272727;
    font-family: Lato;
    font-size: $fourteen-pixel;
    font-weight: $bold;
    letter-spacing: 0;
    line-height: 15px;
    margin-right: 20px;
    width: 100%;
  }
  .weatherWidgetContainer {
    margin-right: 15px;
  }
  .planeDepartureIcon {
    height: 15px;
    // width: 20px;
    cursor: pointer;
    margin-right: 15px;
  }
  .subHeadingContainer {
    display: flex;
    gap: 20px;
    & h1 {
      font-weight: $bold;
      font-size: $ten-pixel;
      text-transform: uppercase;
      color: #272727;
      line-height: 13px;
    }
  }
}
// .headingContainer {
//   display: flex;
//   gap: 8px;
//   // margin-left: -20px;
//   margin-right: 30px;
//   flex-direction: column;
//   & h2 {
//     font-weight: bold;
//     font-size: 12px;
//     color: #272727;
//     width: 100%;
//   }
//   .subHeadingContainer {
//     display: flex;
//     gap: 20px;
//     & h1 {
//       font-weight: bold;
//       font-size: 9px;
//       text-transform: uppercase;
//       color: #272727;
//     }
//   }
// }

/**
 Editing icon
*/
.activityContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}
.iconButton {
  width: 24px;
  height: 24px;
  margin-right: -5px !important;
  border-radius: 4px !important;
  .editIcon {
    width: 22px;
  }
}

// .loaderContainer {
//   margin-top: 20px;
//   display: none;
//   @media screen and (max-width: 700px) {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   }
// }

/*
  Day activity skeleton
*/

.skeletonHeaderContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  .loaderContainer{
    margin-left: 10px;
    margin-top: 10px;
  }
}

.skeletonSubHeaderContainer {
  display: flex;
  gap: 20px;
  & h1 {
    font-weight: bold;
    font-size: 9px;
    text-transform: uppercase;
    color: #272727;
    line-height: 10px;
  }
}

.accordionDetailsContainer {
  padding: 0 19px;
}

/** */
.stepperContainer {
  width: 100%;
  position: relative;
  .circle {
    position: absolute;
    left: -21px;
    // left: -10px;
    width: 11px;
    height: 11px;
    border: 1px solid #696969;
    border-radius: 50%;
    top: 11px;
  }
  .endCircle {
    outline: 4px solid #696969;
    outline-offset: -6px;
  }

  .line {
    // background-color: #696969;
    // width: 1px;
    border-left: 1px solid #696969;
    position: absolute;
    left: -16px;
    top: 25px;
    bottom: -9px;
  }
}
