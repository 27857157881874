.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;

    .editIcon {
        width: auto;
        height: 20px;
        cursor: pointer;
    }

    .editText {
        margin-top: 1px;
        font-size: 10px;
        color: #696969;
        font-family: "Lato";
        font-weight: 600;
        cursor: pointer;
        letter-spacing: 0px;
    }
}

.main::after,
.main::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}