@import "/src/variables/font_size";

.loginHeadingText {
  font-size: $twenty-pixel;
  font-weight: $bold;
  color: #ffffff;
  margin-left: 10px;
}
.loginDialogText {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 41px;
  padding-top: 50px;
  padding-bottom: 10px;
  color: #696969;
  font-family: Lato;
  & h1 {
    font-weight: 400;
    font-size: $twenty-pixel;
  }
  & p {
    font-size: $fifteen-pixel;
    font-weight: 400;
  }
  & h2 {
    font-size: $fifteen-pixel;
    font-weight: $bold;
  }
}
