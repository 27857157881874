@import "/src/variables/font_size";

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .heading {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    & h1 {
      font-size: $fourteen-pixel;
      font-weight: $bold;
      color: #272727;
    }
    & p {
      color: #cc0900;
      font-size: $eight-pixel;
      font-weight: $bold;
      padding-bottom: 1px;
    }
    & h2 {
      color: #9e9e9e;
      font-size: $eight-pixel;
      font-weight: $bold;
      padding-bottom: 1px;
    }
    & h3 {
      font-size: $twelve-pixel;
      font-weight: $bold;
      color: #272727;
    }
  }
}

.rowMainContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.main {
  margin: 0;
  padding: 0;
  background-color: #FEFEFE !important;
  // overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  
  overflow: hidden;
  .formCard {
    width: 100%;
    height: 100%;
    padding: 25px 26px;
    padding-top: 0px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .cardStyles {
      display: flex;
      gap: 1px;
      width: 100%;
      min-height: 50px;
      overflow-x: auto;
      scrollbar-width: thin;
      scroll-behavior: smooth;
      // padding-left: 2px;
    }
    .titleStyles {
      color: #272727;
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 15px;
    }
    .nameCard {
      @extend .cardStyles;
      gap: 4px;
    }
    .dateCard {
      // @extend .cardStyles;
      display: flex;
      gap: 4px;
      align-items: flex-end;
      .dobContainer {
        display: flex;
        align-items: flex-end;
        gap: 3px;
        margin-right: 25px;
      }
    }
    // .dateCard > :last-child {
    //   margin-left: 10px;
    // }
    .contactInfo {
      margin-top: 15px;
      @extend .cardStyles;
      flex-direction: column;
      margin-bottom: 14px;
      .contactTitle {
        @extend .titleStyles;
      }
    }
    .phoneCard {
      @extend .cardStyles;
      .phoneInput {
        min-width: 139px !important;
      }
    }
    .flyProgramCard {
      display: block;
      position: relative;
      margin-top: 15px;
      .flyTitle {
        @extend .titleStyles;
        margin-bottom: 10px;
      }
      .flyBox {
        @extend .cardStyles;
        .programName {
          min-width: 139px !important;
        }
      }
      .datesContainer {
        @extend .cardStyles;
        display: flex;
        @media screen and (max-width: 394px) {
          flex-direction: column;
        }
      }
    }
    .passportCard {
      @extend .cardStyles;
      flex-direction: column;
      margin-top: 15px;
      .passTitle {
        @extend .titleStyles;
        margin-bottom: 0px;
      }
    }
    .mealCard {
      margin-top: 15px;
      .mealTitle {
        @extend .titleStyles;
        margin-bottom: 10px;
      }
      .programName {
        // min-width: 40%;
        width: max-content;
        max-width: 100% !important;
      }
    }
    .specialNeeds {
      margin-top: 20px;
      .specialTitle {
        @extend .titleStyles;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  overflow: auto;
}
.main::-webkit-scrollbar {
  display: none;
}

.genderContainer {
  padding-left: 5px;
}

.selectCard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 36px;
  // padding: 16px;
  .checkSection {
    display: flex;
    align-items: center;
  }
}
