@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
 

  .container {
    width: 100%;
    height: 100vh;
    position: relative;
    .buttonContainer {
      border-bottom: 0.1px solid #979797;
      padding: 10px 0px 10px 10px;
      .columnAlignButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: max-content;
        font-size: 10px !important;
        font-weight: 700 !important;
        color: #696969 !important;
        font-family: Lato !important;
        gap: 6px;
        & img {
          height: 16px;
        }
      }
    }
    .title {
      opacity: 1;
      color: #252525;
      font-family: Lato;
      font-size: $seventeen-pixel;
      font-weight: $bold;
      font-style: Bold;
      letter-spacing: 0.3px;
      text-align: center;
      line-height: $eightteen-pixel;
      margin: 20px 0px 20px 20px;
    }
    .contentCard {
      opacity: 1;
      border-radius: 4px;
      padding: 10px;
      width: 100%;
      .modifyCard {
        max-height: calc(100dvh - 30dvh);
        @media only screen and (max-width: 600px) {
          max-height: calc(100dvh - 30dvh);
        }

        overflow: auto;
        .subCard {
          width: 100%;
          height: max-content;
          padding: 20px 20px 20px 10px;
          background-color: #f4f4f4;
          margin-bottom: 10px;
          .flightHeader {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .flightDetails {
              position: relative;
              .headerStyles {
                opacity: 1;
                color: #272727;
                font-family: Lato;
                font-weight: $bold;
                font-style: Bold;
                text-align: left;
              }
              .dateInfo {
                @extend .headerStyles;
                font-size: $eight-pixel;
                letter-spacing: 0px;
                line-height: $fifteen-pixel;
                text-transform: uppercase;
              }
              .flightInfo {
                @extend .headerStyles;
                font-size: $twelve-pixel;
                letter-spacing: 0.2px;
                line-height: $fourteen-pixel;
                text-transform: capitalize;
              }
            }
            .accordion {
              display: flex;
              align-items: center;
              gap: 15px;
              .iconStyles {
                width: auto;
                cursor: pointer;
              }
              .flightIcon {
                @extend .iconStyles;
                height: 15px;
              }
              .expandButton {
                margin: 0 !important;
                padding: 0 !important;
                border: none !important;
                .expandIcon {
                  @extend .iconStyles;
                  height: 8px;
                  transition: transform 0.3s ease-in-out;
                }
                .hideExpandIcon {
                  transform: rotate(180deg);
                  @extend .iconStyles;
                  height: 8px;
                  transition: transform 0.3s ease-in-out;
                }
              }
            }
          }
          .inputFields {
            position: relative;
            padding: 20px 0px 10px 20px;
            .dateClassCard {
              display: flex;
              align-items: center;
              gap: 40px;
            }
            .airportCard {
              display: flex;
              align-items: center;
              gap: 50px;
              margin-top: 20px;
              .newInputCard2 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;  
                // pointer-events: none;
                .leftRightIcon {
                  width: auto;
                  height: 15px;
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }

      .buttonCard {
        display: flex;
        gap: 10px;
        margin-top: 30px;
        .buttonStyles {
          border-radius: 3px;
          opacity: 1;
          color: #222222;
          font-family: Lato;
          font-size: $fourteen-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.2px;
          text-align: center;
          width: 100%;
          height: 60px;
          text-transform: uppercase;
          cursor: pointer;
        }
        .cancelBtn {
          @extend .buttonStyles;
          background-color: #ffffff;
          border: 1px solid rgba(151, 151, 151, 1);
        }
        .searchBtn {
          @extend .buttonStyles;
          background-color: #ffce07;
          border: none !important;
        }
      }
    }
  }
}
