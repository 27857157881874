.successContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100dvh - 60px);
  background-color: #ffffff;

  .mainContainer {
    max-width: 382px;
    width: 80vw;
    padding: 30px;
    padding-left: 40px;
    background-color: #FEFEFE;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    padding-bottom: 100px;
    .booking {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      align-self: flex-start;
      & img {
        width: 30px;
      }
      & h1 {
        font-size: 20px;
        font-weight: 600;
        color: #272727;
      }
    }
    .informationText {
      font-size: 10px;
      text-align: start;
      color: #272727;
      font-weight: 600;
    }
    .confirmationNumber {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 20px;
      color: #272727;
    }
    .button:hover {
      background-color: #1b75ba !important;
    }
    .button {
      border-radius: 0;
      font-size: 18px !important;
      background-color: #1b75ba;
      font-family: Lato !important;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70px !important;
      font-weight: 500;
      color: #ffffff;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}
