@import "/src/variables/font_size";

.newLoaderContainer {
  height: 100dvh;
  width: 100%;
  @media only screen and (min-width: 767px) {
    display: none;
  }
}

.main {
  margin: 0;
  padding: 0;
  .container {
    width: 100%;
    min-height: calc(100dvh - 60px);
    overflow: auto;
    scroll-behavior: smooth;
    .header {
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      // gap: 15px;
      padding-left: 20px;
      padding-bottom: 20px;
      .columnAlignButton {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: max-content;
        font-size: 10px !important;
        // transform: translateY(10px);
        font-weight: 700 !important;
        color: #696969 !important;
        font-family: Lato !important;
        gap: 6px;
        & img {
          width: 22px;
        }
      }
      .bookingTitle {
        color: #696969;
        font-size: 20px;
        line-height: 24px;
        font-family: lato;
        font-weight: bold;
        text-align: center;
        margin-top: -5px;
      }
    }
    .inputBox {
      border: 1px solid #979797;
      background-color: #f4f4f4;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-right: 10px;
      .inputHeader {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &.collapseContainer {
          justify-content: flex-end;
          margin-bottom: 15px;
        }
        .checkBox {
          display: flex;
          align-items: center;
          gap: 10px;
          padding-left: 38px;
          width: 330px;
          @media (min-width: 360px) and (max-width: 395px) {
            width: 290px;
          }
        }
        .expandIconBox {
          position: relative;
          // margin-right: 6%;
          // @media (min-width: 360px) and (max-width: 395px) {
          // margin-left: 10%;
          // }
          .showDetailsBtn {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;

            .chip {
              color: #696969;
              font-size: 12px;
              // line-height: 1.2;
              font-family: Lato;
              background-color: #ffff;
              border-radius: 40px;
              font-weight: 600;
              padding-left: 5px;
              padding-right: 5px;
              padding-top: 0px;
              padding-bottom: 0px;
              letter-spacing: 0.2px;

              color: #696969;
              font-size: 11px;
              line-height: 12px;
              font-weight: 600;
              letter-spacing: 0.3px;
              text-align: left;
              cursor: pointer;
              border-color: #a4d1f0;
            }
            .showBtnTitle {
              color: #696969;
              font-family: Lato;
              font-size: $eight-pixel;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: $ten-pixel;
              text-transform: uppercase;
              cursor: pointer;
            }
            .expandIcon {
              height: 7px;
              width: 14px;
              transform: rotate(180deg);
              cursor: pointer;
            }
          }
        }
      }
    }
    .titleCard {
      // padding-left: 38px;
      // padding-top: 10px;

      .checkCard {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 20px;
        overflow: hidden;
        // overflow-x: auto;
        // scrollbar-width: thin;
        @media only screen and (min-width: 360px) and (max-width: 360px) and (min-height: 740px) and (max-height: 740px) {
          gap: 10px;
        }
        .checkBox {
          display: flex;
          align-items: center;
          gap: 10px;
          .inputCheckBox {
            width: 20px;
            height: 20px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }

          .label {
            color: #696969;
            font-size: 10px;
            line-height: 12px;
            font-weight: 500;
            letter-spacing: 0.2px;
            text-align: left;
            text-transform: uppercase;
          }
        }
        .inputCheckBox:checked {
          background-color: transparent;
        }
        .packageDateCard {
          margin-left: 20px;
          margin-top: -15px;
          .packageDateTitle {
            color: #222222 !important;
            opacity: 1 !important;
            width: 120px;
          }
        }
        .searchCard {
          margin-top: -15px;
          display: flex;
          gap: 10px;
          @media only screen and (max-width: 600px) {
            padding-right: 10px;
          }
          @media only screen and (min-width: 360px) and (max-width: 360px) and (min-height: 740px) and (max-height: 740px) {
            gap: 5px;
          }
          .travelTitle {
            color: #222222 !important;
          }
          .searchBtn {
            height: 30px;
            min-width: 62px;
            max-width: 65px;
            @media (min-width: 360px) and (max-width: 390px) {
              min-width: 45px;
              max-width: 45px;
            }
          }
          .flightDateCard {
            margin-left: 20px;
            .flightDateTitle {
              color: #222222 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    .detailsBox {
      margin-top: -25px;
    }
    .detailsCard {
      padding-left: 2px;
      padding-right: 2px;
      margin-top: -20px;
    }

    .divider {
      width: 100%;
      border-bottom: 1.1px solid #1d75bb;
    }
    .list {
      position: relative;
      .flightLoader {
        padding-top: 0px;
      }
      .listCard {
        position: relative;
        padding: 0px 2px 0px 2px;
        @media screen and (max-width: 767px) {
          padding: 0px 5px 0px 2px;
        }
        .radioControl {
          width: 100%;
          .radioGroup {
            width: 100%;
          }
        }
        .listCardContainer {
          padding: 0 !important;
          margin-bottom: 10px;
          // margin: 0 !important;
          .customFlightStyles {
            padding: 0 !important;
            margin: 0 !important;
          }
          .summaryClass {
            padding-left: 0px !important;
            margin: 0 !important;
          }
          .accordFlightDetailsClass {
            margin: 0 !important;
            padding: 10px 0px !important;
          }
          .headingFlightComp {
            margin-left: 15px !important;
            margin-right: 12px !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .checkInCheckout {
              color: #272727;
              font-family: Lato;
              font-size: $eight-pixel;
              font-weight: bold;
              letter-spacing: 0;
              line-height: $fourteen-pixel;
              text-transform: uppercase;
            }
            .city {
              color: #222222;
              font-family: Lato;
              font-size: $fourteen-pixel;
              font-weight: bold;
              letter-spacing: 0.3px;
              line-height: $sixteen-pixel;
              text-transform: uppercase;
            }
          }
          .hotelListing {
            position: relative;
            .showMoreBox {
              display: flex;
              align-items: center;
              justify-content: center;
              .showMoreBtn {
                color: #696969;
                font-weight: 600;
                font-family: lato;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.2px;
                .showMoreIcon {
                  width: auto;
                  height: 20px;
                  cursor: pointer;
                }
              }
              .showMoreBtn:hover {
                outline: none;
                background: none !important;
              }
            }
          }
        }
        .showCard,
        .packageShowMoreCard {
          display: flex;
          text-align: center;
          justify-content: center;

          .showMoreBtn {
            color: #696969;
            font-weight: 600;
            font-family: lato;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.2px;
            .showMoreIcon {
              width: auto;
              height: 20px;
              cursor: pointer;
            }
          }
          .showMoreBtn:hover {
            outline: none;
            background: none !important;
          }
        }
        .showCard {
          height: 80px;
        }
        .packageShowMoreCard {
          height: 50px;
        }
      }
      .buttonCard {
        margin-top: 150px;
        margin-bottom: 20px;
      }
    }

    .flightListCard {
      position: relative;

      .flightListBox {
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: thin;
        padding-left: 2px;
        padding-right: 2px;
      }
      .showCard {
        display: flex;
        text-align: center;
        justify-content: center;
        height: 80px;
        .showMoreBtn {
          color: #696969;
          font-weight: 600;
          font-family: lato;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.2px;
          .showMoreIcon {
            width: auto;
            height: 20px;
            cursor: pointer;
          }
        }
        .showMoreBtn:hover {
          outline: none;
          background: none !important;
        }
      }
    }
  }
  .flightLoader {
    margin-top: 50px;
  }
  .landingCard {
    margin-bottom: 100px;
    @media only screen and (min-width: 767px) {
      min-height: 500px;
      overflow: auto;
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
