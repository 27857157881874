.mainContainer {
  box-shadow: none !important;
  border: none !important;
  // background-color: transparent !important;
  // padding: 3px 20px !important;
  // padding-top: 0 !important;
}
.summary {
  // margin: 0px -20px !important;
  // padding: 0px 35px !important;
  // color: #272727 !important;
  // padding-left: 10px !important;
  // padding-right: 26px !important;
}
.expandIcon {
  width: 13px;
  // background-color: #aaaaaa;
}
.hideExpandIcon {
  display: none;
}

.accordionDetails {
  padding: 0px 35px;
}
