@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  .flightDetailCard {
    position: relative;
    .flightHeaderStyles {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      letter-spacing: 0.2px;
      text-align: left;
    }
    .flightHeader {
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      width: 100%;
      .flightNumber {
        @extend .flightHeaderStyles;
        color: #696969;
        font-size: $eleven-pixel;
        font-weight: $bold;
        line-height: $fifteen-pixel;
      }
      .flightName {
        @extend .flightHeaderStyles;
        color: #696969;
        font-weight: 400 !important;
        font-size: $eleven-pixel;
        line-height: $fifteen-pixel;
      }
      .operator {
        @extend .flightHeaderStyles;
        color: #696969;
        font-size: $eleven-pixel;
        line-height: $fifteen-pixel;
        font-weight: $bold;

        .operatedBy {
          font-weight: 400;
        }
      }
    }
    .flightDescription {
      .duration {
        @extend .flightHeaderStyles;
        color: #696969;
        font-size: $eleven-pixel;
        line-height: $fifteen-pixel;
        font-weight: 400;
      }
    }
  }
  .headingStyles {
    color: #696969;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    line-height: 14px;
  }
  .container {
    width: max-content;
    min-width: 120px;
    height: max-content;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    padding-bottom: 0px;
    .layoverCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 4px;
      border: 1px solid #fbb040;
      height: 35px;
      gap: 5px;
      padding-left: 10px;
      padding-right: 10px;
      .layIcon {
        width: auto;
        height: 14px;
      }
      .layoverDetailCard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .layOverStyles {
          opacity: 1;
          color: #696969;
          font-family: "Lato-Bold", sans-serif;
          font-size: 12px;
          letter-spacing: 0.2px;
          text-align: left;
          line-height: 15px;
        }
        .layTitle {
          @extend .layOverStyles;
          font-weight: 700;
          font-style: Bold;
        }
        .layDes {
          @extend .layOverStyles;
          font-weight: 500;
          font-style: normal;
        }
      }
    }
    .contentCard,
    .layCard {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      .iconCard {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .layoverIcon {
          width: auto;
          height: 14px;
        }
        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .imgIcon {
        width: 40px;
        height: 40px;
        border: 0.51px solid #979797;
        border-radius: 8px;
        box-sizing: border-box;
        object-fit: contain;
      }
      display: flex;
      align-items: center;
      .detailsCard {
        position: relative;
        .title {
          @extend .headingStyles;
          padding-bottom: 2px;
          font-weight: bold;
          .subTitle {
            font-weight: 500;
          }
        }
        .desc {
          @extend .headingStyles;
        }
      }
    }
    .layCard {
      border-radius: 4px;
      border: 1px solid #fbb040;
      display: flex;
      align-items: center;
      .iconCard {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .destinationImg {
      display: block;
      height: 80px;
      width: 170px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
}
