@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .searchBtn,
  .inActive {
    border-radius: 8px;

    color: #222222 !important;
    font-family: Lato;
    font-size: $eleven-pixel;
    font-weight: bold;
    letter-spacing: 0.21px;
    line-height: $thirteen-pixel;
    text-align: center;
    outline: none;
    box-shadow: none;
    border: none;
    height: 33px;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
  }
  .searchBtn {
    background-color: #fcb400 !important;
  }
  .inActive {
    background-color: #ffffff;
    border: 1px solid #979797;
  }
  .inActive:hover {
    background-color: #ffffff;
    border: 1px solid #979797;
  }
  .searchBtn:hover {
    background-color: #fcb400 !important;
    color: #222222 !important;
  }
}
