.container {
  display: none;
  @media only screen and (min-width: 1000px) {
    display: block;
    width: 100vw;    
    height: 100vh;

    .emptyNav {
      position: relative;
      height: 60px;
      width: 100%;
      background-color: #ffff;
      border-bottom: 1.5px solid #dcdcdc;
    }
    .emptyDraw {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #e5e5e5;
    }
  }
}
