.hotelPreferencesContainer {
  .header {
    font-size: 14px;
    letter-spacing: 0.5px;
    // text-transform: uppercase;
    color: #696969;
    font-family: Lato;
    font-weight: bold;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0px;
      font-size: 12px;
      letter-spacing: 0.3px;
    }
  }
  .customCheckboxContainer {
    display: flex;
    gap: 8px;
    .label {
      color: #696969;
      font-size: 10px;
      line-height: 12px;
      font-weight: 500;
      letter-spacing: 0.3px;
      text-align: left;
      cursor: pointer;
    }
    .selected {
      color: #272727;
      font-family: Lato;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 12px;
    }
    .inputBox {
      position: relative;
      .customCheckbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      .checkmark {
        height: 14px;
        width: 14px;
        background-color: transparent;
        border: 1.5px solid #696969;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        cursor: pointer;
      }
      .customCheckbox:checked ~ .checkmark {
        background-color: transparent;
      }
  
      .customCheckbox:checked ~ .checkmark::after {
        display: block;
      }
      .checkmark::after {
        content: "";
        width: 2px;
        height: 6px;
        margin-top: -2px;
        border: solid #696969;
        border-width: 0 1.5px 2px 0;
        transform: rotate(45deg);
        display: none;
        cursor: pointer;
      }
    }
  } 
  .toggleSwitchContainer {
    margin-left: 7px;
    margin-bottom: 3vh;
  }
  .selectCard {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 10px;
    .checkSection {
      width: fit-content;

      .checkBoxClass {
      }

      .labelClass {
        font-weight: bold;
      }
    }
  }
}
