@import "/src/variables/font_size";

.loaderContainer {
  z-index: 999;
  background: rgba(0, 0, 0, 0.9);
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContainer {
  background-color: #fff;
  width: 100%;
  // overflow: auto;
  scrollbar-width: thin;
  padding: 24px 3.5px;
  // height: 100dvh;
  height: 100%;
  padding-top: 0px;
  margin-top: -60px;
  padding-top: 38px;
  @media screen and (min-width: 700px) {
    padding-top: 44px;
    margin-top: 0px;
    height: calc(100dvh - 60px);
    overflow: auto;
  }
  .tabsClass {
    margin-bottom: 15px;
  }
}
/* Hide scrollbar for WebKit browsers (Chrome, Safari, Edge) */
.mainContainer::-webkit-scrollbar {
  width: 0; /* Hides the vertical scrollbar */
  height: 0; /* Hides the horizontal scrollbar */
}

/* Hide scrollbar for Firefox */
.mainContainer {
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
}

.headerContainer {
  padding: 0px 5px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d6d6;
  padding-left: 10px;
  padding-right: 19px;
  margin-left: -7px;
  margin-right: -19px;
  padding-bottom: 5px;
  .columnAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    font-size: 10px !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #696969 !important;
    font-family: Lato !important;
    gap: 6px;
    & img {
      // width: 30px;
      height: 16px;
    }
  }
}

.rightBtnContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .shareButtonContainer {
    align-self: flex-start;
    justify-self: flex-end;
    // margin-top: -12px;
    // margin-right: -30px;
  }
  .rowAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: max-content;
    font-size: $nine-pixel !important;
    // transform: translateY(10px);
    font-weight: $bold !important;
    color: #696969 !important;
    font-family: Lato;
    // margin-top: -15px;
    & img {
      // width: 35px;
      width: 30px;
      // transform: translateY(15px);
    }
  }
}
