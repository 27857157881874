@import "/src/variables/font_size";

.arrivalOnDifferentDateContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  & img {
    width: 13px;
  }
  .defaultText {
    font-family: Lato;
    font-size: $seven-pixel;
    font-weight: bold;
    color: #CC0900 !important;
  }
  .redText {
    font-family: Lato;
    font-size: $seven-pixel;
    font-weight: bold;
    color: #CC0900 !important;
  }
}
