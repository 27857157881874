.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
    max-height: calc(100dvh - 240px);
    overflow: scroll;
    overflow-x: hidden;
    // margin: 0 -10px;
    // margin-left: -10px;
    @media only screen and (max-width: 767px ){
        max-height: calc(100dvh - 420px);
        overflow: auto;
        margin: 0;
    }
    .listContainer{
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .card {
        height: 100%;
        z-index: 999;
        // border-radius: 6px;
        // width: 100%;
        background-color: #fff;
        cursor: pointer;
        // margin-bottom: 10px;
        padding: 0px 10px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        // min-height: 50px;
        // border: 1px solid green;
        .title {
            font-family: "Lato";
            line-height: 1;
            font-weight: 500;
            font-size: 14px;
        }
        .detailsCard {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // padding-top: 5px;
            .dateInfo {
                font-size: 10px;
                font-family: "Lato";
                line-height: 1;
                font-weight: 500;
            }
            .createdOn {
                font-size: 10px;
                font-family: "Lato";
                line-height: 1;
                font-weight: 500;
            }
        }
    }
}
.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
}

::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-track {
    background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
    background: #b1b1b1;
}
::-webkit-scrollbar-thumb:hover {
    background: #b1b1b1;
}

.loader{
    margin-top: 30px;
}