.superContainer {
  // height: calc(100dvh - 60px);
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // overflow: auto;
  position: relative;
  padding: 30px 15px;
  padding-bottom: 120px;
  min-height: calc(100dvh - 70px);
  gap: 17px;
}
.superContainerFocus {
  padding-bottom: 0;
  justify-content: flex-end;
}


.chatIcon {
  width: 32px;
}
.heading {
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
}

// }
.placeAndPromptContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .placeText {
    width: 175px;
    word-wrap: break-word;
    color: #272727;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 500;
  }
  .dateDaysContainer {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    .daysDateText {
      color: #272727;
      font-family: Lato;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: right;
    }
  }
}
.information {
  font-family: Lato;
  font-size: 12px;
}
.buttonContainer {
  margin-top: 12px;
  display: flex;
  justify-content: center;
}
// }

.sectionContainer {
  display: flex;
  flex-direction: column;
  // gap: 3px;
  align-items: flex-start;
  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
  }
}

.moreOptionsWithinCity {
  display: flex;
  align-items: center;
  gap: 20px;
  .moreProfileIcon {
    width: 30px;
  }
  .moreOptionText {
    font-size: 12px;
  }
}

.showMoreLikeIconContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .button {
    color: #696969;
    font-family: Lato;
    font-weight: 600;
    font-size: 9px;
    gap: 5px;
    border-radius: 5px;
    width: 120px;
    .buttonIcon {
      width: 20px;
      height: 20px;
    }
  }

  .button:disabled {
    background-color: #d5d5d5;
  }

  .likeIconContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    .likeDislikeIcon {
      width: 20px;
    }
  }
}

.inputContainer {
  position: fixed;
  bottom: 0;
  left: 0px;
  right: 0px;
  padding: 0 10px;
  padding-top: 10px;
  background-color: #FEFEFE;
  z-index: 9;
}

.inputContainerFocus {
  position: relative;
  width: 100vw;
  margin-left: -15px;
  padding: 0 10px;
  // padding-top: 15px;
  background-color: #FEFEFE;
  z-index: 9;
}

.input {
  color: #696969;
  // width: 100%;
  font-size: 13px;
  border: none;
  padding: 14px;
  padding-left: 20px;
  padding-right: 35px;
  outline-color: #2596be;
  outline-width: 1px;
  border-radius: 25px;
  // resize: none;
  outline: 1px solid #a4d1f0;
  -webkit-appearance: none;
}
.input::placeholder {
  color: #a3a9ad !important;
}

.constText {
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  align-self: flex-start;
  & h1 {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 13px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    // margin-left: -15px;
    // margin-right: -30px;
  }
  & p {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    font-weight: 400;
    // margin-left: -15px;
    // margin-right: -30px;
  }
}


 