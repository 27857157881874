.selectBtn {
  border: 1px solid rgba(151, 151, 151, 1);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  cursor: pointer;
  width: 125px;
  gap: 10px;
  & img {
    height: 16px;
  }
  .expand {
    width: 12px;
  }
}

.selectList {
  width: 125px;
  margin-top: 3px;
  margin-left: 8px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.5);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  .selectItem {
    border: none;
    background-color: transparent;
    padding: 12px;
    width: max-content;
    cursor: pointer;

    color: #000000;
    font-weight: 700;
    font-size: 12px;
  }
}
