// @import "/src/variables/font_size";
// .main {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   margin: 1px;
//   position: relative;

//   .selectCard {
//     .label {
//       // width: 70px;
//       color: #696969;
//       font-size: $eleven-pixel;
//       padding-bottom: 3px;
//       font-weight: 400;
//       letter-spacing: 0.2px;
//       display: flex;
//       align-items: center;
//       gap: 1px;
//       text-transform: uppercase;
//       // @media only screen and (min-width: 767px) {
//       //   margin-bottom: 2px !important;
//       // }
//       .mandate {
//         color: #bf150d;
//         width: 6px;
//         height: 6px;
//       }
//     }
//     .selectBox {
//       position: relative;
//       // min-width: 50px;
//       // max-width: 80px;
//       .selectOption {
//         // position: relative;
//         border: none;
//         margin: 0 !important;
//         // padding: 0 !important;
//         padding: 0px 20px 0px 8px !important;
//         height: 35px;
//         color: #272727 !important;
//         font-family: Lato;
//         background-color: #ffffff !important;
//         font-size: 10px;
//         letter-spacing: 0.2px;
//         line-height: 12px;
//         border-radius: 2px;
//         outline: none !important;
//         // border-color: #979797;
//         border-color: #cfcfcf;
//         // box-shadow: 0px -0.1px 0 0.5px #979797;
//         // & :hover {
//         //   outline: none !important;
//         //   border: 5px solid green;
//         // }
//         &::placeholder {
//           color: #a3a9ad;
//         }
//         .options {
//           border: 1px solid #979797 !important;
//           // border: 1px solid #cfcfcf;
//           border-radius: 2px !important;
//           // background-color: #ffffff !important;
//         }
//       }
//     }
//   }
//   .inputErrorField {
//     border: 1px solid #ff0000;
//   }
//   .errorMessage {
//     position: absolute;
//     font-size: 10px;
//     line-height: 12px;
//     font-weight: 500;
//     font-family: lato;
//     color: red;
//   }
//   .hideError {
//     display: none;
//   }
// }
// .listCard {
//   color: #272727 !important;
//   font-family: Lato;
//   font-size: 10px !important;
//   letter-spacing: 0.2px;
//   line-height: 12px !important;
//   .list {
//     color: #272727 !important;
//     font-family: Lato;
//     font-size: 10px !important;
//     letter-spacing: 0.2px;
//     line-height: 12px !important;
//   }
// }

// .customIcon {
//   height: 6px;
//   width: 10px;
//   cursor: pointer;
//   margin-right: 10px;
//   transition: transform 0.3s ease;
// }
// .rotateIcon {
//   transform: rotate(-180deg);
//   transition: transform 0.5s ease;
// }

// .placeHolderOption {
//   color: #a3a9ad !important;
//   border: 2px solid green !important;
//   .placeHolder {
//     color: #a3a9ad !important;
//   }
//   &::placeholder {
//     color: #a3a9ad !important;
//   }
// }

/* OLD UI */
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 1px;

  .selectCard {
    .label {
      width: 70px;
      color: #222222;
      font-family: Lato;
      font-size: 6.6px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 8px;
      display: flex;
      align-items: center;
      gap: 1px;
      margin-bottom: 3px;
      text-transform: uppercase;
      @media only screen and (min-width: 767px) {
        margin-bottom: 2px !important;
      }
      .mandate {
        color: #bf150d;
        width: 6px;
        height: 6px;
      }
    }
    .selectBox {
      position: relative;
      min-width: 50px;
      max-width: 80px;
      .selectOption {
        margin: 0 !important;
        // padding: 0 !important;
        padding: 0px 20px 0px 8px !important;
        height: 27px;

        color: #272727 !important;
        font-family: Lato;
        background-color: #ffffff !important;
        font-size: 10px;
        letter-spacing: 0.2px;
        line-height: 12px;
        border-radius: 2px;
        outline: none !important;
        border-color: #979797;
        // box-shadow: 0px -0.1px 0 0.5px #979797;
        // & :hover {
        //   outline: none !important;
        //   border: 5px solid green;
        // }
        .options {
          border: 1px solid #979797 !important;
          border-radius: 2px !important;
          background-color: #ffffff !important;
        }
      }
    }
  }
  .inputErrorField {
    border: 1px solid #ff0000;
  }
  .errorMessage {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    font-family: lato;
    color: red;
  }
  .hideError {
    display: none;
  }
}
.listCard {
  color: #272727 !important;
  font-family: Lato;
  font-size: 10px !important;
  letter-spacing: 0.2px;
  line-height: 12px !important;
  .list {
    color: #272727 !important;
    font-family: Lato;
    font-size: 10px !important;
    letter-spacing: 0.2px;
    line-height: 12px !important;
  }
}

.customIcon {
  height: 6px;
  width: 10px;
  cursor: pointer;
  margin-right: 10px;
  transition: transform 0.3s ease;
}
.rotateIcon {
  transform: rotate(-180deg);
  transition: transform 0.5s ease;
}
