.sliderContainer {
  display: flex;
  align-items: center;
  gap: 30px;

  @media screen and (max-width: 768px) {
    gap: 20px;
  }

  .stopCard {
    position: relative;
    margin-top: -1vh;

    .stop {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      font-weight: Lato;
      color: #205c8c;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }
}
