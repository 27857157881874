.popOverContainer {
  position: relative;
  z-index: 100;
  .dropdown {
    top: 120%;
    right: 7px;
    background-color: #ffff;
    border: 0.41px solid rgb(151, 151, 151);
    position: absolute;
  }
  img {
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;
  }

  &.rotateDeg {
    img {
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;
    }
  }
}
