.inputContaie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: black;
  display: flex;
  flex-grow: 1;
}
.input {
  padding: 10px;
  width: 100%;
  flex-grow: 1;
}
.calendarCard {
  display: flex;
  gap: 10px;
  padding: 20px;
}
.travelersCard {
  display: flex;
  gap: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
}
.main {
  padding-top: 5px;
  .container {
    border: 1px solid rgba(151, 151, 151, 1);
    opacity: 1;
    background-color: rgba(244, 244, 244, 1);
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    .searchContainer {
      padding: 20px;
      .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkBox {
          display: flex;
          gap: 20px;
          padding: 20px 20px 0px 20px;
        }
      }
      .airportCard {
        padding: 0px 0px 20px 20px;
  
      }
      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
