@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    .label {
      color: #696969;
      font-family: Lato;
      font-size: $eight-pixel;
      font-weight: bold;
      letter-spacing: 0.58px;
      line-height: $ten-pixel;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-left: 2px;
    }
  }
  .buttonStyles {
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    cursor: pointer;
  }
  .travelBtn,
  .activeTravelBtn {
    width: 160px;
    @extend .buttonStyles;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 120px;
    }
    .travelCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: inline-block;
      padding-top: 2px;
    }
    .btnName {
      display: inline;
      color: #222222;
      font-family: Lato;
      font-size: $ten-pixel;
      font-weight: bold;
      letter-spacing: 0.71px;
      line-height: $thirteen-pixel;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .roomCard {
      width: 33px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #1b75ba;
      padding-top: 5px;
      .room {
        color: #ffffff;
        font-family: Lato;
        font-size: 7px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 9px;
        text-align: center;
        height: 10px;
      }
    }
  }
  .travelBtn {
    background-color: #ffffff;
  }
  .activeTravelBtn {
    background-color: #d8efff;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
