@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .label {
    color: #696969;
    font-family: Lato;
    font-size: $eight-pixel;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: $ten-pixel;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-left: 2px;
  }
  .airportNotAvail {
    color: #BF150D;;
    font-family: Lato;
    font-size: $nine-pixel;
    letter-spacing: 0.2px;
    line-height: $eleven-pixel;
    margin-bottom: 10px;
    margin-top: 5px;
    word-break: break-all;
    width: 100%;
    margin-top: 25px;
  }
  .btnStyles {
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-left: 5px;
    padding-right: 5px;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 165px;      
    }
  }
  .btn,
  .activeBtn {
    @extend .btnStyles;
    background-color: #ffffff;
    cursor: pointer;
    .arrowIcon {
      height: 10px;
      width: 10px;
    }
    .card {
      position: relative;
      .cityButtonStyles {
        color: #222222;
        font-family: Lato;
        letter-spacing: 0.21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
        @media (min-width: 360px) and (max-width: 395px) {
          width: 65px;      
        }
      }
      .btnName {
        @extend .cityButtonStyles;
        font-size: $ten-pixel;
        font-weight: bold;
        line-height: $thirteen-pixel;
      }
      .placeHolder {
        // @extend .cityButtonStyles;
        font-size: $ten-pixel;
        font-weight: 500;
        line-height: $thirteen-pixel;
        color: #696969;
        // font-weight: 600;
        font-family: Lato;
        letter-spacing: 0.5px;
      }
      .city {
        @extend .cityButtonStyles;
        font-weight: 500;
        line-height: $ten-pixel;
        font-size: $eight-pixel;
      }
    }
  }
  .activeBtn {
    @extend .btnStyles;
    background-color: #D8EFFF;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
