// .dialogContainer{
//     max-width: 330px;
//     width: 320px;
//     padding: 20px 20px;
//     display: flex;
// }

.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      padding: 20px;
      .title {
        font-weight: 600;
        font-family: Lato;
        font-size: 18px;
        color: #f2f2f7;
        letter-spacing: 0.2px;
      }
      .subTitle {
        font-weight: 500;
        font-family: Lato;
        font-size: 14px;
        color: #f2f2f7;
        letter-spacing: 0.2px;
        text-align: center;
      }
      .btnCard {
        display: flex;
        gap: 20px;
        padding-top: 10px;
        .cancelBtn {
          padding-top: 5px;
          padding-bottom: 6px;
          padding-left: 30px;
          padding-right: 30px;
          background-color: transparent;
          font-size: 12px;
          font-family: Lato;
          font-weight: 500;
          letter-spacing: 0.2px;
          box-shadow: none;
          color: #f9f9f9;
          border: 1px solid #979797;
          outline: none;
          border-radius: 50px;
          cursor: pointer;
        }
        .discBtn {
          padding-top: 5px;
          padding-bottom: 6px;
          padding-left: 20px;
          padding-right: 20px;
          outline: none;
          border: none;
          text-align: center;
          border-radius: 50px;
          background-color: #d31e1e;
          font-size: 12px;
          font-family: Lato;
          font-weight: 500;
          letter-spacing: 0.2px;
          cursor: pointer;
          color: #f2f2f7;
        }
      }
    }
  }
  .main::before,
  .main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  