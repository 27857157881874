.closeBtnContainer {
  margin-left: 15px;
  margin-right: 13px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pdfContainer {
  width: 100vw;
  height: 91vh;
  overflow-x: none;
}
.columnAlignButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
  font-size: 12px !important;
  // transform: translateY(10px);
  font-weight: 700 !important;
  color: #696969 !important;
  font-family: Lato !important;
  gap: 6px;
  & img {
    width: 24px;
  }
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: none;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  .container {
    padding-top: 25px;
    // padding-bottom: 200px;

    .title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 600;
      color: #696969;
      margin-left: 40px;
      font-family: lato;
      margin-bottom: 20px;
    }
    .accordion {
      .questionStyles {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.2px;
        word-spacing: 1px;
        font-family: Lato, sans-serif;
        text-align: left;
      }
      .qus {
        @extend .questionStyles;
        font-weight: 600;
        width: 90%;
      }
      .ans {
        @extend .questionStyles;
        font-weight: 500;
        color: #696969;
      }
    }
  }
}
.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
