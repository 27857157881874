@import "/src/variables/font_size";
.midContainer {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding-top: 13px;
  position: relative;
  .clearText {
    cursor: pointer;
    position: absolute;
    width: 17px;
    top: 55px;
    right: 10px;
  }
  & h1 {
    font-size: 11px;
    color: #5f6a70;
    font-weight: $bold;
    text-transform: uppercase;
  }
  & p {
    text-align: end;
    font-size: 9px;
    color: #272727;
    font-weight: 600;
  }
}

.input {
  color: #272727;
  width: 100%;
  font-size: $twelve-pixel;
  border: none;
  // padding: 12px;
  color: #696969;
  padding: 0;
  padding-left: 5px;
  padding-right: 25px;
  outline-color: #a3a9ad;
  outline-width: 1px;
  border-radius: 5px;
  resize: none;
  outline: 1px solid #a3a9ad;
  -webkit-appearance: none;
  border: 8px solid #ffffff !important;
}
.input::placeholder {
  color: #a3a9ad !important;
}
.input::-webkit-scrollbar {
  width: 0;
}
