@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .locationBox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    .checkBox {
      margin-top: 25px;
    }
    .locationCard,
    .disableCard {
      .locationLabel {
        opacity: 1;
        color: #696969;
        font-family: Lato;
        font-size: $eleven-pixel;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.3px;
        text-align: left;
        .location {
          font-weight: $bold;
        }
      }
      .inputField {
        margin-top: 2px;
        margin-bottom: 20px;
      }
      .dateCard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        margin-top: 10px;
      }
    }
    .disableCard {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
