@import "/src/variables/font_size";

.main {
  // padding-left: 5px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // padding-right: 20px;
  width: 100%;
  border-radius: 4.8px;
  // border: 1px solid pink;

  &.hotelCardBorder {
    border: 1px solid #979797;

    &.expandedBorder {
      border: 1.7px solid #fbb040;
    }
  }

  &.searchTypeCls {
    background-color: #f4f4f4;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    .firstCard {
      display: block;
      width: 100%;

      &.addressTypeCard {
        cursor: pointer;
      }
      .imgBox {
        height: 100px;
        width: 100%;
        overflow: hidden;
        position: relative;

        .hotelImg {
          width: 100% !important;
          height: 100%;
          // border: 0.51px solid #979797;
          // border-radius: 4px;
          box-sizing: border-box;
          object-fit: cover;
          min-width: 66px;
          border-bottom-right-radius: 4px;
        }
        .hotelAndStarContainer {
          position: absolute;
          bottom: 0;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: end;
          color: #ffffff;
          background-color: rgba(0, 0, 0, 0.7);
          padding: 8px 12px;
          font-family: lato;
          gap: 10px;
          .hotelNameText {
            font-size: $thirteen-pixel;
            letter-spacing: 0.64px;
            line-height: $sixteen-pixel;
            font-weight: bold;
            letter-spacing: 0.2px;

            // white-space: nowrap;
            // overflow: hidden;
          }
          .ratingsContainer {
            font-size: $ten-pixel;
            font-weight: 400;
            letter-spacing: 0.41px;
            text-align: end;
          }
        }
        .packageImg {
          width: 40px;
          height: 40px;
          border: 0.51px solid #979797;
          border-radius: 8px;
          box-sizing: border-box;
        }
      }
    }

    .contentCard {
      position: relative;
      padding: 4px 13px 13px 13px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      &.tripRightPadding {
        padding-right: 9px;
      }

      &.gapStyles {
        justify-content: space-between;
      }
      .reviewsContainer {
        width: 30%;
        max-width: 70px;
        padding-top: 5px;
        // border-right: 0.41px solid rgb(151, 151, 151);

        padding-right: 8px;

        &.noReviews {
          width: 35%;
          max-width: 85px;
          margin-top: 8px;

          &.showHotelRoomReviewDetails {
            margin-top: 32px;
            &.addressRoomNoReviews {
              margin-top: 15px;
              margin-bottom: 15px;
            }
          }

          &.selectedHotelNoReviews {
            &.showHotelRoomReviewDetails {
              margin-top: 15px;
            }
          }
        }
      }

      .distanceAndPriceContainer {
        align-self: stretch;
        display: flex;
        .bookingContainer {
          height: 120%;
          font-family: lato;
          padding-right: 8px;
          border-right: 0.31px solid #979797;
          transform: translateY(-5px);
          padding-top: 7px;

          &.showHotelRoomDetailsStyles {
            height: 113%;
            padding-top: 20px;
            // transform: translateY(-7px);
          }
          .distance {
            font-weight: 400;
            font-size: 10.4px;
            color: #272727;
          }

          .refundText {
            font-size: 9.7px;
            font-weight: 400;
            color: #696969;
            margin-top: 3px;
            text-align: end;

            &.boldText {
              font-weight: bold;
              font-size: 10.4px;
            }
          }
        }
        .selectedHotelPriceContainer {
          display: flex;
          align-items: center;
          margin: 0 8px 0 8px;
          gap: 5px;

          &.expandedSelectedRoomDetails {
            margin-left: 12px;
          }

          .circleCheckIcon {
            height: 27px;
            transform: translateY(1px);
          }
          .selectedPriceContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            font-family: lato;
            color: #272727;
            // margin: 0 10px 0 15px;
            .hotelPrice {
              font-size: 20px;
              font-weight: bold;
            }

            .taxAndFeesContainer {
              font-size: 9px;
              font-weight: bold;
            }

            .selectBtn {
              padding: 8px 20px;
              margin-top: 15px;
            }
          }
        }
        .priceContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          font-family: lato;
          color: #696969;
          margin: 0 11px 0 24.5px;

          &.tripRightMargin {
            margin-right: 0;
            margin-left: 9px;
          }
          .hotelPrice {
            font-size: 20px;
            font-weight: bold;
          }

          .taxAndFeesContainer {
            font-size: 9px;
            font-weight: bold;
          }

          .selectBtn {
            padding: 7.5px 14px;
            margin-top: 15px;
            margin-bottom: 3px;
          }
        }
      }

      .divider {
        border-left: 0.41px solid rgb(151, 151, 151, 0.7);
        align-self: stretch;
      }
      .hotelDetailCard {
        padding-left: 25px;
        align-self: stretch;
        display: flex;
        padding-right: 30px;
        justify-content: space-between;
        flex-direction: column;
        // height: 75px;
        .hotelNameCard {
          display: block;
          position: relative;
          height: 100%;
          // margin-top: 20px;
          color: #272727;
          font-family: lato;

          font-size: $ten-pixel;
          letter-spacing: 0.34px;
          line-height: $twelve-pixel;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .location {
            font-weight: bold;
            // margin-bottom: 5px;
          }
          .confirmationTicket {
            // margin-top: 10px;
          }
        }
        .addCard {
          display: block;
          position: relative;
          .address {
            color: #252525;
            font-family: Lato;
            font-size: $ten-pixel;
            letter-spacing: 0.2px;
            line-height: $twelve-pixel;
            margin-bottom: 5px;
          }
          .distance {
            color: #252525;
            font-family: Lato;
            font-size: $ten-pixel;
            font-weight: bold;
            letter-spacing: 0.21px;
            line-height: $twelve-pixel;
          }
        }
      }

      .refundTextTwo {
        width: 100%;
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;

        color: #696969;
        font-family: Lato;
        font-size: 11px;
        letter-spacing: 0.2px;
        line-height: 10px;
      }

      .amenitiesCard {
        width: 100%;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .amenities {
          color: #696969;
          font-family: Lato;
          font-size: 6.6px;
          letter-spacing: 0.2px;
          line-height: 8px;
          // width: 60px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .selectedHotelRoomName {
      padding-left: 13px;
      font-family: lato;
      color: #1b75ba;
      font-size: 12px;
      font-weight: bold;
      margin-top: -3px;
      transform: translateY(-10px);
      max-width: 60%;
      cursor: pointer;
      // margin-bottom: 2px;

      &.expandedHotelRoomName {
        margin-bottom: 12px;
      }
    }

    .bookingHotelDetailsContainer {
      width: 100%;
      height: 0;

      &.revealHotelDetails {
        height: auto;
        opacity: 1;
        @keyframes revealAnimation {
          from {
            // opacity: 0;
            height: 0;
          }
          to {
            // opacity: 1;
            height: 150px;
          }
        }

        animation: revealAnimation 0.3s ease-in-out;
      }
    }

    .hotelSearchContainer {
      padding: 0px 12px 15px 12px;
      width: 100%;
      .searchIconContainer {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        gap: 8px;

        .searchIcon {
          // height: 14px;
          width: 17px;
        }

        p {
          font-size: 15px;
          letter-spacing: -0.11px;
          font-weight: bold;
          color: #696969;
        }
      }
      .criteriaContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5px;
        margin-top: 13px;
        padding: 0 15px 0 10px;
        .roomsContainer {
          min-width: 20%;
          max-width: 32%;
          flex: 1 1 auto;
          .label {
            color: #696969;
            font-family: Lato;
            font-size: $eight-pixel;
            font-weight: bold;
            letter-spacing: 0.58px;
            line-height: $ten-pixel;
            text-transform: uppercase;
            margin-bottom: 5px;
            margin-left: 2px;
          }
          .buttonStyles {
            height: 37.3px;
            width: 100%;
            // width: 80px;
            // width: 18vw;
            // min-width: 68px;
            // max-width: 85px;
            overflow: hidden !important;
            display: flex;
            align-items: center;
            border: 0.38px solid #979797;
            border-radius: 4px;
            background-color: #f6f6f6;
            padding: 10px 5px;
            color: #222222;
            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0.4px;
            line-height: 9px;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .travelBtn,
          .activeTravelBtn {
            @extend .buttonStyles;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffff;
            line-height: normal;

            p {
              display: flex;
              align-items: center;
              font-size: 9px;
              font-weight: bold;
              padding-top: 1px;

              span {
                font-size: 18px;
                margin-right: 8.56px;
              }
            }

            .arrowIcon {
              width: 12.5px;
              height: 10px;
              cursor: pointer;
              margin-left: 5px;
              margin-right: 2px;
              transition: transform 0.3s ease-in-out;

              &.openPopOverStyles {
                transform: rotate(180deg);
              }
            }
          }
          .activeTravelBtn {
            background-color: #d8efff;
          }
        }
      }
    }

    .roomDetailsContainer {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: 100%;
      .closeBtnContainer {
        display: flex;
        margin-bottom: 10.37px;
        justify-content: space-between;
        .columnAlignButton {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: max-content;
          font-size: $twelve-pixel !important;
          // transform: translateY(10px);
          font-weight: 700 !important;
          color: #696969 !important;
          font-family: Lato !important;
          gap: 6px;
          padding-left: 14px;
          & img {
            width: 24px;
          }
        }
      }

      .showCard {
        display: flex;
        text-align: center;
        justify-content: center;
        margin-top: 3px;
        margin-bottom: 13px;

        color: #696969;
        font-weight: 600;
        font-family: lato;
        font-size: 9px;
        line-height: 12px;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        .showMoreBtn {
          color: #696969;
          font-weight: 600;
          font-family: lato;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.2px;
          .showMoreIcon {
            width: auto;
            height: 20px;
            cursor: pointer;
          }
        }
        .showMoreBtn:hover {
          outline: none;
          background: none !important;
        }
      }

      .header {
        color: #272727;
        font-family: Lato;
        font-size: $seventeen-pixel;
        // font-size: 4.673vw;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
        // line-height: 5.607vw;
        margin-left: 22.75px;
        margin-bottom: 15px;
      }
    }
  }
  .secondCard {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
    padding-left: 8px;
  }
}
