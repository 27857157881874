@import "/src/variables/font_size";

.flightInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  // flex-wrap: wrap;
  padding-right: 8px;
  @media screen and (max-width: 600px) {
    gap: 8px;
    padding-right: 5px;
  }
  .imgCard {
    width: 35px;
    height: 35px;
    @media screen and (max-width: 600px) {
      width: 30px;
      height: 30px;
    }
    .flightImg {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .testCard {
    display: block;
    .arrivalTimeInfo {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      letter-spacing: 0.21px;
      text-align: right !important;
      font-size: $twelve-pixel;
      font-weight: $bold;
      font-style: Bold;
      line-height: $eightteen-pixel;
      margin-bottom: 5px;
      // @media screen and (max-width: 600px) {
      //   font-size: $ten-pixel;
      //   line-height: $fifteen-pixel;
      // }
    }
    .arrivalDateInfo {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      letter-spacing: 0.21px;
      text-align: right !important;
      font-size: $eleven-pixel;
      font-weight: 400;
      // @media screen and (max-width: 600px) {
      //   font-size: $nine-pixel;
      //   text-align: right !important;
      // }
    }
  }
  .flightTime {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: max-content;
    gap: 5px;
    @media screen and (max-width: 600px) {
      gap: 2px;
    }
    .timeStyles {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      letter-spacing: 0.21px;
    }
    .timeInfo {
      @extend .timeStyles;
      font-size: $twelve-pixel;
      font-weight: $bold;
      font-style: Bold;
      line-height: $eightteen-pixel;
      // @media screen and (max-width: 600px) {
      //   font-size: $ten-pixel;
      //   line-height: $fifteen-pixel;
      // }
    }
    .dateInfo {
      @extend .timeStyles;
      font-size: $eleven-pixel;
      font-weight: 400;
      // @media screen and (max-width: 600px) {
      //   font-size: $nine-pixel;
      // }
    }

    .nextDay {
      color: #cc0900;
      font-weight: bold;
    }
  }

  .flightRoute {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    text-align: center;

    .stops {
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $ten-pixel;
      font-weight: $bold;
      font-style: Bold;
      letter-spacing: 0.2px;
      text-align: center;
      line-height: $thirteen-pixel;
      // @media screen and (max-width: 600px) {
      //   font-size: $eight-pixel;
      //   line-height: $eleven-pixel;
      // }
    }

    .line {
      width: 100%;
      border-bottom: 1px solid #696969;
      margin: 5px 0;
    }
    .travelDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      @media screen and (max-width: 600px) {
        gap: 2px;
      }
      .airportCode {
        opacity: 1;
        color: #696969;
        font-family: Lato;
        font-size: $eleven-pixel;
        font-weight: $bold;
        font-style: Bold;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: $thirteen-pixel;

        @media screen and (max-width: 600px) {
          font-size: $ten-pixel;
          line-height: $eleven-pixel;
        }
      }
      .travelTime {
        opacity: 1;
        color: #696969;
        font-family: Lato;
        font-size: $nine-pixel;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.2px;
        text-align: center;
        line-height: $thirteen-pixel;
        @media screen and (max-width: 600px) {
          font-size: $seven-pixel;
          line-height: $eleven-pixel;
        }
      }
    }
  }
}
