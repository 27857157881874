@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .label {
    height: 8px;
    letter-spacing: 0.2px;
    line-height: $eight-pixel;
    margin-bottom: 5px;
    margin-left: 2px;
    opacity: 1;
    color: rgba(105, 105, 105, 1);
    font-family: "Lato-Regular", sans-serif;
    font-size: $eleven-pixel;
    font-weight: 400;
    font-style: Regular;
    text-align: left;
    text-transform: uppercase;
  }
  .airportNotAvail {
    color: #bf150d;
    font-family: "Lato-Regular", sans-serif;
    font-size: $nine-pixel;
    letter-spacing: 0.2px;
    line-height: $eleven-pixel;
    margin-bottom: 10px;
    margin-top: 5px;
    word-break: break-all;
    width: 100%;
    margin-top: 25px;
  }
  .btnStyles {
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-right: 5px;
  }
  .airportCard,
  .activeBtn {
    @extend .btnStyles;
    cursor: pointer;
    .arrowIcon {
      height: 10px;
      width: 10px;
    }
    .card {
      position: relative;
      border-radius: 1.1px;
      border: 1px solid rgba(151, 151, 151, 1);
      opacity: 1;
      background-color: rgba(255, 255, 255, 1);
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      padding-right: 20px;
      padding-left: 10px;
      width: 15vw; 
      max-width: 140px;
      height: 8vh; 
      max-height: 35px; 
      @media screen and (max-width: 600px) {
        width: 120px;
        height: 40px;
      }
      .cityButtonStyles {
        color: #222222;
        font-family: Lato;
        letter-spacing: 0.21px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        @media (min-width: 360px) and (max-width: 395px) {
          width: 100%;
        }
      }
      .btnName {
        @extend .cityButtonStyles;
        font-size: $ten-pixel;
        font-weight: bold;
        line-height: $thirteen-pixel;
      }
      .selectBtn {
        opacity: 1;
        color: #9e9e9e;
        font-family: Lato;
        font-size: $eleven-pixel;
        font-weight: 700;
        font-style: Bold;
        letter-spacing: 0.21px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
      .emptyAirport {
        opacity: 1;
        color: #9e9e9e;
        font-family: Lato;
        font-size: $nine-pixel;
        font-weight: 400;
        letter-spacing: 0.19px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        // text-overflow: ellipsis;
        width: 100%;
      }
      .placeHolder {
        // @extend .cityButtonStyles;
        font-size: $ten-pixel;
        font-weight: 500;
        line-height: $thirteen-pixel;
        color: #696969;
        // font-weight: 600;
        font-family: Lato;
        letter-spacing: 0.5px;
      }
      .city {
        @extend .cityButtonStyles;
        font-weight: 500;
        line-height: $ten-pixel;
        font-size: $eight-pixel;
      }
    }
  }
  .activeBtn {
    @extend .btnStyles;
    background-color: #d8efff;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
