.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-top: 5px;
  gap: 6px;

  @media only screen and (min-width: 767px) {
    padding-top: 10px;
    flex-direction: column-reverse;
    gap: 0px;
  }
  .editIcon {
    width: auto;
    height: 20px;
    cursor: pointer;
    @media only screen and (min-width: 767px) {
      height: 15px;
      cursor: pointer;
    }
  }

  .editText {
    margin-top: 5px;
    font-size: 10px;
    color: #696969;
    font-family: "Lato";
    font-weight: 600;
    cursor: pointer;
    letter-spacing: 0px;
    @media only screen and (min-width: 767px) {
      font-size: 8px;
      margin-top: 2px;
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}
