@import "/src/variables/font_size";

.headerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  padding-right: 15px;
  margin-bottom: 17px;
  opacity: 0.9;
  .header {
    color: #ffffff;
    font-family: Lato;
    font-size: $seventeen-pixel;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 25.75px;
  }
  .roomAndNights {
    font-family: lato;
    font-weight: bold;
    font-size: $fifteen-pixel;
    color: #ffffff;
  }
}
.roomDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
  .closeBtnContainer {
    display: flex;
    margin-bottom: 10.37px;
    justify-content: space-between;
    .columnAlignButton {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      font-size: $twelve-pixel !important;
      // transform: translateY(10px);
      font-weight: 700 !important;
      color: #696969 !important;
      font-family: Lato !important;
      gap: 6px;
      padding-left: 14px;
      & img {
        width: 24px;
      }
    }
  }

  .header {
    color: #272727;
    font-family: Lato;
    font-size: $seventeen-pixel;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 22.75px;
    margin-bottom: 15px;
  }
}

.showCard {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 80px;
  .showMoreBtn {
    color: #696969;
    font-weight: 600;
    font-family: lato;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2px;
    .showMoreIcon {
      width: auto;
      height: 20px;
      cursor: pointer;
    }
  }
  .showMoreBtn:hover {
    outline: none;
    background: none !important;
  }
}
