.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 4.12px;
  // @media screen and (max-width: 350px) {
  //   width: 290px;
  // }
  // @media screen and (max-width: 360px) {
  //   width: 300px;
  // }
  // @media screen and (max-width: 370px) {
  //   width: 310px;
  // }
  // width: 320px;

  // width: 320px;
  // @media (min-width: 360px) and (max-width: 395px) {
  //   width: 200px;
  // }
  @media only screen and (min-width: 767px) {
    width: 300px;
  }
}
.loader {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  color: #222222;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-family: Lato;
}
.autoCompleteField {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
  .searchIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.errorMessage {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-family: Lato;
  color: #bf150d;
  letter-spacing: 0.2px;
}

.airportLabel {
  font-family: Lato;
  font-size: 11px;
  font-family: Lato;
  font-weight: bold;
  .distance {
    color: #7cbeea;
    font-size: 8px;
    font-weight: 500 !important;
  }
}

.formControlContainer {
  padding-left: 8px !important;
}

.icon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #ffffff;
  outline-offset: 2px;
  outline: 1px solid #696969;
}
.checkedIcon {
  outline: 1.5px solid #696969;
  outline-offset: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0b3353;
}
