.inputContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .newInputCard1 {
    display: flex;
    align-items: center;
    gap: 20px;
    // @media (min-width: 360px) and (max-width: 395px) {
    //   gap: 10px;
    // }
    padding-left: 38px;
  }
  .newInputCard2 {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 38px;
  }
  .stepper {
    position: relative;
    padding-left: 38px;
  }
  // .searchBox {
  //   position: relative;
  //   width: 385px;
  //   padding-left: 30px;
  //   @media (min-width: 360px) and (max-width: 395px) {
  //     width: 360px;     
  //   }
  // }
  .searchBox {
    position: relative;
    width: 385px;
    padding-left: 30px;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 360px;     
    }
  }
  .singleCitySearch {
    position: relative;
    width: 385px;
    padding-left: 38px;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 350px;     
    }
  }
}
