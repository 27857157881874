@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    position: relative;
    .label {
      letter-spacing: 0.21px;
      line-height: $eight-pixel;
      margin-bottom: 5px;
      opacity: 1;
      color: #696969;
      font-family: Lato;
      font-size: $eleven-pixel;
      font-weight: 400;
      font-style: Regular;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .buttonStyles {
    border: 1px solid #979797;
    border-radius: 1px;
    box-sizing: border-box;
    height: 32px;
    width: max-content;
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    cursor: pointer;
  }
  .travelBtn,
  .activeTravelBtn {
    height: 40px;
    width: 100px;
    @extend .buttonStyles;
    .travelersCard {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      .travelerBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .totalTravelers {
          font-size: $twenty-pixel !important;
          color: #222222;
          font-family: Lato, sans-serif;
          font-weight: $bold;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
        }

        .travelerLabel {
          color: #222222;
          font-family: Lato, sans-serif;
          font-weight: $bold;
          letter-spacing: 0.2px;
          line-height: $thirteen-pixel;
          background-color: transparent;
          font-size: $nine-pixel;
          letter-spacing: 0.2px;
          margin-top: 2px;
        }
      }
    }
    .roomCard {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-left: 5px;
      .onlyRoomBtn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .roomBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          .roomCount {
            font-size: $twenty-pixel !important;
            color: #222222;
            font-family: Lato, sans-serif;
            font-weight: $bold;
            letter-spacing: 0.2px;
            line-height: $thirteen-pixel;
            background-color: transparent;
          }
          .roomLabel {
            color: #222222;
            font-family: Lato, sans-serif;
            font-weight: $bold;
            letter-spacing: 0.2px;
            line-height: $thirteen-pixel;
            background-color: transparent;
            font-size: $nine-pixel;
            letter-spacing: 0.2px;
            margin-top: 2px;
          }
        }
        .expandIcon {
          width: 14px;
          height: 7.6px;
          margin-right: 5px;
        }
      }
    }

    .travelCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 100%;
      .btnName {
        display: inline;
        color: #222222;
        font-family: Lato;
        font-size: $ten-pixel;
        font-weight: $bold;
        letter-spacing: 0.2px;
        line-height: $thirteen-pixel;
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        .btnCard {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .expandIcon {
            width: 14px;
            height: 7.6px;
            margin-right: 5px;
          }
        }
      }
    }

    // .roomCard {
    //   width: 33px;
    //   height: 100%;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   flex-direction: column;
    //   background-color: #1b75ba;
    //   padding-top: 5px;
    //   .room {
    //     color: #ffffff;
    //     font-family: Lato;
    //     font-size: 7px;
    //     font-weight: bold;
    //     letter-spacing: 0.2px;
    //     line-height: 9px;
    //     text-align: center;
    //     height: 10px;
    //   }
    // }
  }
  .travelBtn {
    background-color: #ffffff;
  }
  .activeTravelBtn {
    background-color: #d8efff;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
