.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .titleCard {
    position: relative;
    display: block;

    .title {
      color: #696969;
      font-size: 16px;
      font-family: Lato;
      font-weight: 600;
      letter-spacing: 0px;
    }
  }
  .selectedChipStyles {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    font-family: Lato;
    border-radius: 50px;
    letter-spacing: 0.2px;
  }

  .updateCard {
    position: relative;
    .duration {
      color: #696969;
      font-family: Lato;
      text-transform: none;
      font-size: 14px;
      font-family: Lato;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    .climateHeader {
      padding-top: 2vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .climateTitle {
        color: #696969;
        font-size: 14px;
        font-family: Lato;
        font-weight: 600;
        letter-spacing: 0px;
      }
      .closeCard {
        display: block;
        cursor: pointer;
        // padding-top: 3vh;

        .closeIcon {
          width: auto;
          height: 30px;
          padding: 6px;
          border-radius: 50%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }
      }
    }

    .selectBox {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 2vh;
      .selectChipCard {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 1vh;
        padding-top: 1vh;

        .selectedChip {
          @extend .selectedChipStyles;
          color: #222222;
          background-color: #a4d1f0;
          border: none;
        }

        .notSelectedChip {
          @extend .selectedChipStyles;
        }
      }
    }
    .slider {
      padding-top: 2vh;
      padding-bottom: 2vh;
      position: relative;
      display: block;
      .duration {
        color: #696969;
        font-size: 14px;
        font-family: Lato;
        font-weight: 700;
        letter-spacing: 0.1px;
        margin-bottom: 2vh;
        margin-top: 2vh;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chipCard {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1vh;
      padding-top: 4vh;
      padding-bottom: 3vh;
      .activeChip {
        color: #222222;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.2;
        font-family: Lato;
        background-color: #a4d1f0;
        border-radius: 50px;
        border: none;
        letter-spacing: 0.2px;
      }
      .upTo {
        font-size: 14px;
        font-weight: 500;
        font-family: Lato;
        letter-spacing: 0px;
        color: #696969;
      }
    }
    .editCard {
      position: relative;
      cursor: pointer;
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
