.hidden {
  visibility: hidden;
}

.mainContainer {
  // height: 160px;
  // height: 146.6px;
  // overflow: hidden;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  //   width: calc(100vw - 10px);
  width: 100%;
  // width: 200px;
  //   margin: 0 -25px;
  @media screen and (min-width: 700px) {
    margin-bottom: 20px;
  }
  .imageContainer {
    // height: 160px;
    width: 100%;
    display: flex;
    aspect-ratio: 1264 / 440;
    max-height: 150px;
    .img {
      // height: 160px;
      object-fit: cover;
      // flex-shrink: 0;
    }
  }
}
.placeHeadingContainerSelected {
  height: 100%;
}
.placeHeadingContainer {
  min-height: 35px;
  // background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
  .textContainer {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    .midContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      justify-content: flex-start;
      width: 100%;
    }
    .midContainerSelected {
      position: absolute;
      top: 40%;
      bottom: 50%;
    }

    .versionContainer {
      border: 1px solid #ffffff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-color: #7cbeea;
      display: grid;
      place-items: center;
      margin-top: -22px;
      & p {
        font-size: 12px;
        color: #ffffff;
      }
    }

    .cityCountryPriceContainer {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    .cityCountryPriceContainerSelected {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    .cityNameContainer {
      display: flex;
    }
    .nextIcon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      width: 15px;
      z-index: 1;
    }
  }
  .placeHeadingMobContainer {
    display: flex;
    flex-direction: column;
    & h2 {
      font-size: 9px;
      font-weight: 400;
      text-transform: uppercase;
      color: #ffffff;
    }
  }
  .placeHeadingMob {
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    // @media screen and (max-width: 550px) {
    //   display: flex;
    // }
    z-index: 1;
  }
  .placeHeadingDesk {
    z-index: 1;
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  .priceText {
    transform: translateY(1px);
    z-index: 1;
    flex-shrink: 0;
    color: #f4f6f8;
    font-family: Lato;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    line-height: 18px;
    text-align: center;
    height: 20px;
    text-align: start;
  }
  .priceTextSelected {
    // position: absolute;
    // bottom: 5px;
  }
}

.selectedIcon {
  z-index: 2;
  // height: 40px;
  width: 15px;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.multiCityIconContainer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  border-radius: 0 0 0 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  & img {
    width: 12px;
  }
  & h1 {
    font-size: 8px;
    color: #ffffff;
    font-weight: 600;
  }
}
