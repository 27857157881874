.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  .flightContainer,
  .weatherContainer {
    height: 80px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 28px;
    position: relative;
    margin-bottom: 10px;
    .closeCard {
      top: 5px;
      right: 5px;
      position: absolute;
      cursor: pointer;
      .closeIcon {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
    .contentCard {
      display: flex;
      align-items: center;
      gap: 15px;
      z-index: 2;
      .planeIcon {
        height: 24px;
        width: 26px;
      }
      .descCard {
        width: 85%;
        // text-wrap: wrap;
        .desc {
          color: #ffffff;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0.2px;
          line-height: 12px;
          opacity: 0.7;
          font-weight: 500 !important;
        }
        .title {
          color: #ffffff;
          font-family: Lato;
          letter-spacing: 0.2px;
          font-weight: bold;
          margin-bottom: 2px;
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
  .flightContainer {
    border: 1px solid #ff0000;
    // background-color: #cc0900;
    // background-color: rgba(255, 0, 0, 0.932);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 0, 0, 0.6);
      // background-color: rgba(204, 9, 0, 0.3);
      pointer-events: none;
    }
  }
  .weatherContainer {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(255, 0, 0, 0.5);
      // background-color: rgba(204, 9, 0, 0.3);
      // background: linear-gradient(180deg, #7cbeea 0%, #376a89 100%);
      pointer-events: none;
    }
  }
}
.hideMain {
  display: none;
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
