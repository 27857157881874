@import "/src/variables/font_size";
.timeInADay {
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 13px;
  // box-shadow:
  //   rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
  //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 100%;
  & h1 {
    font-weight: $bold;
    // color: #272727;
    color: #696969;
    text-transform: uppercase;
    padding-bottom: 11px;
    font-size: $twelve-pixel;
  }
}

.descriptionContainer {
    padding-left: 14px;
    display: flex;
    // padding-top: 2px;
    gap: 3px;
    color: #696969;
    font-size: $twelve-pixel;
  }
  
  .description {
    // color: #272727;
    color: #696969;
    font-size: $twelve-pixel;
    word-wrap: break-word;
    padding-bottom: 3px;
  }
  .description li {
    margin-left: 16px;
  }