@import "/src/variables/font_size";

.noData {
  text-align: center;
  color: #696969;
  font-family: Lato;
  font-size: 14px;
  margin-top: 25px;
}
.loader {
  background: rgba(0, 0, 0, 0.9);
  height: 100dvh;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upcomingTripSubContainer {
  margin-bottom: 33.33px;
  // padding-left: 4px;
  // padding-right: 4px;
  position: relative;
  margin-top: 25px;
  & .bookedDate {
    text-align: end;
    margin-right: 2px;
    margin-top: 4px;
    font-size: $eight-pixel;
    color: #696969;
  }

  .iconWithBookAgainText {
    cursor: pointer;
    display: flex;
    position: absolute;
    left: 26.6px;
    bottom: -20.9px;
    padding-top: 4px;
    padding-left: 6.8px;
    padding-right: 9px;
    padding-bottom: 5px;
    border-radius: 0 0 3.75px 3.75px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    p {
      font-size: $ten-pixel;
      color: #ffffff;
      // font-weight: 400;
      font-weight: bold;
      opacity: 0.9;
    }
    .nextIcon {
      width: 9.3px;
    }
  }
  .bookedDate {
    text-align: end;
    margin-right: 7px;
    margin-top: 5px;
    font-weight: 400;
    font-size: $ten-pixel;
    color: #696969;
    position: absolute;
    right: 0;
    bottom: -15px;
  }
}
