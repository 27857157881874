.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  background-color: #f3f3f3;

  .content {
    width: 100%;
    height: 100vh;
    background-color: #ffff;
    overflow: auto;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    .closeCard {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: 10px 15px 0px 0px;
      cursor: pointer;

      .closeIcon {
        width: auto;
        height: 20px;
        cursor: pointer;
      }
    }
  }

  .container {
    // min-width: 350px;
    // max-width: 500px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;

    .title {
      font-size: 16px;
      color: #222222;
      font-weight: 600;
      font-family: Lato;
      text-align: center;
    }

    .desc {
      font-weight: 500;
      color: #222222;
      font-family: Lato;
      font-size: 12px;
      font-style: italic;
      text-align: center;
    }
  }

  @-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
      transform: translateX(-1000px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.constText {
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  margin-bottom: 20px;

  & h1 {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 13px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    // margin-left: -15px;
    // margin-right: -30px;
    @media only screen and (min-width: 1000px) {
      text-align: center;
      font-size: 11px;
      padding-left: 0px;
    }
  }
  & p {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    text-align: center;
    max-width: 380px;
    // margin-left: -15px;
    // margin-right: -30px;
    @media only screen and (min-width: 1000px) {
      text-align: center;
      font-size: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
