@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .hotelListCard {
        width: 100%;
        // height: 128px;
        border-radius: 6px;
        border: 1px solid #979797;
        // border-bottom: 1px solid #979797;
        // border-left: 1px solid #979797;
        // border-right: none;
        display: flex;
        background-color: #ffff;
        // gap: 10px;
        overflow-x: auto;
        scrollbar-width: thin;
        margin-bottom: 8px;
        // padding-bottom: 12px;
        overflow-y: hidden;

        &.selectedHotelListCard {
          border: 1.7px solid #fbb040;
        }
        .card1 {
          width: 100%;
          height: 100%;
          padding: 6px 5px;
          // background-color: #f9e9be;
          //   padding: 20px 10px;
          // padding-left: 6px;
          display: flex;
          // align-items: center;
          gap: 14px;
          // width: 180px;
          .imgCard {
            width: 66px;
            height: 100%;
            .flightImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 0.51px solid #979797;
              border-radius: 8px;
              box-sizing: border-box;
            }
          }

          .flightTime {
            color: #696969;
            font-size: 8px;
            line-height: 15px;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-family: lato;
            .supText {
              color: #bf150d;
              margin-left: 2px;
            }
            .flightName {
              font-weight: 500 !important;
            }
          }
        }
        .card2 {
          padding: 1.2vh 0px;
          width: 60px;
          .stops {
            color: #696969;
            font-family: Lato;
            font-size: 8px;
            line-height: 12px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 5px;
            .stopInfo {
              font-weight: 500;
            }
          }
        }
        .card3 {
          display: none;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 80px;
          .flightBtn {
            font-family: Lato;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: none;
            .viewDetails {
              color: #696969;
              font-family: Lato;
              font-size: 6.6px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 8px;
              text-align: center;
            }
            .iconAndText {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              .viewDetailsBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                .expIcon {
                  width: 14px;
                  height: 10px;
                  cursor: pointer;
                  margin-bottom: 5px;
                }
              }
            }
          }
          .flightBtn:hover {
            background-color: transparent !important;
          }
        }
        .lastCard {
          width: 150px !important;
          // height: 100%;
          .discountCard {
            padding: 10px;
          }
          .card4 {
            width: 100% !important;
            // min-width: 100px !important;
            // max-width: 200px;
            height: 100%;
            background-color: #1d75bb;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            // gap: 8px;
            // border-top-right-radius: 2.6px;
            // border-bottom-right-radius: 2.6px;
            border-top-left-radius: 2.6px;
            &.selectedHotelRoom {
              background-color: #fbb040;
            }
            .price {
              color: #ffffff;
              font-family: Lato;
              font-size: 13.3px;
              letter-spacing: 0.4px;
              line-height: 16px;
              text-align: center;
              font-weight: bold;

              &.selectedPrice {
                color: #272727;
              }
            }
            .flightClass {
              color: #ffffff;
              font-family: lato;
              font-size: $nine-pixel !important;
              letter-spacing: 0.4px !important;
              line-height: $fourteen-pixel !important;
              text-align: center;
              margin-top: -2px !important;
              text-transform: capitalize;
              font-weight: 500;
              opacity: 0.8;
              text-align: center;

              &.selectedFlightClass {
                color: #272727;
              }
            }
            .cancellation {
              color: #ffffff;
              font-family: lato;
              font-size: $seven-pixel;
              font-weight: bold;
              letter-spacing: 0.4px;
              line-height: $ten-pixel;
              text-transform: capitalize;
              margin-bottom: 5px;
              margin-top: 8px;
              opacity: 0.8;
              &.selectedCancellation {
                color: #222222;
              }
            }
            .bookBtn,
            .selectedBtn {
              width: 62px;
              height: 20px;
              border-radius: 30px;
              box-shadow: none;
              border: none;
              outline: none;
              // font-size: 6px;
              // line-height: 10px;
              font-size: 8px;
              line-height: 12px;
              text-align: center;
              font-family: Lato;
              font-weight: 800 !important;
              letter-spacing: 0.5px;
              font-family: lato;
            }
            .bookBtn {
              color: #222222;
              background-color: #fcb400;
            }
            .selectedBtn {
              color: #ffffff;
              background-color: #272727;
            }
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
