.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffff;
  position: relative;
  display: flex;
  flex-direction: column;

  .headBox {
    background-color: transparent;
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: 10px;
    margin-right: 8px;
    margin-bottom: 15px;
    gap: 5px;
    .editLogo {
      height: 14px;
      width: auto;
      cursor: pointer;
    }

    .title {
      font-size: 10px;
      line-height: 1;
      // opacity: 0.8;
      font-weight: 600;
      font-family: Lato;
      color: #696969;
      text-transform: uppercase;
    }
  }
  .box {
    background-color: #ffff;
    width: 100%;
    position: relative;
  }
  height: 100%;
  // width: 250px;
  // @media screen and (min-width: 700px) {
  //   width: 260px;
  // }
  @media only screen and (min-width: 700px) {
    max-width: 170px;
    width: 14vw;
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.divider {
  position: absolute;
  border-top: 0.5px solid #979797;
  // margin: 0 18px;
  top: 110px;
  z-index: 2;
  left: 18px;
  right: 18px;
}

.arrowButton {
  position: absolute;
  top: 75px;
  z-index: 2;
  right: 18px;
  height: 15px;
}
