@import "/src/variables//font_size";
.roomDetailsContainer {
  //   .closeBtnContainer {
  //     display: flex;
  //     margin-bottom: 10.37px;
  //     justify-content: space-between;
  //     .columnAlignButton {
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-end;
  //       width: max-content;
  //       font-size: 12px !important;
  //       // transform: translateY(10px);
  //       font-weight: 700 !important;
  //       color: #696969 !important;
  //       font-family: Lato !important;
  //       gap: 6px;
  //       padding-left: 14px;
  //       & img {
  //         width: 24px;
  //       }
  //     }
  //   }

  .header {
    color: #272727;
    font-family: Lato;
    font-size: $seventeen-pixel;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 22.75px;
    margin-bottom: 15px;
  }

  .imgSlider {
    height: 147px;
  }

  .roomDetailsInfoContainer {
    .hotelDescriptionContainer {
      padding-left: 7.562vw;
      margin-bottom: 15px;
      @media screen and (min-width: 768px) {
        padding-left: 25.3px;
        padding-right: 25.3px;
      }
      .contentCard {
        position: relative;
        display: block;
        // margin-top: -8px;
        // min-width: 130px;
        // max-width: 100%;

        .hotelName {
          color: #252525;
          font-family: lato;
          font-size: $fifteen-pixel;
          //   line-height: 16vw;
          font-weight: bold;
          letter-spacing: 0.34px;
          margin-top: 25px;
          // width: 130px;
        }
        .location {
          color: #252525;
          font-family: Lato;
          font-size: $nine-pixel + 0.345px;
          text-transform: uppercase;
          letter-spacing: 0.34px;
          //   line-height: 3.894vw;
          margin-top: 3px;

          &.lastChild {
            margin-bottom: 13px;

            span {
              color: #1b75ba;
              text-decoration: underline;
              text-underline-offset: 2px;
              cursor: pointer;
              text-underline-position: above;
            }
          }
        }
        .landMark {
          color: #252525;
          font-family: Lato;
          font-size: $nine-pixel + 0.2px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 10px;
          margin-bottom: 34px;
          //   @media screen and (min-width: 768px) {
          //     font-size: 17px;
          //     letter-spacing: 0px;
          //     margin-bottom: 18px;
          //     line-height: 15px;
          //   }
        }
      }
    }
    .amenitiesClass {
      align-items: flex-start;

      @media screen and (min-width: 768px) {
        margin-top: 15px;
      }

      .amenityListConainerClass {
        column-gap: 12vw;
        @media screen and (min-width: 768px) {
          column-gap: 40px;
        }
        .amenityItemClassName {
          @media screen and (min-width: 768px) {
            font-size: $twelve-pixel;
            // margin-left: 10px;
          }
        }
      }
    }
  }
}
