@import "/src/variables//font_size";
.reviewsContainer {
  padding: 0 28.75px 0 22.75px;
  .header {
    color: #272727;
    font-family: Lato;
    font-size: $eightteen-pixel + 0.7px;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;

    margin-bottom: 28.28px;
  }

  .reviewRow {
    margin-bottom: 24px;
    padding-left: 6px;
    border-bottom: 0.31px solid #979797;
    padding-bottom: 26.5px;
    .reviewHeading {
      color: #222222;
      font-family: Lato;
      font-size: $eleven-pixel + 0.22px;
      font-weight: bold;
      letter-spacing: 0.24px;
      line-height: 15.57px;
    }

    .ratings {
      color: #252525;
      font-family: Lato;
      font-size: $eleven-pixel + 0.21px;
      font-weight: bold;
      letter-spacing: 0.24px;
      line-height: 15.6px;
      //   text-align: right;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 26.5px;
      margin-left: -1px;

      margin-bottom: 10px;
    }
    .ratingsAndTimeContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;

      color: #252525;
      font-family: Lato;
      font-size: $nine-pixel;
      font-weight: bold;
      letter-spacing: 0.18px;
      line-height: 15px;
      //   text-align: right;
      text-transform: uppercase;

      .ratings {
        display: flex;
        gap: 12.7px;
      }
    }

    .description {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 21px;
      color: #222222;
      font-family: Lato;
      font-size: $ten-pixel + 0.28px;
      letter-spacing: 0.22px;
      line-height: 15.6px;
      margin-top: 10px;
      margin-bottom: 16px;

      .descImg {
        margin-top: 3px;
        width: 13px;
      }
    }

    .ownerAndTimeContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-left: 4px;
      font-size: $eleven-pixel + 0.21px;
      letter-spacing: 0.24px;
      font-weight: normal;

      .ownerText {
        display: flex;
        align-items: center;
        gap: 5px;
        font-weight: bold;

        p {
          transform: rotate(90deg);
          margin-bottom: -2.5px;
          margin-left: 2px;
          font-size: $nine-pixel;
        }
      }

      .dateText {
        opacity: 0.8;
      }
    }
  }
}
