@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 70px;
    gap: 5px;
    .weatherIcon {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
    .weather {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 5px;

      .high,
      .low {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: #272727;
        font-family: Lato;
        font-size: $eight-pixel;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 10px;
        text-align: center;
      }

      .low {
        justify-content: flex-end;
      }
    }
    .city {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.25px;
        line-height: 14px;
        text-align: right;
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
