.mainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  
  // display: none;
  .container {
    .homeMenuCard {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
      position: absolute;
      top: 25px;
      left: 35px;

      .homeMenuIcon {
        height: 22px;
        width: auto;
        cursor: pointer;
      }
    }

    .navBar {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 60px;
      width: 100vw;
      // background-color: #fff !important;
      // box-shadow:
      //   rgba(27, 31, 35, 0.04) 0px 1px 0px,
      //   rgba(255, 255, 255, 0.25) 0px 1px 0px inset;
      .navBox {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .menuCard {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          // position: absolute;
          // top: 25px;
          // left: 35px;
          padding-left: 35px;

          .menuIcon,
          .whiteMenuIcon {
            height: 22px;
            width: auto;
            cursor: pointer;
          }
          .whiteMenuIcon {
            height: 15px;
          }

          .logo {
            margin-top: 7px;
            height: 20px;
            width: auto;
            cursor: pointer;
          }
        }
        .hideButton {
          display: none;
        }
        .button {
          color: #696969;
          font-family: Lato;
          font-weight: 600;
          font-size: 9px;
          gap: 5px;
          border-radius: 5px;
          width: 120px;
          margin-right: 15px;
          .buttonIcon {
            width: 20px;
            height: 20px;
          }
        }

        .button:disabled {
          background-color: #d5d5d5;
        }
      }
    }

    .sidebar {
      position: absolute;
      top: 0;
      right: 0;
      width: 0px;
      height: 100dvh;
      background-color: #ffff;
      color: black;
      z-index: 99999 !important;
      // border-left: 0.2px solid hsl(0, 0%, 0%, 0.2);
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      scroll-behavior: smooth;
      overscroll-behavior: contain;

      .box {
        position: relative;
        width: 100%;
        // height: 100% !important;
        height: calc(100dvh - 180px);
        // min-height: 100vh;
        background-color: #ffff;
        overflow-y: scroll;
        overscroll-behavior: contain;

        .termArrContainer {
          bottom: 0;
          position: fixed;
          width: 100%;
          background: white;
          padding-bottom: 20px;
          .sideContainer {
            // border-top: 1px solid #979797;
            // min-height: 80px;
            padding: 5px 20px 5px 65px;
            display: flex;
            align-items: center;
            gap: 20px;

            &:first-of-type {
              margin-top: 20px;
            }

            &.terms_contact {
              // margi
              border-top: none;
              // border-bottom: 1px solid #979797;
            }

            &.aboutUs {
              padding-bottom: 0px;
              padding-left: 20px;
            }
            .optionIcon {
              width: 25px;
              height: 25px;
            }
            .optionsCard {
              display: flex;
              align-self: center;
              justify-content: space-between;
              width: 100%;
              gap: 30px;
              text-decoration: none;
              .descCard {
                display: flex;
                align-items: center;
                justify-content: center;

                .infoIcon {
                  width: 20px;
                  margin-right: 15px;
                  // height: 25px;
                }
                .tripTitle {
                  color: #696969;
                  font-size: 14px;
                  line-height: 14px;
                  word-spacing: 4px;
                  text-align: left;
                  font-family: Lato;
                  font-weight: 600;
                  text-transform: uppercase;
                }
                .tripDesc {
                  color: #8f8f8f;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: left;
                  margin-top: 2px;
                  font-family: lato;
                  font-weight: 500;
                }
              }
              .arrowCard {
                padding-top: 5px;
                padding-left: 5px;
                .arrowIcon {
                  width: auto;
                  height: 20px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .sidebar::-webkit-scrollbar {
      display: none;
    }

    .sidebarOpen {
      width: 100%;
      background-color: transparent;
      .subSideBar {
        width: 400px;
        left: 0;
        transition: 0.3s !important;
        background-color: #ffffff;
        border-right: 1.5px solid hsl(0, 0%, 0%, 0.2);
        .closeCard {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .closeButton {
            color: #696969;
            font-size: 10px;
            line-height: 13px;
            font-weight: 500;
            font-family: Lato;
            letter-spacing: 0.2px;
            padding: 0 !important;
            margin-right: 20px !important;
            margin-top: 10px;
            .closeIcon {
              cursor: pointer;
              height: 1em;
              width: auto;
            }
          }
        }

        .sideTermsContainer {
          width: 100%;

          &.loggedOutUser {
            position: absolute;
            // top: 70vh;
            bottom: 50px;
          }
          .sideContainer {
            // border-top: 1px solid #979797;
            // min-height: 80px;
            padding: 5px 20px 5px 65px;
            display: flex;
            align-items: center;
            gap: 20px;

            &:first-of-type {
              margin-top: 20px;
            }

            &.terms_contact {
              // margi
              border-top: none;
              // border-bottom: 1px solid #979797;
            }

            &.aboutUs {
              padding-bottom: 0px;
              padding-left: 20px;
            }
            .optionIcon {
              width: 25px;
              height: 25px;
            }
            .optionsCard {
              display: flex;
              align-self: center;
              justify-content: space-between;
              width: 100%;
              gap: 30px;
              text-decoration: none;
              .descCard {
                display: flex;
                align-items: center;
                justify-content: center;

                .infoIcon {
                  width: 20px;
                  margin-right: 15px;
                  // height: 25px;
                }
                .tripTitle {
                  color: #696969;
                  font-size: 14px;
                  line-height: 14px;
                  word-spacing: 4px;
                  text-align: left;
                  font-family: Lato;
                  font-weight: 600;
                  text-transform: uppercase;
                }
                .tripDesc {
                  color: #8f8f8f;
                  font-size: 12px;
                  line-height: 16px;
                  text-align: left;
                  margin-top: 2px;
                  font-family: lato;
                  font-weight: 500;
                }
              }
              .arrowCard {
                padding-top: 5px;
                padding-left: 5px;
                .arrowIcon {
                  width: auto;
                  height: 20px;
                  cursor: pointer;
                }
              }
            }
          }

          .loginCard {
            // bottom: 50px;
            // position: absolute;
            // left: 40%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            @media only screen and (max-width: 767px) {
              // left: 40%;
            }
          }
        }

        @media only screen and (max-width: 767px) {
          width: calc(100vw - 10vw);
          height: 100dvh;
          overflow-y: hidden;
        }

        @media only screen and (max-width: 600px) {
          width: calc(100vw - 10vw);
          transition: width 0.1s ease-in-out;
          animation: slideIn 0.3s linear forwards;
        }
      }
    }

    .sideBarClose {
      left: -400px;
      width: 0px;
      opacity: 0;
      background-color: #ffffff;
      transition: 0.6s !important;
      .closeCard {
        opacity: 0;
      }

      .termArrContainer {
        display: none;
      }

      .loginCard {
        opacity: 0;
      }
    }

    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
        background-color: #ffffff;
      }

      100% {
        transform: translateX(0);
        background-color: #ffffff;
      }
    }

    // &.open .sidebar {
    //     animation: smoothScroll 0.5s forwards;
    // }
  }
}
