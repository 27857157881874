.newLoaderContainer {
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  // height: 100% !important;
  display: flex;

  width: 100%;
  height: 100vh;

  .newLoaderComponent {
    .testWrapper {
      color: white;
    }
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    @media screen and (max-width: 500px) {
      margin-top: -4vw;
    }
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 31.77px;

    @media screen and (max-width: 500px) {
      gap: 6.928vw;
    }

    .loaderIcon {
      @media screen and (max-width: 500px) {
        width: 13.857vw;
      }
    }

    .failureIconStyles {
      margin-top: -10vh;
      opacity: 0.6;
      max-width: 250px;
      @media screen and (max-width: 500px) {
        width: 70vw;
      }
    }
  }

  .loaderText {
    color: #ffffff;
    font-family: "Lato";
    font-weight: bold;
    letter-spacing: 0.266px;
    line-height: 15.24px;
    margin-top: 24.25px;
    margin-bottom: 30.13px;

    @media screen and (max-width: 500px) {
      font-size: 2.771vw;
      letter-spacing: 0.059vw;
      line-height: 3.387vw;
      margin-top: 6.31vw;
      margin-bottom: 7.313vw;
    }

    &.subTextExists {
      margin-bottom: 15px;

      @media screen and (max-width: 500px) {
        margin-bottom: 2vw;
      }
    }
  }

  .loaderSubText {
    color: #ffffff;
    font-family: "Lato";
    font-weight: bold;
    letter-spacing: 0.266px;
    line-height: 15.24px;

    @media screen and (max-width: 500px) {
      font-size: 2.771vw;
      letter-spacing: 0.059vw;
      line-height: 3.387vw;
    }
  }

  .cancelBtn {
    background-color: #000000;
    font-size: 12px;
    font-family: Lato;
    font-weight: bold;
    letter-spacing: 0.2px;
    box-shadow: none;
    color: #f9f9f9;
    border: 1px solid #979797;
    outline: none;
    border-radius: 50px;
    cursor: pointer;
    @media screen and (min-width: 500px) {
      margin-top: 60px;
      padding-top: 5px;
      padding-bottom: 6px;
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (max-width: 500px) {
      //   margin-bottom: -100px;
      font-size: 2.694vw;
      width: 18.63vw;
      height: 6.928vw;
      // position: absolute;
      margin-top: 8vw;
    }
  }
}
