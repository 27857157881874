.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 30px;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 30px;

    .title {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 600;
        font-family: Lato;
        color: #696969;
    }

    .chipCard {
        display: flex;
        gap: 6px;
        width: 100%;
        flex-wrap: wrap;
        max-width: 350px;
        margin-top: 25px;

        .activeChip {
            color: #222222;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.2;
            font-family: Lato;
            background-color: #A4D1F0;
            border: 1px solid #A4D1F0;
            border-radius: 40px;
            letter-spacing: 0.2px;
            font-weight: 600;
            padding: 0 12px;
            height: 33px;
            margin-bottom: 3px;
        }

        .chip {
            color: #696969;
            padding: 0 12px;
            font-size: 13px;
            line-height: 1.2;
            font-family: Lato;
            background-color: #ffff;
            border-radius: 40px;
            border: 1px solid #A4D1F0;
            letter-spacing: 0.2px;
            font-weight: 600;
            height: 33px;
            margin-bottom: 3px;
        }
    }

    .seeMore {
        font-size: 13px;
        line-height: 1.2;
        color: #1fbccc;
        font-family: Lato;
        cursor: pointer;
    }
}

.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}