@use "./variables/color" as *;

* {
  padding: 0;
  font-family: "Lato";
  margin: 0;
  box-sizing: border-box;
  // overscroll-behavior: none;

  -webkit-overflow-scrolling: auto;
}
html {
  overflow-x: hidden;
  overflow-y: var(--hidden--);
  // overscroll-behavior: none;
  // scroll-behavior: smooth;

  -webkit-overflow-scrolling: auto;
}

// input {
//   color: #222222 !important;
// }

body {
  // overscroll-behavior: none;

  -webkit-overflow-scrolling: auto;
}

/* For WebKit browsers */
html::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar */
  height: 0; /* Hides the horizontal scrollbar */
  overscroll-behavior: none;

  -webkit-overflow-scrolling: auto;
}

/* For Firefox */
html {
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
  backface-visibility: hidden;
  -webkit-overflow-scrolling: auto;
  overflow-y: var(--hidden--);
}
b {
  font-weight: 700;
}
i {
  font-weight: 400;
  // margin: 0 10px;
}

strong {
  // color: #007AFF;
}

h1 {
  color: $font-color;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 500;
}

h2 {
  font-family: "Lato";
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

h3 {
  font-family: "Lato";
  color: $font-color;
  font-size: 12px;
  font-weight: 600;
}

h4 {
  font-family: "Lato";
  color: $font-color;
  font-size: 16px;
  font-weight: 700;
}

h5 {
  font-family: "Lato";
  font-weight: 700;
}
h6 {
  font-family: "Lato";
  color: $font-color;
  font-size: 12px;
  font-weight: 400;
}

input::placeholder {
  font-family: "Lato";
}

input {
  font-family: "Lato";
}

label {
  font-family: "Lato";
}

.test {
  color: #9c9c9c;
}

/** Swipe to delete **/
.rstdi {
  --rstdiHeight: 30px;
  --rstdiTransitionDuration: 250ms;
  --rstdiTranslate: 0px;
  --rstdiIsRtl: 0;
  --rstdiDeleteColor: rgba(252, 58, 48, 1);
  --rstdiDeleteWidth: 75px;
  --rstdiButtonMarginRight: 0px;
  --rstdiButtonMarginLeft: 0px;

  width: auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: var(--rstdiHeight);
  max-height: calc(var(--rstdiHeight) + 10px);
}

.rstdi *,
.rstdi *:before,
.rstdi *:after {
  box-sizing: border-box;
}

.rstdi.deleting {
  transition: all var(--rstdiTransitionDuration) ease-out;
  max-height: 0;
}

.rstdi .content {
  height: 100%;
  width: auto;
  position: relative;
  transform: translateX(var(--rstdiTranslate));
}

.rstdi .content.transition {
  transition: transform var(--rstdiTransitionDuration) ease-out;
}

.rstdi .content.deleting {
  height: 100%;
  width: auto;
  position: relative;
  transform: scale(0) translateX(var(--rstdiTranslate));
}

.rstdi .delete {
  position: absolute;
  right: 4px;
  left: 0px;
  height: 100%;
  top: 1px;
  background-color: var(--rstdiDeleteColor);
  background-color: #cc0900;
  // height: 58px;
  height: 32px;
  height: var(--rstdi-delete-btn-height);
  font-weight: 400;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  // border-radius: 0 5px 5px 0;
}

.rstdi .delete button {
  // width: var(--rstdiDeleteWidth);
  // transition: margin transform var(--rstdiTransitionDuration) ease-in-out;
  // margin-left: var(--rstdiButtonMarginLeft);
  // margin-right: var(--rstdiButtonMarginRight);
  background-color: #cc0900;
  text-align: center;
  height: 100%;
  background: transparent;
  border: none;
  color: white;
  // padding-right: 5px;
  font-size: 10px;
  @media screen and (max-width: 700px) {
    font-size: 13.3px;
  }
  position: absolute;
  right: 0;
  width: 100px;
}

/**
  React calander Date range
**/
.react-calendar {
  margin-top: -8px !important;
  border: none !important;
  font-family: "Lato" !important;
}

.react-calendar * {
  font-family: "Lato" !important;
  color: #222222 !important;
  font-weight: 600 !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none !important;
}

.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button {
  margin-top: 4px !important;
  height: 40px;
  font-size: 20px;
  border: 1px solid #9c9c9c !important;
  border-left: none !important;
  border-radius: 0 6px 6px 0;
  margin-left: -16px !important;
}
.react-calendar__navigation__next-button {
  margin-top: 4px !important;
  height: 40px;
  font-size: 20px;
  border: 1px solid #9c9c9c !important;
  border-right: none !important;
  border-radius: 6px 0 0 6px;
  margin-right: -16px !important;
}

.react-calendar__tile {
  width: 40px !important;
  height: 47px !important;
  margin-bottom: 8px !important;
}

// today date
.react-calendar__tile--now {
  background-color: transparent !important;
}

.react-calendar__tile--active {
  background-color: #d8efff !important;
  border: 1px solid #d1c6c6 !important;
  // border-right: none !important;
  // border-bottom: none !important;
  border-radius: 50% !important;
}

// selected range or date
.react-calendar__tile--range {
  background-color: #d8efff !important;
  border: 1px solid #d1c6c6 !important;
  // border-right: none !important;
  // border-bottom: none !important;
  border-radius: 4px !important;
}

// start
.react-calendar__tile--rangeStart {
  // border-right: none !important;
  // border-bottom: none !important;
  // border-radius: 4px 0 0 4px !important;
}

// end
.react-calendar__tile--rangeEnd {
  // border-left: none !important;
  // border-top: none !important;
  // border-radius: 0 4px 4px 0 !important;
}

// month and year label
.react-calendar__navigation__label__labelText {
  font-weight: 600 !important;
  font-size: 17px;
}

// Google signin btn
.S9gUrf-YoZ4jf {
  border: 1px solid black !important;
  width: 100% !important;
}
