@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0px 0px 20px 20px;
  .location {
    position: relative;
    margin-bottom: 20px;
  }
  .travelCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
  }
}
