.loaderContainer {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 700px) {
    width: 90vw;
  }
  width: 380px;
}
.container {
  width: 100%;
  justify-self: center;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  // gap: 5px;
  position: relative;
  flex-grow: 1;
  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .chatIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  .heading {
    color: #222222;
    font-family: Lato;
    font-size: 13px;
    white-space: pre-wrap;
    font-weight: bold;
    // padding: 0 20px;
  }
  .para {
    align-self: flex-start;
    color: #222222;
    font-family: Lato;
    font-size: 14px;
    // margin-left: 42px;
    white-space: pre-wrap;
  }
}

.markdown {
  // padding-left: 20px;
  // padding-left: 30px;
  color: #222222;
  font-family: Lato;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  padding-left: 40px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
  // white-space: text-wrap;
  margin-right: 25px;
}
.markdownPaddingLeft {
  padding-left: 52px;
}

.markdown li {
  margin-left: 16px;
}
