.mainContainer{
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    align-items: center;
    gap: 12px;
    min-height: 100dvh;
    @media screen and (max-width: 700px){
        min-height: calc(100dvh - 70px);
        padding-top: 54px;
    }
    padding-top: 114px;
}

.inputLabelContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    .label{
        font-size: 11px;
        margin-bottom: 5px;
    }
}

.button{
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    width: 120px !important;
    display: flex !important;
    align-items: center !important;
}