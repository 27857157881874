@import "/src/variables/font_size";
.mainContainer {
  margin-bottom: -20px;
  min-width: 150px;
  // @media screen and (max-width: 350px) {
  //     width: 290px;
  //   }
  //   @media screen and (max-width: 360px) {
  //     width: 300px;
  //   }
  //   @media screen and (max-width: 370px) {
  //     width: 310px;
  //   }
  //   width: 320px;
  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding-top: 20px;
    .starCards,
    .selectedCard {
      width: 56px;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #979797;
      border-radius: 4px;
      gap: 5px;
      .num {
        color: #222222;
        font-family: Lato;
        font-size: $nine-pixel;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: $eleven-pixel;
      }
      .starIcon {
        height: 13px;
        width: 13px;
        cursor: pointer;
        border-radius: 50%;
        // border: 1px solid #7CBEEA;
        // background-color: #ffffff;
      }
    }
    .starCards {
      background-color: #ffffff;
    }
    .selectedCard {
      background-color: #d8efff;
    }
  }
}

.prevIcon {
  rotate: 90deg;
  width: 15px;
  margin-bottom: 4px;
}
.nextIcon {
  width: 15px;
  rotate: -90deg;
  margin-bottom: 4px;
}
