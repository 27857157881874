.main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .message {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    font-family: lato;
    letter-spacing: 0.2px;
    color: #696969;
    text-align: center;
  }
}
