.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .titleCard {
        position: relative;
        margin-bottom: 3vh;
        .title {
            color: #696969;
            font-family: Lato;
            text-transform: uppercase;
            font-size: 14px;
            font-family: Lato;
            font-weight: 600;
            letter-spacing: 0.5px;
        }
    }
    .optionCard {
        position: relative;
        .priceCard {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-top: 1vh;
            gap: 20px;
            margin: 0;
            .btn1,
            .btn2,
            .btn3 {
                font-size: 12px;
                line-height: 1.2;
                font-family: Lato;
                font-weight: 500;
                color:#696969;
            }
            .btn1 {
                margin-left: 2vw;
                @media only screen and (max-width: 767px) {
                    margin-left: 6vw;
                }
            }
            .btn2 {
                margin-left: 2vw;
                @media only screen and (max-width: 767px) {
                    margin-left: 6vw;
                }
            }
            .btn3 {
                 margin-left: 1vw;
                 @media only screen and (max-width: 767px) {
                    margin-left: 2vw;
                }
            }
          }     
    }
}
.main::after,
.main::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}