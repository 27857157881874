.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  background-color: #f3f3f3;
  height: 100vh;
  overflow: hidden;
  @media only screen and (min-width: 1000px) {
    display: flex;
  }
  .layout {
    position: relative;
    max-width: 1000px;
    .container {
      position: relative;
      display: flex;
      gap: 10px;

      .chat {
        margin-right: 0px;
        background-color: #ffffff;
      }

      .topDest {
        // width: 100%;
        height: 100%;
        // width: 35vw;
        width: 415px;
        // width: 100%;
        overflow: auto;
        position: relative;
        @media only screen and (min-width: 1000px) {
          // min-width: 350px;
          // min-width: 400px;

          max-width: 415px;
        }
      }

      .itineraryDetails {
        // width: 100%;
        height: 100vh;
        overflow: auto;
        position: relative;
        background-color: #ffff;
        width: 415px;
        @media only screen and (min-width: 1000px) {
          // min-width: 400px;
          max-width: 415px;
          // max-width: 450px;
        }
      }
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f2f2f2;
}
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
}
::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1;
}
