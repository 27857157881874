.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    margin-bottom: 30px;
    border-bottom: 1px solid #c2c2c2;
    padding-bottom: 30px;
    .titleCard {
        position: relative;
        margin-bottom: 3vh;
        .title {
            font-size: 12px;
            line-height: 1.2;
            font-weight: 500;
            color: #696969;
            font-family: Lato;
            text-transform: uppercase;
            letter-spacing: 0px;
            font-weight: 700;
        }
    }
    .optionCard {
        position: relative;
        .toggleButtonWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0;
            width: 100%;
          }
          
          .toggleButtonNumber {
            margin-top: 5px;
            font-size: 14px;
            color: #222222;
          }
          .priceCard {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding-top: 1vh;
            gap: 20px;
            max-width: 270px;
            margin: 0;
            .btn1,
            .btn2,
            .btn3 {
                font-size: 12px;
                line-height: 1.2;
                font-family: Lato;
                font-weight: 500;
                color:#696969;
            }
            .btn1 {
                // margin-left: 6vw;
                margin-left: 20px;
            }
            .btn2 {
                // margin-left: 6vw;
            margin-left: 10px;    
            }
            .btn3 {
                //  margin-left: 2vw;
                margin-right: 5px; 
            }
          }
    }
}
.main::after,
.main::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}