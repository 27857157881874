@import "/src/variables/font_size";

.closeBtnContainer {
  display: flex;
  margin-bottom: 10.37px;
  justify-content: space-between;
  .columnAlignButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    font-size: $twelve-pixel !important;
    // transform: translateY(10px);
    font-weight: 700 !important;
    color: #ffffff !important;
    font-family: Lato !important;
    gap: 6px;
    padding-left: 14px;
    & img {
      width: 34px;
      transform: rotate(180deg);
    }
  }
}

.header {
  color: #ffffff;
  font-family: Lato;
  font-size: $seventeen-pixel;
  // font-size: 4.673vw;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  // line-height: 5.607vw;
  margin-left: 25.75px;
  margin-bottom: 10px;
}

.roomDetailsContainer {
  .closeBtnContainer {
    display: flex;
    margin-bottom: 10.37px;
    justify-content: space-between;
    .columnAlignButton {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      font-size: $twelve-pixel !important;
      // transform: translateY(10px);
      font-weight: 700 !important;
      color: #696969 !important;
      font-family: Lato !important;
      gap: 6px;
      padding-left: 14px;
      & img {
        width: 24px;
      }
    }
  }

  .header {
    color: #272727;
    font-family: Lato;
    font-size: $seventeen-pixel;
    // font-size: 4.673vw;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    // line-height: 5.607vw;
    margin-left: 22.75px;
    margin-bottom: 15px;
  }

  .imgSliderClass {
    height: 144px;
  }

  .roomDetailsInfoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 12.7px;
    padding-left: 34.26px;

    .roomName {
      color: #222222;
      font-family: Lato;
      font-size: $fourteen-pixel;
      font-weight: bold;
      letter-spacing: 0.28px;
      line-height: 15.57px;
    }

    .sleepAndAreaContainer {
      display: flex;
      margin-top: 12.9px;

      gap: 29.36px;

      .sleepsContainer {
        display: flex;
        gap: 13px;
        .sleepsIcon {
          width: 16px;
        }
        .sleepText {
          color: #252525;
          font-family: Lato;
          font-size: $nine-pixel + 0.5px;
          letter-spacing: 0.18px;
          line-height: 15.57px;
          text-align: right;
          text-transform: uppercase;
        }
      }
    }
  }

  .amenitiesHeader {
    color: #222222;
    font-family: Lato;
    font-size: $twelve-pixel + 0.8px;
    font-weight: bold;
    letter-spacing: 0.24px;
    line-height: 13.7px;
    margin-top: 25.22px;
  }
  .amenitiesContainer {
    .amenitiesClass {
      align-items: flex-start;

      @media screen and (min-width: 768px) {
        margin-top: 15px;
      }

      .amenityItemClassName {
        @media screen and (min-width: 768px) {
          font-size: $twelve-pixel;
          margin-left: 10px;
        }
      }
    }
  }
}
