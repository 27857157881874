.container {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
    height: 90vh; 
    background-color: #f9f9f9;
  
    .emailVerificationMessage {
      display: flex;
      justify-content: center;
      text-align: center;
  
      .message {
        font-size: 1.5rem;
        padding: 2vw;
        color: #333;
        font-weight: bolder;
        margin-bottom: 1rem;
      }
    }
  
    .okButton {
      padding: 0.5rem 1.5rem;
      font-size: 1rem;
     
      color: #fff;
      background-color: #007bff;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: background-color 0.3s;
  
      &:hover {
        background-color: #0056b3;
      }
  
      &:focus {
        outline: none;
      }
    }
  }
  