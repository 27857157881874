.searchBtnClass {
  width: 150px !important;
  background-color: #fbb040 !important;
  font-size: 12px;
  font-family: Lato;
  font-weight: bold;
  letter-spacing: 0.2px;
  box-shadow: none;
  color: #272727 !important;
  border: none !important;
  outline: none;
  border-radius: 50px;
  cursor: pointer;
  @media screen and (min-width: 500px) {
    margin-top: 60px;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (max-width: 500px) {
    //   margin-bottom: -100px;
    font-size: 2.694vw;
    width: 18.63vw;
    height: 6.928vw;
    // position: absolute;
    margin-top: 3.5vw !important;
  }
}
