@import "/src/variables/font_size";

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 4.12px;
  @media only screen and (min-width: 767px) {
    width: 300px;
  }
  .content {
    position: relative;
    opacity: 1;
    .heading {
      color: #222222;
      font-size: $fourteen-pixel;
      font-weight: bold;
      font-family: Lato;
      margin-bottom: 20px;
    }
    .autoCompleteField {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 10px;
      .searchIcon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
    .loader {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .errorMessage {
      font-size: $fourteen-pixel;
      font-weight: bold;
      font-family: Lato;
      color: #bf150d;
      letter-spacing: 0.2px;
    }

    .formControlContainer {
      padding-left: 8px !important;
      margin-top: 20px;
      .airportLabel {
        font-size: $eleven-pixel;
        font-family: Lato;
        font-weight: bold;
        .distance {
          color: #7cbeea;
          font-size: $eight-pixel;
          font-weight: 500 !important;
        }
      }
    }
  }
}

.icon {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: #ffffff;
  outline-offset: 2px;
  outline: 1px solid #696969;
}
.checkedIcon {
  outline: 1.5px solid #696969;
  outline-offset: 2px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0b3353;
}
