@import "/src/variables/font_size";

.dialogContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100dvh;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999 !important;
  // background-color: rgba(0, 0, 0, 0.5);
  .dialogContent {
    width: 100vw;
    max-width: 350px;
    background-color: transparent;
    border-radius: 8px;
    padding: 16px;
    .dialogHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      color: #ffffff;
      .closeIcon {
        width: 25px;
        cursor: pointer;
      }
      .header {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-family: Lato;
        font-size: $twenty-pixel;
        font-weight: normal;
        font-style: Bold;
        letter-spacing: 0.4px;
        text-align: left;
      }
    }
    .dialogBody {
      max-height: 80dvh;
      overflow: auto;
      background-color: #ffffff;
      border-radius: 8px;
      .content {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
        padding: 40px 30px 40px 30px;
        .title {
          opacity: 1;
          color: #272727;
          font-family: Lato;
          font-size: $fourteen-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.3px;
          text-align: left;
          line-height: $sixteen-pixel;
        }
        .ruleTitle {
          opacity: 1;
          color: #272727;
          font-family: Lato;
          font-size: $twelve-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.3px;
          text-align: left;
          line-height: $sixteen-pixel;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        .ruleDetails {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          margin-bottom: 5px;
          .closeIcon {
            width: auto;
            height: 15px;
          }
          .caryBag {
            font-size: $thirteen-pixel;
            color: #272727;
            text-align: left;
            line-height: $sixteen-pixel;
          }
        }
      }
    }
  }
}
