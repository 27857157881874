.headerContainer {
  z-index: 10;
  position: fixed;
  top: 0;
}

.contentContainer {
  background-color: #FEFEFE;
  @media screen and (max-width: 700px) {
    padding-top: 60px;
  }
}
.hideContentContainer {
  @media screen and (max-width: 700px) {
    padding-top: 0px;
  }
}

.dialogMainContainer {
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  .overlay {
    z-index: 1;
    // opacity: 0.7;
    background-color: rgba(0, 0, 0, 0.85);

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}

.loaderContainer {
  background: rgba(0, 0, 0, 0.9);
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
