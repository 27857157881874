.main {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .title {
    white-space: pre-wrap;
    color: #696969;
    font-size: 14px;
    font-family: Lato;
    font-weight: 500;
    max-width: 700px;
    @media screen and (max-width: 700px) {
      max-width: 300px;
    }
    text-align: center;
  }
  .btn {
    box-shadow: none;
    font-size: 12px;
    font-weight: 600;
    color: black;
    text-transform: capitalize;
    width: 110px;
    height: 35px;
    background-color: #fab306 !important;
    margin-top: 20px;
    align-self: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.9;
  }
  .btn:hover {
    background-color: #fab306;
    opacity: 1;
  }
}
