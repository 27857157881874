@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  .title {
    display: block;
    opacity: 1;
    color: #696969;
    font-family: Lato;
    font-size: $thirteen-pixel;
    font-weight: normal;
    letter-spacing: 0.2px;
    text-align: left;
    line-height: $twenty-pixel;
    max-width: 280px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    @media screen and (max-width: 375px) {
      max-width: 220px;
    }
    @media screen and (max-width: 767px) {
      max-width: 300px;
    }
    .airportName {
      font-weight: 400;
    }
    .airportCode {
      font-weight: $bold;
    }
  }
  .descriptionCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    .flightIcon {
      width: auto;
      height: 14px;
    }
    .dateInfo {
      opacity: 1;
      color: rgba(105, 105, 105, 1);
      font-family: Lato;
      font-size: $eleven-pixel;
      font-weight: normal;
      font-style: normal;
      letter-spacing: 0.21px;
      text-align: left;
      line-height: $twenty-pixel;
      .timeInfo {
        font-weight: $bold;
        font-style: Bold;
        text-transform: lowercase !important;
      }
    }
    .seatCard {
      display: flex;
      align-items: center;
      gap: 5px;
      .airlineSeat {
        width: auto;
        height: 16px;
      }
      .seatInfo {
        opacity: 1;
        color: #696969 !important;
        font-family: Lato;
        font-size: 10px;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.2px;
        text-align: left;
      }
    }
  }
}
