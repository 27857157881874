@import "/src/variables/font_size";

.btn {
  border-radius: 50px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 1);
  color: #ffffff;
  font-family: Lato;
  font-size: $fourteen-pixel;
  font-weight: 600;
  font-style: Bold;
  letter-spacing: 0.3px;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
/* Disabled state */
.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Small size */
.btn-sm {
  padding: 8px 18px;
  font-size: $twelve-pixel;
  @media screen and (max-width: 600px) {
    padding: 6px 12px;
  }
}

/* Medium size */
.btn-md {
  padding: 8px 40px;
  font-size: $fourteen-pixel;
}

/* Large size */
.btn-lg {
  padding: 10px 32px;
  font-size: $sixteen-pixel;
}

/* Default */
.btn-default {
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 1);
  border: none;
}

/* Cancel */
.btn-cancel {
  opacity: 1;
  color: #ffffff;
  background-color: #cc0900;
  border: none;
  outline: none;
}

/* Success */
.btn-success {
  background-color: rgba(52, 199, 89, 1);
  color: white;
  border: none;
}

/* Disabled */
.disabled {
  overflow: 0.5;
  background-color: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  cursor: not-allowed;
  border: none;
  outline: none;
}
