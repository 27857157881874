@import "/src/variables/font_size";
.mainContainer {
  // height: 160px;
  // overflow: hidden;
  position: relative;
  overflow: hidden;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  //   width: calc(100vw - 10px);
  width: 100%;
  // width: 200px;
  //   margin: 0 -25px;
  // @media screen and (min-width: 700px) {
  //   margin-bottom: 20px;
  // }
  .imageContainer {
    // height: 160px;
    width: 100%;
    display: flex;
    aspect-ratio: 1264 / 440;
    max-height: 150px;
    .img {
      // height: 160px;
      object-fit: cover;
      // flex-shrink: 0;
    }
  }
}

.placeHeadingContainer {
  min-height: 35px;
  background-color: rgba(0, 0, 0, 0.7);
  // background-color: #000000;
  // opacity: 70%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px 10px 32.7px;
  .placeHeadingMobContainer {
    display: flex;
    flex-direction: column;
    & h2 {
      font-size: $nine-pixel + 0.4px;
      //   font-weight: 500;
      // text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      opacity: 0.9;
    }
  }
  .placeHeadingMob {
    color: #ffffff;
    font-family: Lato;
    font-size: $twelve-pixel + 0.46px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    // @media screen and (max-width: 550px) {
    //   display: flex;
    // }
    z-index: 1;
  }
}

.selectedIcon {
  z-index: 2;
  // height: 40px;
  width: 15px;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.savedTrips {
  position: absolute;
  top: 0;
  left: 0;
  //   right: 0;
  padding: 6.2px 4.7px;
  border-radius: 0 0 3.73px 0px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;

  & p {
    font-size: $seven-pixel + 0.5px;
    color: #ffffff;
    // font-weight: 400;
    // font-weight: bold;
    letter-spacing: 0.16px;
  }

  &.draftTrip {
    p {
      color: #fbb040;
    }
  }
}
.tripDate {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 10px;
  border-radius: 0 0 0 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7);

  & p {
    font-size: $ten-pixel;
    color: #ffffff;
    // font-weight: 400;
    font-weight: bold;
  }
}

.nextIcon {
  position: absolute;
  bottom: 18.69px;
  right: 16.1px;
  width: 12.5px;
  cursor: pointer;
  z-index: 1;
}
