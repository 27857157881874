@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    .title {
      color: #696969;
      font-family: Lato;
      font-size: $eight-pixel;
      font-weight: bold;
      letter-spacing: 0.58px;
      line-height: $ten-pixel;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .durationCard {
      height: 33px;
      width: 60px;
      // padding-right: 5px;
      padding-top: 5px;
      .days {
        color: #222222;
        font-family: Lato;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.21px;
        line-height: 12px;

      }
      .desc {
        color: #222222;
        font-family: Lato;
        font-size: $eight-pixel;
        font-weight: bold;
        letter-spacing: 0.21px;
        line-height: $ten-pixel;
      }
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
