.loaderContainer {
  padding-bottom: 30px;
}

.noDetails {
  text-align: center;
  font-size: 12px;
  color: #696969;
  padding-bottom: 20px;
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      .hotelListCard {
        width: 100%;
        min-height: 80px;
        max-height: max-content;
        border-radius: 2.6px;
        border-top: 1px solid rgba(151, 151, 151, 0.5);
        border-bottom: 1px solid rgba(151, 151, 151, 0.5);
        border-left: 1px solid rgba(151, 151, 151, 0.5);
        border-right: none;
        display: flex;
        flex-direction: column;
        background-color: #ffff;
        overflow-x: auto;
        scrollbar-width: thin;
        margin-bottom: 8px;
        overflow-y: hidden;
        .hotelList {
          display: flex;
          gap: 10px;
          height: 80px;
          width: 100%;
          .card1 {
            //   padding: 20px 10px;
            padding-left: 1.2vh;
            display: flex;
            align-items: center;
            gap: 14px;
            // width: 180px;
            // width: 45%;
            width: 100%;
            height: 100%;
            @media only screen and (min-width: 767px) {
              width: 50%;
            }
            .flightImgCard {
              width: 40px;
              height: 40px;
              border: 0.51px solid #979797;
              border-radius: 8px;
              box-sizing: border-box;
              .flightImage {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 8px;
                box-sizing: border-box;
              }
            }

            .flightTime {
              color: #696969;
              font-size: 8px;
              line-height: 15px;
              letter-spacing: 0.2px;
              font-weight: 600;
              font-family: lato;
              .supText {
                color: #bf150d;
                margin-left: 2px;
              }
              .flightName {
                font-weight: 500 !important;
              }
            }
          }
          .card2 {
            padding: 1.2vh 0px;
            // width: 60px;
            min-width: 60px;
            // width: 100%;
            .stops {
              color: #696969;
              font-family: Lato;
              font-size: 8px;
              line-height: 12px;
              letter-spacing: 0.2px;
              font-weight: 600;
              margin-bottom: 5px;
              .stopInfo {
                font-weight: 500;
              }
            }
          }
          .card3 {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            // width: 60px;
            width: 60px;
            .flightBtn {
              color: #696969;
              font-size: 6.6px;
              line-height: 8px;
              font-weight: bold;
              font-family: Lato;
              letter-spacing: 0.2px;
              text-align: center;
              opacity: 0.8;
              .expIcon,
              .rotateExpIcon {
                width: auto;
                height: 10px;
                cursor: pointer;
              }
              .rotateExpIcon {
                animation: rotateIcon 0.1s linear forwards;
              }
            }
            .flightBtn:hover {
              background-color: transparent !important;
            }
          }
          .card4 {
            // width: 100px;
            // width: 100%;
            min-width: 100px;
            height: 100%;
            background-color: #1d75bb;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 8px;
            border-top-right-radius: 2.6px;
            border-bottom-right-radius: 2.6px;
            @media only screen and (min-width: 767px) {
              width: 100px;
            }
            .flight {
              position: relative;
              .price {
                font-size: 12px;
                line-height: 15px;
                font-weight: 600;
                font-family: lato;
                color: #ffffff;
                letter-spacing: 0.3px;
                text-align: center;
                .flightClass {
                  font-size: 8px !important;
                  font-weight: 500 !important;
                  text-transform: uppercase;
                }
              }
              .bookBtn {
                width: 62px;
                height: 20px;
                border-radius: 30px;
                background-color: #fcb400;
                box-shadow: none;
                border: none;
                outline: none;
                font-size: 8px;
                line-height: 12px;
                // font-size: 6px;
                // line-height: 10px;
                text-align: center;
                color: #222222;
                font-family: Lato;
                font-weight: 800 !important;
                letter-spacing: 0.5px;
                font-family: lato;
              }
            }
            .tourPack {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .radioControl {
                // width: 100%;
                .radioGroup {
                  width: 75px;
                  .customRadio {
                    border: 1px solid #696969 !important;
                    background-color: #ffffff;
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    &.Mui-checked {
                      color: #0b3353;
                    }
                    color: #0b3353;
                    .MuiSvgIcon-root {
                      font-size: 10px;
                    }
                  }
                  .radio {
                    color: #ffffff;
                    font-family: Lato;
                    font-size: 13.33px;
                    font-weight: bold;
                    letter-spacing: 0.3px;
                    line-height: 14.6px;
                    .flightClass {
                      display: block;
                      color: #ffffff;
                      font-family: Lato;
                      font-weight: 500;
                      font-size: 8px;
                      letter-spacing: 0.2px;
                      line-height: 15px;
                      margin-top: -2px;
                      opacity: 0.8;
                      text-transform: uppercase;
                    }
                  }
                }
              }
              .radioBtn {
                position: relative;
                width: 65px;
              }
            }
          }
        }
        .testCard {
          width: 100%;
          height: max-content;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 8px;
          margin-top: 15px;
        }
      }
    }
  }
  @keyframes rotateIcon {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
