.activeChip {
  color: #222222;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  font-family: Lato;
  background-color: #a4d1f0;
  border-radius: 40px;
  border-color: #a4d1f0 !important;
  padding-left: 5px;
  padding-right: 5px;
  letter-spacing: 0.2px;
  border: 1px solid #a4d1f0 !important;
  width: max-content;
}

.chip {
  color: #696969;
  font-size: 12px;
  line-height: 1.2;
  font-family: Lato;
  background-color: #ffff;
  border-radius: 40px;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
  letter-spacing: 0.2px;
  border-color: #a4d1f0;
  border: 1px solid #a4d1f0 !important;
  width: max-content;
}
