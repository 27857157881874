@import "/src/variables/font_size";
.inputContainer {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .newInputCard1 {
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 38px;
  }
  .newInputCard2 {
    display: flex;
    align-items: center;
    gap: 8px;
    .airportNotAvail {
      color: #bf150d;
      font-family: Lato;
      font-size: $nine-pixel;
      letter-spacing: 0.2px;
      line-height: $eleven-pixel;
      margin-bottom: 10px;
      margin-top: 15px;
      word-break: break-all;
      width: 90%;
      @media (min-width: 360px) and (max-width: 395px) {
        width: 280px;
      }
    }
    .card4 {
      padding-left: 38px;
    }
    // padding-left: 38px;
    @media (min-width: 360px) and (max-width: 395px) {
      gap: 8px;
    }
  }
  .stepper {
    position: relative;
    padding-left: 38px;
  }
  .searchBox {
    position: relative;
    width: 385px;
    padding-left: 30px;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 360px;
    }
  }
  .singleCitySearch {
    position: relative;
    width: 385px;
    padding-left: 38px;
    @media (min-width: 360px) and (max-width: 395px) {
      width: 350px;
    }
  }
}
