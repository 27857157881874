.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: -5px;
  .customStyle {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .flightAccord {
    margin-bottom: 15px;
    position: relative;
    .customFlightStyles {
      @extend .customStyle;
      .summaryFlightClass {
        margin: 0 !important;
        padding-left: 10px !important;
        padding-right: 26px !important;
        .headingFlightComp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 5px;
          .dayAndCity {
            color: #272727;
            font-family: Lato;
            font-size: 8.5px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 11px;
          }
          .accordDesc {
            color: #272727;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
          }
          .traveler {
            color: #272727;
            font-family: Lato;
            font-size: 9px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 12px;
          }
          .planeDepartureIcon,
          .planeArrivalIcon {
            height: 14px;
            width: 20px;
            cursor: pointer;
          }
          .planeArrivalIcon {
            transform: rotate(180deg);
          }
        }
      }
    }
    .accordFlightDetailsClass {
      padding: 0px !important;
    }
    .stepper {
      padding: 0px !important;
      .stepperDetails {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 25px;
        padding-right: 10px;
      }
      .stopsDetails {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
