.destinationCardContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 -13px;
    margin-top: 10px;
  }
  .showMoreLikeIconContainer {

    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .button {
      margin-left: -8px;
      color: #707070;
      // color: #47174C;
      font-family: Lato;
      font-weight: 600;
      font-size: 9px;
      gap: 8px;
      border-radius: 5px;
      .buttonIcon {
        width: 20px;
        height: 20px;
      }
    }
    .likeIconContainer {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      .likeDislikeIcon {
        width: 20px;
      }
    }
  }

  .postText {
    color: #222222;
    font-family: Lato;
    font-size: 14px;
    // font-weight: 600;
    // white-space: pre-wrap;
    line-height: 21px;
    padding-left: 40px;
    padding-right: 20px;
      padding-top: 10px;
    @media screen and (min-width: 700px) {
      padding-bottom: 15px;
    }
  }
  
  .postText li {
    margin-left: 16px;
  }
  