@import "/src/variables/font_size";

.mainContainer {
  border-top: 1px solid #e2e2e2;
}

.hotelSummaryContainer {
}

.flightSummaryContainer {
}

.summaryContainer {
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  padding: 20px 40px;
  gap: 15px;
  .hide {
    visibility: hidden;
  }
  & img {
    height: 19px;
    margin-top: -3px;
  }
  .detailsColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .headingContainer {
      & h1 {
        font-family: Lato;
        font-weight: $bold;
        font-size: $twelve-pixel;
        color: #272727;
      }
    }
    .rowContainer {
      display: flex;
      gap: 20px;
      & h1 {
        font-family: Lato;
        width: 90px;
        font-weight: 400;
        font-size: $twelve-pixel;
        color: #272727;
      }
      & h2 {
        font-family: Lato;
        width: 90px;
        font-weight: 400;
        font-size: $twelve-pixel;
        color: #272727;
      }
      & h3 {
        font-family: Lato;
        width: 82px;
        font-weight: $bold;
        font-size: $fifteen-pixel;
        color: #696969;
      }
      & h4 {
        font-family: Lato;
        font-weight: $bold;
        font-size: $sixteen-pixel;
        color: #1b75ba;
      }
      & p {
        font-family: Lato;
        font-weight: $bold;
        font-size: $twelve-pixel;
        color: #696969;
      }
      .blackFont {
        color: #272727;
      }
    }
    .discount {
      padding-bottom: 10px;
    }
  }
}
