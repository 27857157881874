@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .buttonStyles {
    color: #222222;
    font-family: Lato;
    font-size: $ten-pixel;
    text-align: center;
    letter-spacing: 0.21px;
  }
  .label {
    color: #696969;
    font-family: Lato;
    font-size: $eight-pixel;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: $ten-pixel;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-left: 2px;
  }
  .btnStyles {
    box-sizing: border-box;
    height: 32px;
    min-width: 130px;
    max-width: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #979797;
    border-radius: 1px;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .roundBox,
  .activeRoundBox {
    background-color: #ffffff;
    @extend .btnStyles;
    cursor: pointer;
    .tripType {
      font-size: $ten-pixel;
      font-weight: bold;
      line-height: $thirteen-pixel;
      @extend .buttonStyles;
    }
    .btnName {
      @extend .buttonStyles;
      font-size: $nine-pixel;
      line-height: $eleven-pixel;
      text-transform: uppercase;
      margin-top: 1px;
    }
  }
  .activeRoundBox {
    background-color: #d8efff;
    @extend .btnStyles;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
