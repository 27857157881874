.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  // background-color: #ffffff;
  .container {
    width: 100%;
    height: 100%;
    .head {
      padding-left: 22px;
      width: 100%;
      height: 212px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
      .backBtn {
        color: #ffffff;
        font-family: Lato;
        font-size: 10px;
        letter-spacing: 0.64px;
        line-height: 12px;
        margin-top: 70px;
      }
      .contentCard {
        display: flex;
        align-items: center;
        gap: 15px;
        z-index: 2;
        margin-top: 48px;
        .planeIcon {
          height: 24px;
          width: 26px;
        }
        .descCard {
          width: 85%;
          // text-wrap: wrap;
          .title,
          .desc {
            color: #ffffff;
            font-family: Lato;
            font-size: 10px;
            letter-spacing: 0.2px;
            line-height: 12px;
            width: 80%;
            word-wrap: break-word;
          }
          .title {
            font-weight: bold;
            margin-bottom: 2px;
            text-transform: uppercase;
          }
        }
      }
    }

    .titleCard {
      position: relative;
      display: block;
      padding-left: 20px;
      padding-top: 30px;
      padding-bottom: 26px;
      .title {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        font-weight: bold;
      }
      .desc {
        color: #272727;
        font-family: Lato;
        font-size: 10px;
        letter-spacing: 0;
        line-height: 12px;
      }
    }
    .accord {
      .customStyles {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        // margin-bottom: 10px;
        // margin-top: 0;

        .summaryClass {
          margin: 0 !important;
          padding-left: 10px !important;
          padding-right: 26px !important;
          .headingComp {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 20px;
            .accordTitleCard {
              position: relative;
              .dayAndCity {
                color: #272727;
                font-family: lato;
                font-size: 8.5px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 11px;
                .city {
                  margin-left: 20px;
                  letter-spacing: 0.2px;
                }
              }
              .accordDesc {
                color: #272727;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
              }
            }
            .accordHeading {
              color: #272727;
              font-family: Lato;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 15px;
            }
            .travelerIcon {
              height: 22px;
              width: 22px;
            }
          }
        }
        .accordContent {
          padding-right: 20px;
          .accordContentCard {
            display: flex;
            align-items: center;
            gap: 0px;
            .inputBox {
              width: 60px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .hideCheck {
                display: none;
              }
              .customCheckbox {
                position: absolute;
                opacity: 0;
                cursor: pointer;
              }
              .checkmark {
                height: 14px;
                width: 14px;
                background-color: transparent;
                border: 1px solid #696969;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 2px;
                cursor: pointer;
              }
              .customCheckbox:checked ~ .checkmark {
                background-color: transparent;
              }

              .customCheckbox:checked ~ .checkmark::after {
                display: block;
              }
              .checkmark::after {
                content: "";
                width: 3px;
                height: 6px;
                margin-top: -2px;
                border: solid #696969;
                border-width: 0 1.5px 2px 0;
                transform: rotate(45deg);
                display: none;
                cursor: pointer;
              }
            }
          }
        }
        .accordianDetailsClass {
          padding: 20px 2px;
        }
      }
    }
    .dayWiseItineraryCard,
    .dayWiseItineraryCardRemoveBorder {
      box-sizing: border-box;
      height: 60px;
      width: 100%;


      // box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
      padding-left: 12px;
      padding-top: 8px;
      padding-bottom: 12px;
      padding-right: 20px;
      margin-bottom: 10px;
      .morning {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        font-weight: bold;
      }
      .description {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: left;
        margin-top: 0px !important;
        .highlightText {
          color: #007aff;
        }
      }
    }
    .dayWiseItineraryCard {
      border: 1px solid #efefef;
      border-radius: 3px;
      background-color: #ffffff;
      box-shadow: 0 1.2px 1.2px 0 rgba(0, 0, 0, 0.3);
      // box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
    }
    .dayWiseItineraryCardRemoveBorder {
      box-shadow: none;
    }
    .activeBorder {
      border: 2px solid #0A84FF;
      box-shadow: none !important;
    }
    .btnCard {
      width: 100%;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 50px;
      padding-top: 20px;
      background-color: #ffffff;
      .continueBtn {
        height: 66px;
        border-radius: 3px;
        background-color: #fcb400;
        outline: none;
        border: none;
        color: #222222;
        font-family: Lato;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.4px;
        line-height: 22px;
        text-align: center;
        box-shadow: 0 1.2px 2.5px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
