.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
  background-color: #ffffff;

  .container {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    position: relative;
    // padding-right: 20px;
    // display: flex;
    // flex-direction: column;
    // flex-wrap: wrap;

    .inputCard {
      height: 100%;
      width: 100%;
      // padding-left: 15px;
      // padding-right: 15px;
      // padding-top: 50px;
      // position: absolute;
      // bottom: 50px;
      // width: 100%;
      overflow: auto;

      .contentDiv {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        height: calc(100vh - 165px);
        overflow: auto;
        position: absolute;
        top: 0px;
        // border: 2px solid green;
        .sectionContainer {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          gap: 3px;
          padding-top: 20px;
          padding-left: 3%;
          .systemChatIcon {
            display: flex;
            gap: 5px;
            align-items: center;
          }

          .chatIcon {
            width: 32px;
          }

          .textContainer{
            padding-left: 40px;
            margin-bottom: 20px;
          }
          .heading {
            color: #222222;
            font-family: Lato;
            font-size: 13px;
            font-weight: 600;
          }

          .para {
            margin-top: 20px;
            color: #222222;
            font-family: Lato;
            font-size: 10.5px;
            font-style: italic;
            letter-spacing: 0.2px;
            margin-right: 100px;
            @media only screen and (min-width: 1440px) and (max-width: 1440px) {
              width: 65%;
            }
          }
        }

        .chatBox {
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
        }

        .showMoreLikeIconContainer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-left: 4%;
          padding-right: 4%;
          padding-top: 20px;
          padding-bottom: 20px;
          .button {
            color: #696969;
            font-family: Lato;
            font-weight: 600;
            font-size: 9px;
            gap: 5px;
            border-radius: 5px;
            width: 120px;
            .buttonIcon {
              width: 20px;
              height: 20px;
            }
          }

          .button:disabled {
            background-color: #d5d5d5;
          }

          .likeIconContainer {
            display: flex;
            gap: 20px;
            justify-content: center;
            align-items: center;

            .likeDislikeIcon {
              width: 20px;
            }
          }
        }
        .loaderCard {
          padding-top: 20px;
          padding-bottom: 20px;
        }
      }

      .searchCard {
        width: 100%;
        position: absolute;
        // bottom: 8%;
        bottom: 60px;
        
        .inputBox {
          background-color: #fff;
          position: relative;
          display: block;
          padding-left: 6%;
          padding-right: 6%;
          z-index: 2 !important;
        }
      }
    }
  }
}

.main::after,
.main::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}

.constText {
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
  & h1 {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 13px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    // margin-left: -15px;
    // margin-right: -30px;
  }
  & p {
    padding-left: 40px;
    padding-right: 15px;
    font-size: 12px;
    color: #222222;
    font-style: normal;
    font-family: Lato;
    text-align: start;
    font-weight: 400;
    // margin-left: -15px;
    // margin-right: -30px;
  }
}
