@import "/src/variables/font_size";

// :global(.react-hot-toast) {
//   min-width: 500px !important;
//   max-width: 90vw !important;
// }

// :global(.react-hot-toast-container) {
//   width: 100% !important;
//   display: flex;
//   justify-content: center;
// }

// /* Override the inner toast body */
// :global(.react-hot-toast > div:first-child) {
//   width: 100% !important;
//   max-width: 600px !important;
//   padding: 12px !important;
// }
.messageStyles {
  opacity: 1;
  color: #ffffff;
  font-family: Lato;
  letter-spacing: 0.21px;
  text-align: left;
}
.successBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-height: 55px;
  min-height: 40px;
  min-width: 350px;
  gap: 15px;
  //   aspect-ratio: 5.36 / 1;
  .imgCard {
    width: 26px;
    height: 26px;
    .icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .successCard {
    position: relative;
    white-space: pre-line;
    margin-left: 0px;
    margin-right: -10px;
    width: calc(100vw * (9 / 16));
    .message {
      @extend .messageStyles;
      font-style: Bold;
      font-weight: 700;
      font-size: $twelve-pixel;
      text-transform: uppercase;
    }
    .detailMsg {
      @extend .messageStyles;
      font-size: $ten-pixel;
    }
  }
}
.errorBox {
  position: relative;
  opacity: 1;
  text-align: center;
  color: white;
  .closeIcon {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -10px;
    margin-top: -2px;
    width: 100%;
    .closeIcon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .errorCard {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-bottom: 20px;
    .imgCard {
      width: 26px;
      height: 26px;
      .icon {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .errorMessage {
      position: relative;
      white-space: pre-line;
      margin-left: 0px;
      margin-right: -10px;
      width: calc(100vw * (9 / 16));
      .message {
        @extend .messageStyles;
        font-style: Bold;
        font-weight: 700;
        font-size: $twelve-pixel;
        text-transform: uppercase;
      }
      .detailMsg {
        @extend .messageStyles;
        font-size: $ten-pixel;
      }
    }
  }
}
