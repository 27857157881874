@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .inputBox {
    width: 100%;
    position: relative;
    .inputField {
      opacity: 1;
      color: rgba(34, 34, 34, 1);
      font-size: 10px;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0.2px;
      text-align: left;
      line-height: 1.2;
      font-family: Lato;
      padding: 10px;
      border-radius: 1.1px;
      border: 1px solid #a3a9ad;
      outline: none;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      box-shadow: 0px 0px 20px -18px;
      width: min(80vw, 290px);
      height: 40px;
    }

    .inputField::placeholder {
      // font-family: Lato;
      color: #9e9e9e;
      opacity: 1;
      font-family: Lato;
      font-size: $twelve-pixel;
      font-weight: 400;
      font-style: Regular;
      letter-spacing: 0.21px;
      text-align: left;
    }

    .inputField::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
      font-size: 14px;
    }
    .card {
      position: relative;
      z-index: 10;
      background-color: #fff;
      border-radius: 1.1px;
      margin-top: 5px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      @media only screen and (min-width: 767px) {
        // width: 160px;
        width: 150px;
      }
      .ulList {
        list-style-type: none;
        padding: 10px;

        .lists {
          color: #000000;
          font-weight: 600;
          font-family: Lato;
          padding-bottom: 10px;
          font-size: 14px;
          letter-spacing: 0.5px;
          cursor: pointer;
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
