@import "/src/variables/font_size";

.customFlightStyles {
  @extend .customStyle;
  .summaryFlightClass {
    padding-left: 10px !important;
    padding-right: 26px !important;
    .headingFlightComp {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .dayAndCity {
        color: #272727;
        font-family: Lato;
        font-size: $eight-pixel + 0.5px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 11px;
      }
      .accordDesc {
        color: #272727;
        font-family: Lato;
        font-size: $twelve-pixel;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 15px;
      }
      .traveler {
        color: #272727;
        font-family: Lato;
        font-size: $nine-pixel;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 12px;
      }
      .planeDepartureIcon {
        width: 19px;
        cursor: pointer;
        margin-right: 20px;

        &.removePadding {
          margin-right: 0px;
        }
      }
    }
  }
}

.customStyle {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.accordFlightDetailsClass {
  padding: 0px !important;
}
// .main {
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding-left: 6.3px;
//   margin-top: 12.45px;
//   margin-bottom: 9px;
//   padding-top: 6.3px;
//   padding-bottom: 6.3px;
//   padding-right: 0px;
//   // border: 0.5px solid #979797;
//   box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
//   border-radius: 8px;
//   width: 100%;

//   .twoSectionsContainer {
//     display: flex;
//     align-items: flex-start;
//     justify-content: flex-start;
//     gap: 12px;
//   }
//   .card3 {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;

//     .flightBtn {
//       font-family: Lato;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       justify-content: center;
//       text-transform: none;
//       padding: 0;
//       // margin-right: -60%;
//       .viewDetails {
//         color: #696969;
//         font-family: Lato;
//         font-size: $six-pixel + 0.6px;
//         font-weight: bold;
//         letter-spacing: 0.2px;
//         line-height: 8px;
//         text-align: center;
//       }
//       .iconAndText {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         .expIcon {
//           width: 14px;
//           height: 10px;
//           cursor: pointer;
//           margin-bottom: 5px;
//         }
//       }
//     }
//     .flightBtn:hover {
//       background-color: transparent !important;
//     }
//   }
//   .hotelImg {
//     // width: 66px;
//     height: 63.23px;
//     border: 0.51px solid #979797;
//     border-radius: 4px;
//     box-sizing: border-box;
//     object-fit: cover;
//     aspect-ratio: 1;
//   }
//   .packageImg {
//     width: 40px;
//     height: 40px;
//     border: 0.51px solid #979797;
//     border-radius: 8px;
//     box-sizing: border-box;
//   }
//   .contentCard {
//     position: relative;
//     display: block;
//     // margin-top: -8px;
//     // min-width: 130px;
//     // max-width: 100%;
//     .starContainer {
//       display: flex;
//       align-items: center;
//       gap: 2.5px;
//       margin-left: -3px;

//       .starPropertyImg {
//         width: 9px;
//         @media screen and (min-width: 768px) {
//           width: 16.35px;
//         }
//       }
//       .star {
//         color: #272727;
//         font-family: Lato;
//         font-size: $six-pixel;
//         letter-spacing: 0.12px;
//         line-height: 5.4px;

//         @media screen and (min-width: 768px) {
//           font-size: $ten-pixel + 0.8px;
//           letter-spacing: 0.221px;
//         }
//       }
//     }
//     .hotelName {
//       color: #252525;
//       font-family: lato;
//       font-size: $nine-pixel + 0.4px;
//       letter-spacing: 0.64px;
//       line-height: 14px;
//       font-weight: bold;
//       letter-spacing: 0.2px;
//       margin-top: 5px;

//       // width: 130px;
//     }
//     .location {
//       color: #252525;
//       font-family: Lato;
//       font-size: $seven-pixel + 0.4px;
//       letter-spacing: 0.19px;
//       line-height: 10.44px;
//       text-transform: uppercase;
//       // margin-top: -4px;
//       margin-bottom: 8px;
//     }

//     .roomDetailsContainer {
//       display: flex;
//       flex-direction: column;

//       .room {
//         font-family: Lato;
//         font-weight: bold;
//         color: #252525;
//         font-size: $six-pixel + 0.23px;
//         line-height: 7.5px;
//         letter-spacing: 0.13px;
//       }
//     }
//     .amenities {
//       color: #696969;
//       font-family: Lato;
//       font-size: $six-pixel + 0.6px;
//       letter-spacing: 0.2px;
//       line-height: 8px;
//     }
//   }
// }

@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // margin: -10px -20px;

  .container {
    width: 100%;
    height: 100%;
    .contentCard {
      &.isHotelBeingModifiedOrCancelled {
        cursor: pointer;
      }
      position: relative;
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .hotelListCard {
        // margin-top: 10px;
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        border: 1px solid #979797;
        display: flex;
        background-color: #ffff;
        // gap: 10px;
        // overflow-x: auto;
        scrollbar-width: thin;
        box-sizing: border-box;
        margin-bottom: 8px;
        // overflow-y: hidden;
        // padding-bottom: 10px;

        &.selectedHotelListCard {
          border: 1px solid #fbb040;
        }

        &.selectingState {
          opacity: 0.4;
        }
        .card1 {
          width: 100%;
          height: 100%;
          padding: 6px 5px;
          // background-color: #f9e9be;
          //   padding: 20px 10px;
          // padding-left: 6px;
          display: flex;
          // align-items: center;
          gap: 14px;
          // width: 180px;
          .imgCard {
            width: 66px;
            height: 100%;
            .flightImage {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 0.51px solid #979797;
              border-radius: 8px;
              box-sizing: border-box;
            }
          }

          .flightTime {
            color: #696969;
            font-size: 8px;
            line-height: 15px;
            letter-spacing: 0.2px;
            font-weight: 600;
            font-family: lato;
            .supText {
              color: #bf150d;
              margin-left: 2px;
            }
            .flightName {
              font-weight: 500 !important;
            }
          }
        }
        .card2 {
          padding: 1.2vh 0px;
          width: 60px;
          .stops {
            color: #696969;
            font-family: Lato;
            font-size: 8px;
            line-height: 12px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 5px;
            .stopInfo {
              font-weight: 500;
            }
          }
        }
        .card3 {
          display: none;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 80px;
          .flightBtn {
            font-family: Lato;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-transform: none;
            .viewDetails {
              color: #696969;
              font-family: Lato;
              font-size: 6.6px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 8px;
              text-align: center;
            }
            .iconAndText {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              .viewDetailsBox {
                display: flex;
                flex-direction: column;
                align-items: center;
                .expIcon {
                  width: 14px;
                  height: 10px;
                  cursor: pointer;
                  margin-bottom: 5px;
                }
              }
            }
          }
          .flightBtn:hover {
            background-color: transparent !important;
          }
        }
        .lastCard {
          width: 150px !important;
          height: 100%;
          .discountCard {
            padding: 10px;
          }
          .card4 {
            width: 100% !important;
            // min-width: 100px !important;
            // max-width: 200px;
            height: 100%;
            background-color: #1d75bb;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            // gap: 8px;
            // border-top-right-radius: 2.6px;
            // border-bottom-right-radius: 2.6px;
            border-top-left-radius: 2.6px;
            &.selectedHotelRoom {
              background-color: #fbb040;
            }
            .price {
              color: #ffffff;
              font-family: Lato;
              font-size: 13.3px;
              letter-spacing: 0.4px;
              line-height: 16px;
              text-align: center;
              font-weight: bold;

              &.selectedPrice {
                color: #272727;
              }
            }
            .flightClass {
              color: #ffffff;
              font-family: lato;
              font-size: $nine-pixel !important;
              letter-spacing: 0.4px !important;
              line-height: $fourteen-pixel !important;
              text-align: center;
              margin-top: -2px !important;
              text-transform: capitalize;
              font-weight: 500;
              opacity: 0.8;
              text-align: center;

              &.selectedFlightClass {
                color: #272727;
              }
            }
            .cancellation {
              color: #ffffff;
              font-family: lato;
              font-size: $seven-pixel;
              font-weight: bold;
              letter-spacing: 0.4px;
              line-height: $ten-pixel;
              text-transform: capitalize;
              margin-bottom: 5px;
              margin-top: 8px;
              opacity: 0.8;
              &.selectedCancellation {
                color: #222222;
              }
            }
            .bookBtn,
            .selectedBtn {
              width: 62px;
              height: 20px;
              border-radius: 30px;
              box-shadow: none;
              border: none;
              outline: none;
              // font-size: 6px;
              // line-height: 10px;
              font-size: 8px;
              line-height: 12px;
              text-align: center;
              font-family: Lato;
              font-weight: 800 !important;
              letter-spacing: 0.5px;
              font-family: lato;
            }
            .bookBtn {
              color: #222222;
              background-color: #fcb400;
            }
            .selectedBtn {
              color: #ffffff;
              background-color: #272727;
            }
          }
        }
      }

      .checkBoxContainer {
        position: absolute;
        top: 10px;
        left: 7px;
        background-color: #ffffff;
        border-radius: 3px;
        .inputBox {
          position: relative;
          .customCheckbox {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }
          .disableCheck {
            height: 14px;
            width: 14px;
            background-color: transparent;
            border: 1.5px solid rgba(105, 105, 105, 0.1);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            cursor: none;
            pointer-events: none;
          }
          .checkmark {
            height: 18px;
            width: 18px;
            background-color: transparent;
            border: 1.5px solid #696969;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 3px;
            cursor: pointer;
          }
          .customCheckbox:checked ~ .checkmark {
            background-color: transparent;
          }

          .customCheckbox:checked ~ .checkmark::after {
            display: block;
          }
          .checkmark::after {
            content: "";
            width: 3.5px;
            height: 7px;
            margin-top: -2px;
            border: solid #696969;
            border-width: 0 1.5px 2px 0;
            transform: rotate(45deg);
            display: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
