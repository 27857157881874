// @import '/src/variables/font_size';

// .main {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     margin: 1px;
//     .inputCard {
//       color: #222222;
//       font-family: Lato;
//       color: #696969;
//       font-size: $eleven-pixel;
//       padding-bottom: 3px;
//       font-weight: 400;
//       letter-spacing: 0.2px;
//       display: flex;
//       align-items: center;
//       gap: 1px;
//       text-transform: uppercase;
//       min-height: 8px;
//       @media only screen and (min-width: 767px) {
//         margin-bottom: 2px !important;
//       }
//       .mandate {
//         color: #bf150d;
//         width: 6px;
//         height: 6px;
//       }
//     }
//     .inputContainer {
//       position: relative;
//       width: 150px;
//     }
//     .inputField,
//     .inputErrorField {
//       position: relative;
//       height: 35px;
//       // width: 136px;
//       // border: none;
//       border: 0.5px solid #cfcfcf;
//       // box-shadow: 0px -0.1px 0 0.5px #979797;
//       border-radius: 2px;
//       background-color: #ffffff;
//       padding-left: 5px;
//       text-align: left;
//       color: #272727;
//       font-family: Lato;
//       font-size: 10px;
//       letter-spacing: 0.21px;
//       line-height: 12px;
//       outline: none;
//       padding-left: 8px;
//       z-index: 2;
//       &::placeholder {
//         color: #a3a9ad;
//         padding-left: 2px;
//       }
//       &:focus,
//       &:active {
//         outline: none;
//         border-color: #979797;
//         background-color: #ffffff;
//       }
//     }
//     .phoneNumberOverlay{
//       z-index: 1;
//       // position: absolute;
//       left: 0;
//     }
//     .hideTextIfOverlay {
//       background-color: transparent;
//       border: none;
//       color: transparent !important;
//       &:focus,
//       &:active {
//         background-color: transparent;
//         caret-color: black;
//       }
//     }
//     .inputErrorField {
//       border: 1px solid #ff0000;
//     }
//     .errorMessage {
//       font-size: 10px;
//       line-height: 12px;
//       font-weight: 500;
//       font-family: lato;
//       color: red;
//     }
//     .hideError {
//       display: none;
//     }
//   }
//   .main::before,
//   .main::after {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
  

/** OLD UI */
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 1px;
  .inputCard {
    color: #222222;
    font-family: Lato;
    font-size: 6.6px;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 8px;
    display: flex;
    align-items: center;
    gap: 1px;
    margin-bottom: 3px;
    text-transform: uppercase;
    min-height: 8px;
    @media only screen and (min-width: 767px) {
      margin-bottom: 2px !important;
    }
    .mandate {
      color: #bf150d;
      width: 6px;
      height: 6px;
    }
  }
  .inputContainer {
    position: relative;
  }
  .inputField,
  .inputErrorField {
    position: relative;
    height: 26.6px;
    // width: 136px;
    // border: none;
    border: 0.5px solid #979797;
    // box-shadow: 0px -0.1px 0 0.5px #979797;
    border-radius: 2px;
    background-color: #ffffff;
    padding-left: 5px;
    text-align: left;
    color: #272727;
    font-family: Lato;
    font-size: 10px;
    letter-spacing: 0.21px;
    line-height: 12px;
    outline: none;
    padding-left: 8px;
    z-index: 2;
    &::placeholder {
      color: #a3a9ad;
      padding-left: 2px;
    }
    &:focus,
    &:active {
      outline: none;
      border-color: #979797;
      background-color: #ffffff;
    }
  }
  .phoneNumberOverlay{
    z-index: 1;
    position: absolute;
    left: 0;
  }
  .hideTextIfOverlay {
    background-color: transparent;
    border: none;
    color: transparent !important;
    &:focus,
    &:active {
      background-color: transparent;
      caret-color: black;
    }
  }
  .inputErrorField {
    border: 1px solid #ff0000;
  }
  .errorMessage {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    font-family: lato;
    color: red;
  }
  .hideError {
    display: none;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
