.aboutUsContainer {
  width: 100dvw;
  height: 100dvh;

  @media screen and (min-width: 769px) {
    padding-top: 50px;
  }

  @media screen and (max-width: 768px) {
    height: calc(100dvh - 60px);
  }

  iframe {
    width: 100%;
    height: 100%;
    // padding-top: 10px;
    @media screen and (min-width: 769px) {
      padding-top: 10px;
    }
  }
}
