.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overscroll-behavior: none;
}

.chatLoaderContainer {
  margin-top: 20px;
  overscroll-behavior: none;
}

.input {
  padding: 0 20px;
  overscroll-behavior: none;
}
.desktopScrollContainer {
  // border: 2px solid green;
  width: 100%;
  overscroll-behavior: none;
  @media screen and (min-width: 700px) {
    max-height: calc(100dvh - 160px);
    background-color: #ffffff;
    padding-bottom: 0px;
    overflow: auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
  }
}
.sectionContainer {
  overscroll-behavior: none;
  @media screen and (min-width: 700px) {
    background-color: #ffffff;
    padding-bottom: 0px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.superContainer {
  // border: 2px solid red;
  overscroll-behavior: none;
  // height: calc(100dvh - 60px);
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // overflow: auto;
  position: relative;
  padding: 30px 15px;
  padding-bottom: 0px;
  min-height: calc(100dvh - 60px);
  max-height: calc(100dvh - 60px);
  gap: 10px;
  // height: 100%;
  padding-top: 0px;
  justify-content: flex-end;
  @media screen and (min-width: 700px) {
    padding-bottom: 0px;
    background-color: #ffffff;
    // height: calc(100dvh - 60px);
    // overflow: hidden;
  }
}

.backIconBtn {
  overscroll-behavior: none;
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
  width: max-content !important;
  z-index: 2 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  gap: 5px !important;
  border-radius: 5px !important;
  color: #696969 !important;
  & img {
    width: 20px;
    margin-top: 0.15em;
  }
}

.chatIcon {
  width: 32px;
  border-radius: 50%;
}
.heading {
  overscroll-behavior: none;
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
}

.headingInfo {
  overscroll-behavior: none;
  color: #222222;
  font-family: Lato;
  font-size: 13px;
  font-weight: 600;
  margin-left: 52px;
  margin-bottom: 10px;
}

.logoContainer {
  overscroll-behavior: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  // position: absolute;
  // top: -15%;
  // bottom: 40%;
  left: 0;
  right: 0;
  margin-bottom: 10vh;
  .logo {
    width: 135px;
    border-radius: 50%;
  }
  & h1 {
    color: #47174c;
    font-weight: 800;
    font-size: 13px;
  }
  & p {
    font-size: 13px;

    width: 150px;
    text-align: center;
    & span {
      font-weight: 600;
    }
  }
}

.sectionContainer {
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  // gap: 3px;
  align-items: flex-start;
  .systemChatIcon {
    display: flex;
    gap: 6px;
    align-items: center;
    border-radius: 50%;
  }
}

.inputContainer {
  // border: 2px solid blue;
  padding-bottom: 10px;
  overscroll-behavior: none;
  position: relative;
  width: 100%;
  // bottom: 0;
  // left: 0px;
  // right: 0px;
  // padding: 0 10px;
  // padding-top: 15px;
  background-color: transparent;
  @media screen and (min-width: 700px) {
    background-color: #ffffff;
  }
  z-index: 9;
}

.input {
  color: #696969;
  // width: 100%;
  font-size: 13px;
  border: none;
  padding: 14px;
  padding-left: 20px;
  padding-right: 35px;
  outline-color: #2596be;
  outline-width: 1px;
  border-radius: 25px;
  // resize: none;
  outline: 1px solid #a4d1f0;
  -webkit-appearance: none;
}
.input::placeholder {
  color: #a3a9ad !important;
}

.constText {
  // margin-top: 5px;
  // width: 92.48%;
  color: #222222;
  font-family: Lato;
  font-size: 12px;
  font-style: italic;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.constText h1 {
  // padding-left: 50px;
  font-size: 12px;
  color: #222222;
  font-style: normal;
  font-family: Lato;
  margin-left: 20px;
  // margin-right: -30px;
}
.constText p {
  // padding-left: 50px;
  // padding-right: 50px;
  max-width: 248px;
  font-size: 11px;
  color: #222222;
  font-style: normal;
  font-family: Lato;
  text-align: center;
  // margin-left: -15px;
  // margin-right: -30px;
}
