.main {
  margin: 0;
  padding: 0;
  .container {
    background-color: #FEFEFE;
    width: 100%;
    height: calc(100dvh - 60px);
    overflow: auto;
    scrollbar-width: none;
    position: relative;
    scrollbar-width: none;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;

    .itineraryCard {
      padding-left: 30px;
      padding-right: 20px;
      padding-bottom: 30px;
      .dayCard {
        display: block;
        position: relative;
        margin-top: 20px;
        margin-bottom: 0px;
        .widget {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-bottom: -18px;
          .widgetContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .city {
              color: #272727;
              font-family: Lato;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.25px;
              line-height: 14px;
              text-align: right;
              margin-top: 5px;
            }
          }
        }
        .todayCard {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .today {
            color: #696969;
            font-family: Lato;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.4px;
            line-height: 22px;
            text-transform: capitalize;
            margin-bottom: 16px;
          }
        }

        .day {
          text-align: left;
          color: #272727;
          font-family: Lato;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 22px;
          text-transform: capitalize;
        }
        .city {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 14px;
          text-align: right;
          margin-top: 5px;
        }
      }
    }
    .itineraryCard {
      padding-left: 30px;
      padding-right: 20px;
      padding-bottom: 30px;
      .dayWiseItineraryCard {
        width: 100%;
        border: 1px solid #efefef;
        border-radius: 3px;
        background-color: #ffffff;
        // box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 1.1px 1.1px 0 rgba(0, 0, 0, 0.2);
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 20px;
        margin-bottom: 10px;
        .morning {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: bold;
          text-transform: capitalize;
        }
        .description {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;
          margin-top: 0px !important;
          .highlightText {
            color: #007aff;
          }
        }
      }
    }
    .divider {
      width: 100%;
      border-bottom: 0.5px solid rgba(105, 105, 105, 0.5);
    }
    .nearByPlaces {
      padding-left: 20px;
      padding-top: 26px;
      padding-bottom: 10px;
      .restaurants {
        color: #272727;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 14px;
        text-transform: capitalize;
      }
      .specCard {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-right: 14px;
        .specs {
          padding-top: 11px;
          padding-left: 20px;
          .specTitle {
            color: #007aff;
            font-family: Lato;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
            font-weight: bold;
          }

          .specDesc {
            color: #272727;
            font-family: Lato;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 14px;
          }
        }
        .distance {
          padding-top: 12px;
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 12px;
          margin-left: 10px;
          flex-shrink: 0;
          width: 90px;
          text-align: end;
        }
      }
    }
    .exploreCard {
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      .exploreBtn {
        width: 160px;
        height: 30px;
        border-radius: 15px;
        background-color: #205c8c;
        color: #ffffff;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 14px;
        text-align: center;
      }
      .exploreBtn:active {
        transform: scale(0.98);
      }
    }
    .upcomingTripContainer {
      width: 100%;
      .viewTrip {
        margin-left: 32px;
        color: #47174c;
        font-family: Lato;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0.3px;
        line-height: 20px;
        margin-bottom: 9px;
      }
      .upcomingTripSubContainer {
        // margin: 5px;
        padding-left: 4px;
        padding-right: 4px;
        position: relative;
        .bookedDate {
          text-align: end;
          margin-right: 2px;
          margin-top: 4px;
          font-size: 8px;
          color: #696969;
        }
      }
    }
    .chatCard {
      align-self: flex-end;
      padding-right: 5px;
      padding-top: 10px;
      width: max-content;
      position: sticky;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // z-index: 9999 !important;
      bottom: -50px;
      @media screen and (max-width: 700px) {
        bottom: 5px;
        position: fixed;
      }
      .chatBtn {
        cursor: pointer;
        .chatImg {
          width: 54px;
          height: 54px;
          border-radius: 50%;
        }
      }
      // .chatBtn:active {
      //   transform: scale(0.98);
      // }
    }
  }
  .container::-webkit-scrollbar {
    display: none !important;
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
}

.notificationContainer {
  margin-left: -25px;
  margin-right: -15px;
  margin-bottom: 20px;
}

.day {
  text-transform: uppercase;
  text-align: left;
  color: #272727;
  font-family: "Lato" !important;
  font-size: 8.3px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 12px !important;
}
.explore {
  color: #272727;
  font-family: "Lato" !important;
  font-size: 12px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 18px !important;
}
