.main {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  .dayCard {
    position: relative;
    margin-top: -1vh;
    .days {
      font-size: 16px;
      line-height: 1.2;
      font-weight: 600;
      font-weight: Lato;
      color: #205c8c;
    }
  }
}
