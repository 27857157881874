@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    height: 20px;
    background: transparent;
    .note {
      color: #cc0900;
      font-family: Lato;
      font-size: $thirteen-pixel;
      letter-spacing: 0.25px;
      line-height: $fifteen-pixel;
      text-align: center;
    }
  }
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
