@import "/src/variables/font_size";
.main {
  // display: flex;
  gap: 5px;
  margin: 0;
  padding: 0;
  .noReviewCard {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 8px;
    .disableCard {
      width: 29px;
      height: 25px;
      border-radius: 1.1px;
      background-color: #c7c7cc;
    }
    .noReview {
      color: #696969;
      font-family: Lato;
      font-size: $ten-pixel;
      font-weight: bold;
      letter-spacing: 0.21px;
      line-height: $twelve-pixel;
    }
  }
  .ratingCard {
    // background-color: #5856d6;
    .rating {
      color: rgb(105, 105, 105);
      font-family: Lato;
      font-size: 12.5px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 10px;
    }
  }

  .content {
    display: block;
    position: relative;
    margin-top: 2px;
    padding-top: 2px;
    .reviewTitle {
      color: #696969;
      font-family: Lato;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: 10px;
    }
    .review {
      color: rgb(27, 117, 186);
      margin-top: 4px;
      font-family: Lato;
      font-size: 8.333px;
      letter-spacing: 0.2px;
      line-height: 8px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
