// STEPPER
.arrivalDateContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 25px;
  margin-top: -5px;
}
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow: visible;
  margin-bottom: 0px;
  .stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    // width: 325px;
    // position: relative;

    .step {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      position: relative;
      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1.5px solid #696969;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffff;
        color: #696969;
        z-index: 2;
      }
      .lastCircle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1.5px solid #696969;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #696969;
        box-shadow: inset 0 0 0 1px #fff;
        color: #ffff;
        z-index: 2;
      }
      .stepContent {
        display: flex;
        flex-direction: column;
        position: relative;
        .title {
          display: flex;
          flex-wrap: wrap;
          color: #696969;
          font-family: "Lato-Bold", sans-serif;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          margin-top: -4px;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          white-space: normal;
          padding-bottom: 3px;
          .airportCode {
            font-weight: bold;
          }
          .airportName {
            font-weight: 500 !important;
          }
        }
        .arrivalDateContainer {
          display: flex;
          align-items: center;
          gap: 10px;
          .planeIcon {
            width: 14px;
            height: 14px;
            cursor: pointer;
          }
          .description,
          .delayDescription {
            font-family: Lato;
            font-size: 10px;
            font-weight: 500;
            letter-spacing: 0.25px;
            line-height: 12px;
            .timeInfo {
              font-weight: bold;
            }
          }
          .description {
            color: #696969;
          }
          .delayDescription {
            color: #696969;
          }
          .seatCard {
            display: flex;
            align-items: center;
            gap: 5px;
            .airlineSeat {
              width: auto;
              height: 16px;
            }
            .seatInfo {
              opacity: 1;
              color: #696969 !important;
              font-family: Lato;
              font-size: 10px;
              font-weight: 400;
              font-style: Regular;
              letter-spacing: 0.2px;
              text-align: left;
            }
          }
        }
        .arrivalOnDifferent {
          padding-left: 30px;
          padding-top: 5px;
        }
        .viewDetailsButton {
          position: absolute;
          width: max-content;
          right: 0;
          top: 20px;
          color: #696969;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0.2px;
          line-height: 12px;
          @media screen and (max-width: 700px) {
            left: 55vw;
          }
          .viewIcon,
          .hideIcon {
            height: 8px;
            width: 14px;
          }
          .hideIcon {
            transform: rotate(180deg);
          }

          &:hover {
            background-color: transparent !important;
          }
        }

        .card {
          width: 100%;
          margin-top: 20px;
          
          .subCard {
            display: flex;
            gap: 20px;
            flex-direction: column;
            position: relative;
            .airlineImgCard {
              position: absolute;
              top: 20%;
              left: -70px;
              z-index: 4 !important;
              .airlineImg {
                width: 30px;
                height: 30px;
              }
            }
          }
          .seatCard {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            .boardingBtn {
              color: #696969;
              font-family: Lato;
              font-size: 7.5px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 20px;
              .passIcon {
                width: 20px;
                height: 28px;
              }
            }
          }
        }
      }

      .line {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        background-color: #696969;
        z-index: 1;
      }
      .dashLine {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        border-left: 2px solid #ffce07;
        z-index: 1;
      }

      .square {
        position: absolute;
        top: 70%;
        left: -4.5px;
        transform: translateY(-5px);
        width: 8px;
        height: 8px;
        background-color: #fff;
        border: 1.5px solid #ffce07;
        z-index: 1;
      }
    }
  }
}
