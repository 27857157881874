@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .container {
    position: relative;
    .titleStyles {
      color: #696969;
      font-family: Lato;
      font-size: $nine-pixel;
      font-weight: bold;
      letter-spacing: 0.2px;
      line-height: $eleven-pixel;
      margin-bottom: 5px;
    }
    .descStyles {
      color: #222222;
      font-family: Lato;
      font-size: $eleven-pixel;
      font-weight: bold;
      letter-spacing: 0.21px;
      line-height: $thirteen-pixel;
      margin-bottom: 5px;
    }
    .flightHotelCard {
      display: flex;
      gap: 50px;
      .flightCard {
        .departure {
          @extend .titleStyles;
          text-transform: uppercase;
        }
        .departCard {
          display: flex;
          align-items: flex-starc;
          justify-content: center;
          gap: 8px;
          margin-top: 10px;
          .departDate {
            @extend .descStyles;
            text-align: center;
        }
        .flightImg {
          height: 13px;
          width: 19px;
        }
        }

      }
      .hotelCard {
        .accomodation {
          @extend .titleStyles;
          text-transform: uppercase
        }
        .nights {
            @extend .descStyles;
            margin-top: 10px;
        }
      }
    }
    .dateInfo {
        color: #BF150D;;
        font-family: Lato;
        font-size: $nine-pixel;
        letter-spacing: 0.2px;
        line-height: $eleven-pixel;
        margin-bottom: 10px;
        margin-top: 5px;
        word-break: break-all;
        width: 90%;
        @media (min-width: 360px) and (max-width: 395px) {
          width: 280px; 
        }

    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
