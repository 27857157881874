.main {
  margin: 0;
  padding: 0;
  .title {
    font-size: 14px;
    font-family: lato;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #222222;
    @media only screen and (min-width: 767px) {
      margin-left: 5px;
    }
  }

  .container {
    // overflow-x: scroll;
    // white-space: nowrap;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: flex;
    gap: 10px;
    @media only screen and (min-width: 767px) {
      padding: 5px 5px;
    }

    .contentCard {
      .card {
        margin-top: 10px;
        width: 220px;
        height: 60px;
        border-radius: 8px;
        border: 1.2px solid #a3a9ad;
        padding: 10px;
        // touch-action: none;
        // user-select: none;
        // -webkit-user-drag: none;
        // -webkit-touch-callout: none;
        .cartTitle {
          font-weight: 600;
          letter-spacing: 0px;
          color: #222222;
          font-size: 11px;
          font-family: lato;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          // touch-action: none;
          // user-select: none;
          // -webkit-user-drag: none;
          // -webkit-touch-callout: none;
        }
        .descCard {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding-top: 2px;
          gap: 20px;
          width: 100%;
          // touch-action: none;
          // user-select: none;
          // -webkit-user-drag: none;
          // -webkit-touch-callout: none;
          .descBox {
            height: 25px;
            // touch-action: none;
            // user-select: none;
            // -webkit-user-drag: none;
            // -webkit-touch-callout: none;
            .desc {
              // touch-action: none;
              // user-select: none;
              // -webkit-user-drag: none;
              // -webkit-touch-callout: none;
              font-family: Lato;
              font-weight: 500;
              font-size: 10px;
              letter-spacing: 0.2px;
              opacity: 0.8;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          .copyIcon {
            height: 16px;
            width: auto;
            cursor: pointer;
            // touch-action: none;
            // user-select: none;
            // -webkit-user-drag: none;
            // -webkit-touch-callout: none;
          }
        }
      }
    }
  }
  .container::-webkit-scrollbar {
    display: none !important;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
