.upcomingtripSubContainer {
  position: relative;
  .bookedDate {
    text-align: end;
    margin-right: 2px;
    margin-top: 4px;
    font-size: 8px;
    color: #696969;
  }
}

.mainContainer {
  // height: 160px;
  height: 146.6px;
  // overflow: hidden;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  //   width: calc(100vw - 10px);
  width: 100%;
  // width: 200px;
  //   margin: 0 -25px;
  @media screen and (min-width: 700px) {
    margin-bottom: 20px;
  }
  .imageContainer {
    height: 160px;
    width: 100%;
    display: flex;
    .img {
      height: 160px;
      // aspect-ratio: 1 / 1.08;
      object-fit: cover;
      // flex-shrink: 0;
    }
  }
}

.placeHeadingContainer {
  min-height: 35px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;
  .placeHeadingMobContainer {
    display: flex;
    flex-direction: column;
    & h2 {
      font-size: 10px;
      font-weight: 500;
      // text-transform: uppercase;
      color: #ffffff;
    }
  }
  .placeHeadingMob {
    color: #ffffff;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    // @media screen and (max-width: 550px) {
    //   display: flex;
    // }
    z-index: 1;
  }
}

.selectedIcon {
  z-index: 2;
  // height: 40px;
  width: 15px;
  position: absolute;
  bottom: 10px;
  right: 5px;
}

.tripDate {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 10px;
  border-radius: 0 0 0 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  & p {
    font-size: 10px;
    color: #ffffff;
    font-weight: 400;
  }
}

.nextIcon {
  position: absolute;
  bottom: 20px;
  right: 30px;
  width: 14px;
}
