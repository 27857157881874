.capitalize {
  text-transform: capitalize;
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .travelerStyles {
    color: #696969;
    font-family: Lato;
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 20px;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .container {
    position: relative;
    padding-left: 20px;
    padding-top: 22px;
    padding-right: 48px;
    padding-bottom: 15px;
    .title {
      @extend .travelerStyles;
      font-weight: bold;
      padding-bottom: 10px;
      color: #1b75ba !important;
      .travelIcon {
        width: 12px;
        height: 12px;
        margin-right: 5px;
      }
    }
    .travelerCard {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      padding-left: 40px;
      .infoCard {
        // border: 1px solid green;
        max-width: 150px;
        .name {
          @extend .travelerStyles;
          font-weight: bold;
          text-transform: uppercase;
        }
        .email {
          @extend .travelerStyles;
          font-weight: bold;
        }
        .gender {
          @extend .travelerStyles;
          font-weight: Light !important;
          text-transform: capitalize !important;
        }
        .meal {
          @extend .travelerStyles;
          font-weight: bold;
        }
        .ff {
          @extend .travelerStyles;
          max-width: 140px;
          .ffTitle {
            white-space: normal;
            word-wrap: break-word;
            overflow-wrap: break-word;
            font-weight: bold;
          }
        }
        .specialCard {
          .special {
            @extend .travelerStyles;
            font-weight: bold;
          }
          .list {
            margin-left: 30px;
            .listTitle {
              @extend .travelerStyles;
            }
          }
        }
      }
      .secondCard {
        width: 190px;
        margin-right: -40px;
        max-width: 190px;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
