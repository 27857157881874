@import "/src/variables/font_size";

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px 18px 8px 18px;
  // background-color: aliceblue;
  background-color: #fefefe;
}
.btn {
  -webkit-tap-highlight-color: transparent;
  border: none;
  color: #9e9e9e;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
  font-size: $twelve-pixel;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    height: 9px;
    margin-left: 10px;
  }
}

.currentCrumb {
  font-weight: $bold;
  color: #1b75ba;
}
