.icon {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #696969;
    background-color: #ffffff;
    outline-offset: 2px;
    outline: none;
  }
  
  .checkedIcon {
    // outline: 1px solid #696969;
    // outline-offset: 0px;
    // border: 2px solid #ffffff;
    // height: 20px;
    // width: 20px;
    // border-radius: 50%;
    // // background-color: #ffff;
  
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2px solid #696969;
    background-color: #ffffff;
    outline-offset: 2px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .selected {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      border: 1.5px solid #ffffff;
      background-color: #0B3353;
      outline-offset: 0px;
      outline: none;
    }
  }
  