@import "/src/variables/font_size";

.paymentDetailsContainer {
  padding: 20px;
  padding-top: 15px;
  .paymentHeaderContainer {
    padding-bottom: 38px;
    & p {
      color: #272727;
      font-family: Lato, sans-serif;
      font-size: $ten-pixel;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      gap: 12px;
      margin-bottom: 2px;
    }
    & h1 {
      font-family: Lato, sans-serif;
      font-size: $fourteen-pixel;
      font-weight: bold;
      color: #272727;
    }
  }
}

.paymentEle {
  border-bottom: 1px solid #dddcdc;
}

.grid {
  padding-bottom: 13px;
  padding-left: 15px;
  display: flex;
  gap: 20px;
  & p {
    font-family: Lato;
    color: #272727;
    font-size: $twelve-pixel;
    font-weight: 500;
  }
  & h2 {
    font-family: Lato;
    color: #696969;
    font-size: $twelve-pixel;
    font-weight: bold;
  }
  & h1 {
    font-family: Lato;
    color: #272727;
    font-size: $twelve-pixel;
    font-weight: bold;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding-right: 25px;
  .planeDepartureIcon {
    height: 18px;
    // width: 20px;
    cursor: pointer;
    padding-left: 10px;
  }
  .headerBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
  }

  .buttonStyles {
    color: #696969;
    font-family: Lato;
    font-size: $ten-pixel;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 12px;
    text-align: right;
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .headerBtn {
    @extend .buttonStyles;
    .flightIcon {
      height: 20px;
      width: 25px;
    }

    .editIcon {
      height: 20px;
      width: 20px;
    }
  }
  .modfiyHeader {
    text-align: center;
    color: #cc0900;
    font-family: Lato;
    font-weight: bold;
    font-size: 10.06px;
    // margin-bottom: -5px;
    margin-left: 5px;
  }
  .headerBtn:hover {
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
    border: none;
  }
  .viewBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    .viewTitle {
      color: #696969;
      font-family: Lato;
      font-size: $nine-pixel;
      // font-weight: bold;
      letter-spacing: 0.2px;
      line-height: $twelve-pixel;
      text-align: center;
      cursor: pointer;
    }
    .expIcon {
      height: 7px;
      width: 14px;
      cursor: pointer;
    }
  }

  .constContainer {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .dropDownRowContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 35px;
      padding: 5px 5px;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      &:last-of-type {
        .titleName {
          color: #cc0900;
        }

        .iconStyle {
          // height: 7px;
          width: 16px;
          transform: rotate(0deg) !important;
        }
      }
      // border-bottom: 1px solid #dddcdc;

      .titleName {
        color: #696969;
        font-family: Lato;
        font-size: $nine-pixel;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: $twelve-pixel;
        text-align: center;
        cursor: pointer;
      }

      .iconStyle {
        // height: 7px;
        width: 14px;
        transform: rotate(0deg) !important;
      }
    }
  }
}

.buttonStyles {
  color: #696969;
  font-family: "Lato";
  font-size: $eleven-pixel;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 12px;
  text-align: right;
  background-color: transparent !important;
  box-shadow: none;
  outline: none;
  border: none;
}

.customStyle {
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.flightAccord {
  position: relative;
  margin-top: 10px;
  .customFlightStyles {
    padding-bottom: 10px;
    @extend .customStyle;
    .summaryFlightClass {
      margin: 0 !important;
      padding-left: 10px !important;
      padding-right: 26px !important;
      .headingFlightComp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .dayAndCity {
          color: #272727;
          font-family: Lato;
          font-size: $eight-pixel + 0.5px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 11px;
        }
        .accordDesc {
          color: #272727;
          font-family: Lato;
          font-size: $twelve-pixel;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 15px;
        }
        .traveler {
          color: #272727;
          font-family: Lato;
          font-size: $nine-pixel;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 12px;
        }
        .planeDepartureIcon {
          // height: 14px;
          width: 18px;
          cursor: pointer;
          margin-right: 20px;
        }
      }
    }
  }
  .accordFlightDetailsClass {
    padding: 0px !important;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 6.3px;
    margin-top: 12.45px;
    margin-bottom: 9px;
    padding-top: 6.3px;
    padding-bottom: 6.3px;
    padding-right: 0px;
    // border: 0.5px solid #979797;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%;

    .twoSectionsContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;
    }
    .card3 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .flightBtn {
        font-family: Lato;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-transform: none;
        padding: 0;
        // margin-right: -60%;
        .viewDetails {
          color: #696969;
          font-family: Lato;
          font-size: $six-pixel + 0.6px;
          font-weight: bold;
          letter-spacing: 0.2px;
          line-height: 8px;
          text-align: center;
        }
        .iconAndText {
          display: flex;
          flex-direction: column;
          align-items: center;
          .expIcon {
            width: 14px;
            height: 10px;
            cursor: pointer;
            margin-bottom: 5px;
          }
        }
      }
      .flightBtn:hover {
        background-color: transparent !important;
      }
    }
    .hotelImg {
      // width: 66px;
      height: 63.23px;
      border: 0.51px solid #979797;
      border-radius: 4px;
      box-sizing: border-box;
      object-fit: cover;
      aspect-ratio: 1;
    }
    .packageImg {
      width: 40px;
      height: 40px;
      border: 0.51px solid #979797;
      border-radius: 8px;
      box-sizing: border-box;
    }
    .contentCard {
      position: relative;
      display: block;
      // margin-top: -8px;
      // min-width: 130px;
      // max-width: 100%;
      .starContainer {
        display: flex;
        align-items: center;
        gap: 2.5px;
        margin-left: -3px;

        .starPropertyImg {
          width: 9px;
          @media screen and (min-width: 768px) {
            width: 16.35px;
          }
        }
        .star {
          color: #272727;
          font-family: Lato;
          font-size: $six-pixel;
          letter-spacing: 0.12px;
          line-height: 5.4px;

          @media screen and (min-width: 768px) {
            font-size: $ten-pixel + 0.8px;
            letter-spacing: 0.221px;
          }
        }
      }
      .hotelName {
        color: #252525;
        font-family: lato;
        font-size: $nine-pixel + 0.4px;
        letter-spacing: 0.64px;
        line-height: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        margin-top: 5px;

        // width: 130px;
      }
      .location {
        color: #252525;
        font-family: Lato;
        font-size: $seven-pixel + 0.4px;
        letter-spacing: 0.19px;
        line-height: 10.44px;
        text-transform: uppercase;
        // margin-top: -4px;
        margin-bottom: 8px;
      }

      .roomDetailsContainer {
        display: flex;
        flex-direction: column;

        .room {
          font-family: Lato;
          font-weight: bold;
          color: #252525;
          font-size: $six-pixel + 0.23px;
          line-height: 7.5px;
          letter-spacing: 0.13px;
        }
      }
      .amenities {
        color: #696969;
        font-family: Lato;
        font-size: $six-pixel + 0.6px;
        letter-spacing: 0.2px;
        line-height: 8px;
      }
    }
  }
}

.footerSection {
  width: 100%;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tapAboveToSelect {
    text-align: center;
    color: #cc0900;
    font-family: Lato;
    font-weight: bold;
    font-size: 12.06px;
    margin-bottom: 18px;
  }
  .hoteltBtn {
    padding-left: 20px;
    padding-right: 20px;

    &.notSelected {
      opacity: 0.2;
    }
  }
  .ignoreStyles {
    margin-top: 11px;
    text-align: center;
    color: #696969;
    font-family: Lato;
    font-size: $eleven-pixel;
    font-weight: 400;
  }
}

.noHotel {
  text-align: center;
  color: #696969;
  font-family: Lato;
  font-size: 14px;
}

.masterCard {
  color: #696969 !important;
}
