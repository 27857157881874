.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    .title {
        color: #696969;
        font-family: Lato;
        text-transform: none;
        font-size: 14px;
        font-family: Lato;
        font-weight: 600;
        letter-spacing: 0.5px;
    }

    .chipCard {
        display: flex;
        gap: 1vh;
        width: 100%;
        flex-wrap: wrap;
        max-width: 400px;
        margin-top: 2vh;
        padding-bottom: 2vh;

        .activeChip {
            color: #222222;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            font-family: Lato;
            background-color: #A4D1F0;
            border-radius: 40px;
            border-color: #A4D1F0;
            padding-left: 5px;
            padding-right: 5px;
            letter-spacing: 0.2px;
        }

        .chip {
            color: #696969;
            font-size: 14px;
            line-height: 1.2;
            font-family: Lato;
            background-color: #ffff;
            border-radius: 40px;
            font-weight: 600;
            padding-left: 5px;
            padding-right: 5px;
            letter-spacing: 0.2px;
            border-color: #A4D1F0;
        }
    }

    .seeMore {
        font-size: 14px;
        line-height: 1.2;
        color: #00B3C5;
        font-family: Lato;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.2px;
    }
}

.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}