.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .backBtn {
    color: #696969;
    font-family: Lato;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.4px;
    line-height: 12px;
    opacity: 0.9;
    .backIcon {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
  .backBtn:hover {
    background-color: transparent !important;
    color: #696969;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
