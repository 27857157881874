@import "/src/variables/font_size";
.noFlight {
  text-align: center;
  color: #696969;
  font-family: Lato, sans-serif;
  font-size: 14px;
}

.grid {
  padding-bottom: 13px;
  padding-left: 30px;
  display: flex;
  gap: 10px;
  & p {
    color: #272727;
    font-family: Lato, sans-serif;
    font-size: $twelve-pixel;
  }
  & h1 {
    font-family: Lato, sans-serif;
    font-size: $twelve-pixel;
    font-weight: bold;
    color: #272727;
  }
  & h2 {
    font-family: Lato, sans-serif;
    font-size: $twelve-pixel;
    font-weight: 500;
    color: #272727;
  }
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  .container {
    position: relative;
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding-right: 10px;
      .buttonStyles {
        color: #696969;
        font-family: Lato, sans-serif;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.2px;
        line-height: 12px;
        text-align: right;
        background-color: transparent !important;
        box-shadow: none;
        outline: none;
        border: none;
      }
      .headerBtn {
        @extend .buttonStyles;
        .flightIcon {
          height: 20px;
          width: 25px;
        }

        .editIcon {
          height: 20px;
          width: 20px;
        }
      }
      .headerBtn:hover {
        background-color: transparent !important;
        box-shadow: none;
        outline: none;
        border: none;
      }
    }
    .customStyle {
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .flightAccord {
      position: relative;
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .flightHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 18px;
        @media screen and (max-width: 600px) {
          padding-right: 10px;
        }
        .selected {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .modification {
            opacity: 1;
            color: #cc0900;
            font-family: Lato;
            font-size: $ten-pixel;
            font-weight: $bold;
            font-style: Bold;
            letter-spacing: 0.2px;
            text-align: left;
            text-transform: uppercase;
            @media screen and (max-width: 600px) {
              font-size: $nine-pixel;
            }
          }
          .flightSelection,
          .confirmed {
            opacity: 1;
            color: rgba(204, 9, 0, 1);
            font-family: Lato;
            font-size: 9px;
            font-weight: 600;
            font-style: Bold;
            letter-spacing: 0.24px;
            text-align: left;
            text-transform: uppercase;
          }
          .confirmed {
            color: #696969 !important;
          }
        }
      }
      .customFlightStyles {
        @extend .customStyle;
        .summaryFlightClass {
          // margin: 0 !important;
          padding-left: 10px !important;
          padding-right: 26px !important;
          .headingFlightComp {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .dayAndCity {
              color: #272727;
              font-family: Lato;
              font-size: 8.5px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 11px;
            }
            .accordDesc {
              color: #272727;
              font-family: Lato;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 15px;
            }
            .traveler {
              color: #272727;
              font-family: Lato;
              font-size: 9px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 12px;
            }
            .planeDepartureIcon {
              height: 14px;
              width: 20px;
              cursor: pointer;
              margin-right: 20px;
            }
          }
        }
      }
      .accordFlightDetailsClass {
        padding: 0px !important;
      }
      .stepper {
        padding: 0px !important;
        .stepperDetails {
          padding-left: 40px;
          padding-top: 20px;
          padding-bottom: 25px;
          padding-right: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .bagageAndFareRules {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;
      padding-right: 30px;
      .baggage {
        opacity: 1;
        color: #1b75ba;
        font-family: Lato;
        font-size: $twelve-pixel;
        font-weight: $bold;
        font-style: Bold;
        letter-spacing: 0.77px;
        text-align: left;
        cursor: pointer;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
