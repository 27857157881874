.main {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .container {
        position: relative;
        display: block;
        padding-left: 2px;
        padding-right: 2px;
        .continue {
            border-radius: 2.6px;
            background-color: #FCB400;
            width: 100%;
            height: 66.66px;
            box-shadow: none;
            color: #222222;
            font-family: Lato;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 22px;
            text-align: center;
            margin-bottom: 20px;
        }
        .select {
            border-radius: 2.6px;
            border: 1px solid #979797;
            background-color: #FFFFFF !important;
            width: 100%;
            height: 66.66px;
            color: #222222;
            font-family: Lato;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.28px;
            line-height: 16px;
            text-align: center;
            box-shadow: none;
        }
        .selectInfo {
            margin-top: 20px;
        }
    }
}
.main::before,
.main::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}