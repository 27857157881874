.main {
  margin: 0;
  padding: 0;
  .container {
    padding: 20px;
    position: relative;
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      .landingImg {
        height: 242px;
        width: 363px;
        border-radius: 10px;
        border: 1px solid #979797;
      }
      .descCard {
        .title {
          color: #222222;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.21px;
          line-height: 14px;
          text-align: center;
          margin-bottom: 30px;
        }
        .description {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            gap: 5px;
          .desc {
            color: #6b6b6b;
            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 12px;
            text-align: left;
          }
          .star {
            color: #cc0900;
            font-family: Lato;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 16px;
            margin-top: 0px;
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
