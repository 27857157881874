.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  max-width: 400px;

  padding: 35px;
  padding-top: 0;
  height: 100vh;

  .cancellationPolicyContainer {
    width: 100%;
    .header {
      font-weight: bold;
      font-family: Lato;
      font-size: 11px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
    }

    .policyContent {
      //   font-weight: bold;
      font-family: Lato;
      font-size: 11px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
      margin-top: 17px;
    }

    .refundContainer {
      width: 100%;
      font-weight: bold;
      font-family: Lato;
      font-size: 12px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
      margin-top: 17px;
      display: flex;
      justify-content: space-between;
      // gap: 50px;
      padding-right: 90px;
    }
    .refundContainerSecond {
      // font-weight: bold;
      font-family: Lato;
      font-size: 10px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
      display: flex;
    }
  }
  .title {
    font-weight: 600;
    font-family: Lato;
    font-size: 18px;
    color: #f2f2f7;
    letter-spacing: 0.2px;
  }
  .subTitle {
    font-weight: 500;
    font-family: Lato;
    font-size: 14px;
    color: #f2f2f7;
    letter-spacing: 0.2px;
    text-align: center;
  }
  .btnCard {
    display: flex;
    gap: 70px;
    padding-top: 10px;
    .cancelBtn {
      padding-top: 5px;
      padding-bottom: 6px;
      padding-left: 40px;
      padding-right: 40px;
      background-color: transparent;
      font-size: 12px;
      font-family: Lato;
      font-weight: bold;
      letter-spacing: 0.2px;
      box-shadow: none;
      color: #f9f9f9;
      border: 1px solid #979797;
      outline: none;
      border-radius: 50px;
      cursor: pointer;
    }
    .discBtn {
      padding-top: 5px;
      padding-bottom: 6px;
      padding-left: 40px;
      padding-right: 40px;
      outline: none;
      border: none;
      text-align: center;
      border-radius: 50px;
      background-color: #d31e1e;
      font-size: 12px;
      font-family: Lato;
      font-weight: bold;
      letter-spacing: 0.2px;
      cursor: pointer;
      color: #f2f2f7;
    }
  }
}
