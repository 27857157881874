@import "/src/variables/font_size";

.main {
}
.mainHeaderContainer{
  display: flex;
  justify-content: space-between;
}

.editActivitiesText{
  padding-left: 25px;
  & h1 {
    font-size: 18px;
    color: #ffffff;
    font-weight: $bold;
  }
}

.dialogContainer {
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  width: 350px;
  gap: 9px;
  @media screen and (max-width: 415px) {
    width: max-content;
    width: 80vw;
  }
}

.headingContainer {
  padding-top: 5px;
  & h1 {
    font-size: $nineteen-pixel;
    color: #272727;
    font-weight: 600;
  }
  & p {
    color: #272727;
    font-weight: 600;
    font-size: 13px;
  }
}


.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding-top: 25px;
  .button {
    border-radius: 25px;
    font-size: $ten-pixel;
    font-family: Lato;
    padding: 5px 16px;
    font-weight: 900;
    color: #272727;
  }
  .saveBtn:hover {
    background-color: #fcb400;
  }
  .saveBtn {
    background-color: #fcb400;
  }
}


.activityContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}
