@import "/src/variables/font_size";
.main {
  margin: 0;
  padding: 0;
  .container {
    height: 60px;
    padding-left: 20px;
    padding-right: 10px;
    @media screen and (max-width: 600px) {
      height: 50px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .headerCard {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .imgCard {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        height: 100%;
        .imgTag {
          width: auto;
          height: 24px;
        }
        .title {
          font-size: 14px;
          font-family: Lato;
          letter-spacing: 0.2px;
          line-height: 16px;
          color: #696969;
          font-weight: bold;
          text-align: left;
          opacity: 1;
        }
      }
      .selectPac {
        color: #696969;
        font-family: lato, sans-serif;
        font-size: 8px;
        letter-spacing: 0.3px;
        line-height: 10px;
        text-align: center;
        font-weight: 600;
      }
      .price {
        opacity: 1;
        color: #272727;
        font-family: "Lato-Regular", sans-serif;
        font-size: $eleven-pixel;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.2px;
        text-align: right;
        line-height: $fourteen-pixel;
        .numberOfTravel {
          font-size: $twelve-pixel;
          line-height: $fourteen-pixel;
          font-weight: $bold;
          color: #272727;
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
