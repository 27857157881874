@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .btnStyles {
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    width: 206px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  .btn {
    @extend .btnStyles;
    background-color: #ffffff;
    .calendarIcon {
      height: 15px;
      width: 13px;
    }
    .arrowRightIcon {
      height: 10px;
      width: 10px;
      transform: rotate(180deg);
      margin-left: 5px;
      margin-right: 5px;
    }
    .btnName {
      color: #222222;
      font-family: Lato;
      font-size: $ten-pixel;
      font-weight: bold;
      letter-spacing: 0.71px;
      line-height: $thirteen-pixel;
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
