.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  .headingStyles {
    color: #696969;
    font-family: Lato;
    font-size: 12px;
    letter-spacing: 0.21px;
    line-height: 14px;
    // text-transform: capitalize;
  }
  .container {
    width: max-content;
    min-width: 120px;
    height: max-content;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    .contentCard,
    .layCard {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      // margin-bottom: 15px;
      .iconCard {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          width: 20px;
          height: 20px;
        }
      }

      .imgIcon {
        width: 40px;
        height: 40px;
        border: 0.51px solid #979797;
        border-radius: 8px;
        box-sizing: border-box;
        object-fit: contain;
      }
      display: flex;
      align-items: center;
      .detailsCard {
        position: relative;
        .title {
          @extend .headingStyles;
          padding-bottom: 2px;
          font-weight: bold;
          .subTitle {
            font-weight: 500;
          }
        }
        .desc {
          @extend .headingStyles;
        }
      
      }
    }
    .layCard {
      background-color: rgba(255, 206, 7, 0.2);
      border-radius: 4px;
      border: 1px solid #ffce07;
      display: flex;
      align-items: center;
      .iconCard {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .destinationImg {
      display: block;
      height: 80px;
      width: 170px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    }
  }
}
