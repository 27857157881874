.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    opacity: 0;
  }
  .inputBox {
    width: 100%;
    position: absolute;
    .inputField1 {
      color: #696969;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      font-family: Lato;
      letter-spacing: 0.5px;
      width: 175px;
      height: 40px;
      padding: 10px;
      border-radius: 4px;
      border: 1.5px solid #a3a9ad;
      outline: none;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      box-shadow: 0px 0px 20px -18px;
      @media only screen and (min-width: 767px) {
        width: 200px;
      }
    }
    .inputField1::placeholder {
      font-size: 14px;
      font-weight: 500;
      font-family: Lato;
      color: #a3a9ad;
    }

    .inputField1::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
      font-size: 14px;
    }
    .inputField {
      color: #696969;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      font-family: Lato;
      letter-spacing: 0.5px;
      width: 200px;
      height: 40px;
      padding: 10px;
      border-radius: 4px;
      border: 1.5px solid #a3a9ad;
      outline: none;
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      box-shadow: 0px 0px 20px -18px;
      @media only screen and (min-width: 767px) {
        width: 200px;
      }
    }

    .inputField::placeholder {
      font-size: 14px;
      font-weight: 500;
      font-family: Lato;
      color: #a3a9ad;
    }

    .inputField::-ms-input-placeholder {
      /* Microsoft Edge */
      color: gray;
      font-size: 14px;
    }
    .listCard,
    .listCard1 {
      position: relative;
      z-index: 10;
      background-color: #fff;
      border-radius: 4px;
      margin-top: 5px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      @media only screen and (min-width: 767px) {
        width: 250px;
      }
      .ulList {
        list-style-type: none;
        padding: 10px;

        .lists {
          color: #000000;
          font-weight: 600;
          font-family: Lato;
          padding-bottom: 10px;
          font-size: 14px;
          letter-spacing: 0.5px;
          cursor: pointer;
        }
      }
    }
    .listCard {
      width: 200px;
    }
    .listCard1 {
      width: 200px;
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
