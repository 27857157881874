.container {
  border-radius: 10px;
  background-color: #FEFEFE;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 20vh;
  .label {
    font-weight: 600;
  }
  .textArea {
    padding: 10px;
    color: #696969;
    font-size: 12px;
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    outline-color: #2596be;
    outline-width: 1px;
    resize: vertical;
  }
  .button {
    margin-top: -10px;
    display: flex;
    justify-content: center;
  }
}
