.loaderContainer {
  cursor: pointer;
  @media screen and (max-width: 600px) {
    cursor: default;
  }
  // height: 265px;
  // max-width: 411px;
  //   width: 180px;
  position: relative;
  // width: calc(50% - 5px);
  width: 100%;
  // height: 180px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid rgba(189, 189, 189, 0.3);
  overflow: hidden;
  // aspect-ratio: 1.4 / 1;
  height: 146.6px;
  // width: 207px;

  // @media screen and (min-width: 700px) {
  //   margin-top: 15px !important;
  // }
  margin-top: 2px;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainContainer {
  margin-top: 2px;
  margin-bottom: 1px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    cursor: default;
  }
  // height: 265px;
  // max-width: 411px;
  //   width: 180px;
  position: relative;
  // width: calc(50% - 5px);
  width: 100%;
  // height: 180px;
  flex-shrink: 0;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  // aspect-ratio: 1.4 / 1;
  height: 146.6px;
  // width: 207px;

  // @media screen and (min-width: 700px) {
  //   margin-top: 15px !important;
  // }
  .loaderSection {
    position: absolute;
    z-index: 20;
    top: 45%;
    bottom: 55%;
    left: 50%;
    right: 50%;
  }

  .selectBox {
    border: 1px solid #272727;
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
  }
  .selectedBox {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: grid;
    place-items: center;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background-color: #272727;
      opacity: 0.5;
    }
  }

  .imageContainer {
    position: relative;
    width: 100%;
    // height: 180px;
    aspect-ratio: 1 / 1.08;
    // overflow: hidden;
    .image {
      width: 100%;
      // height: 180px;
      aspect-ratio: 1 / 1.08;
      object-fit: cover;
    }
    .placeholderImageContainer {
      width: 100%;
      // height: 180px;
      height: 100%;
      // background-color: #696969;
      // background-color: #d8d8d8;
      // background-color: #eee;
      // display: flex;
      // justify-content: center;
      display: flex;
      .placeholderImage {
        // height: 100px;
        // width: 100px;
        // color: #eee;
        // color: #d8d8d8;
      }
    }
  }
  .descriptionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    .descriptionText {
      padding: 25px 0;
      padding-left: 15px;
      color: #272727;
      font-family: Lato;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }
    .nextBtn {
      padding-right: 15px;
      height: 40px;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        cursor: default;
      }
    }
  }
}

.placeHeadingContainerSelected {
  height: 100%;
}

.placeHeadingContainer {
  min-height: 35px;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px;

  .textContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;

    .placeHeadingMob {
      color: #ffffff;
      font-family: Lato;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      display: flex;
      z-index: 1;
    }
    .placeHeadingMobSelected {
      position: absolute;
      top: 39%;
      bottom: 50%;
      // transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      .countryName {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;
      }
    }

    .priceText {
      z-index: 1;
      flex-shrink: 0;
      color: #f4f6f8;
      font-family: Lato;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.32px;
      line-height: 18px;
      text-align: center;
    }
    .priceTextSelected {
      position: absolute;
      bottom: 15px;
      left: 0px;
    }
  }
}
.nextIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 15px;
  z-index: 1;
}

.hidden {
  visibility: hidden;
}

.selectedIcon {
  z-index: 2;
  height: 40px;
  position: absolute;
  bottom: 15px;
  right: 20px;
}
