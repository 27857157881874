.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: #ffff;
  border-bottom: 1.5px solid #dcdcdc;
  padding-left: 35px;
  padding-right: 30px;
  @media only screen and (min-width: 1000px) {
    padding-right: 5px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 6%;
    padding-right: 6%;
  }

//   @media only screen and (min-width: 768px) and (max-width: 1024px) {
//     padding-left: 30px;
//     padding-right: 30px;
//   }

  .logo {
    width: 95px;
    height: 20px;
    cursor: pointer;
  }

  .avatar {
    color: #7cbeea;
    background-color: #ffffff;
    border-color: #7cbeea;
    border: 1px solid #7cbeea;
    cursor: pointer;
  }
}
