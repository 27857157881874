.main {
  z-index: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
    margin-top: 16px;
  }
  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
    margin-top: 16px;
  }
  .container {
    width: 100vw;
    @media only screen and (min-width: 767px) {
      max-width: 350px;
    }
    .subContainer {
        width: 100%;
        .card {
            margin-bottom: 16px;
            margin-right: 10px;
            align-self: flex-end;
            .closeIcon {
                width: 25px;
                cursor: pointer;
            }
        }
    }
  }
}
