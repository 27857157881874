@import "/src/variables/font_size";

.arrivalDateContainer {
  display: flex;
  align-items: center;
  gap: 25px;
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  .customStyle {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .flightAccord {
    position: relative;
    .customFlightStyles {
      @extend .customStyle;
      .summaryFlightClass {
        padding-left: 10px !important;
        padding-right: 26px !important;
        .headingFlightComp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 5px;
          .accordTitleCard {
            position: relative;
            .dayAndCity {
              color: #272727;
              font-family: Lato;
              font-size: 8.5px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 11px;
            }
            .accordDesc {
              color: #272727;
              font-family: Lato;
              font-size: 12px;
              font-weight: $bold;
              letter-spacing: 0;
              line-height: 15px;
              .rightArrow,
              .leftArrow {
                width: auto;
                height: 8px;
                margin-left: 5px;
                margin-right: 5px;
              }
              .leftArrow {
                transform: rotate(180deg);
              }
            }
          }
          .statusCard {
            .inProgress {
              color: #cc0900;
              opacity: 1;
              font-family: Lato;
              font-size: $ten-pixel;
              font-weight: $bold;
              font-style: Bold;
              letter-spacing: 0.2px;
              text-align: center;
              margin-right: 20px;
              text-transform: uppercase;
            }
            .planeDepartureIcon,
            .planeArrivalIcon {
              height: 14px;
              width: 20px;
              cursor: pointer;
              margin-right: 20px;
            }
            .planeArrivalIcon {
              transform: rotate(180deg) !important;
            }
          }

          .traveler {
            color: #272727;
            font-family: lato;
            font-size: 9px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 12px;
          }
        }
      }
    }
    .accordFlightDetailsClass {
      padding: 0px !important;
    }
    .stepper {
      padding: 0px !important;
      .stepperDetails {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-left: 50px;
        padding-top: 20px;
        padding-bottom: 25px;
        padding-right: 10px;
      }
      .stopsDetails {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// STEPPER

// .main {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   position: relative;
//   overflow: hidden;
//   margin-bottom: 0px;
//   .stepper {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 20px;
//     // width: 100%;
//     width: 325px;
//     position: relative;

//     .step {
//       display: flex;
//       align-items: flex-start;
//       gap: 20px;
//       position: relative;

//       .circle {
//         height: 12px;
//         width: 12px;
//         border-radius: 50%;
//         border: 1.5px solid #696969;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         background-color: white;
//         color: #696969;
//         z-index: 2;
//       }

//       .stepContent {
//         display: flex;
//         flex-direction: column;
//         position: relative;
//         width: 300px;
//         .title {
//           display: flex;
//           flex-wrap: wrap; /* Allow items to wrap */
//           color: #696969;
//           font-family: Lato;
//           font-size: 14px;
//           letter-spacing: 0.25px;
//           line-height: 20px;
//           margin-top: -4px;
//           overflow-wrap: break-word; /* Ensure long words wrap */
//           word-wrap: break-word; /* Legacy support */
//           word-break: break-word; /* Ensure words break */
//           white-space: normal; /* Ensure wrapping happens */
//           padding-bottom: 3px;
//           .airportCode {
//             font-weight: bold;
//           }
//           .airportName {
//             font-weight: 500 !important;
//           }
//         }

//         .description,
//         .delayDescription {
//           font-family: Lato;
//           font-size: 10px;
//           font-weight: bold;
//           letter-spacing: 0.25px;
//           line-height: 12px;
//         }
//         .description {
//           color: #696969;
//         }
//         .delayDescription {
//           color: #bf150d;
//         }

//         .viewDetailsButton {
//           position: absolute;
//           width: max-content;
//           right: 0;
//           top: 20px;
//           color: #696969;
//           font-family: Lato;
//           font-size: 10px;
//           letter-spacing: 0.2px;
//           line-height: 12px;
//           @media screen and (max-width: 700px) {
//             left: 55vw;
//           }
//           .viewIcon,
//           .hideIcon {
//             height: 8px;
//             width: 14px;
//           }
//           .hideIcon {
//             transform: rotate(180deg);
//           }

//           &:hover {
//             background-color: transparent !important;
//           }
//         }

//         .card {
//           width: 400px;
//           // min-height: 100px;
//           // padding: 10px;

//           margin-top: 20px;
//           // @media only screen and (max-width: 600px) {
//           //   width: 40vw;
//           //   min-width: 280px;
//           // }
//           .subCard {
//             display: flex;
//             gap: 20px;
//             flex-direction: column;
//           }
//           .seatCard {
//             display: flex;
//             align-items: flex-start;
//             justify-content: flex-start;
//             gap: 20px;
//             .boardingBtn {
//               color: #696969;
//               font-family: Lato;
//               font-size: 7.5px;
//               font-weight: bold;
//               letter-spacing: 0.2px;
//               line-height: 20px;
//               margin-top: -10px;
//               .passIcon {
//                 width: 20px;
//                 height: 28px;
//               }
//             }
//           }
//         }
//       }

//       .line {
//         position: absolute;
//         top: 16px;
//         left: 4.9px;
//         width: 1.5px;
//         height: 100%;
//         background-color: #696969;
//         z-index: 1;
//       }
//       .dashLine {
//         position: absolute;
//         top: 16px;
//         left: 4.9px;
//         width: 1.5px;
//         height: 100%;
//         border-left: 2px dashed #ffce07;
//         z-index: 1;
//       }
//     }
//   }
// }
