.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // display: block;
  position: relative;
  // background-color: blue;

}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}
