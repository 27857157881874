.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  .container {
    width: 100%;
    height: 100%;
    .beforeStyles {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    .cardStyles {
      padding-left: 22px;
      width: 100%;
      height: 211px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      * {
        position: relative;
        z-index: 2;
      }
    }
    .weatherHead,
    .flightRedHead,
    .flightBlueHead {
      @extend .cardStyles;
      .backBtn {
        color: #ffffff;
        font-family: Lato;
        font-size: 10px;
        letter-spacing: 0.64px;
        line-height: 12px;
        margin-top: 70px;
      }
      .contentCard {
        display: flex;
        align-items: center;
        gap: 15px;
        z-index: 2;
        margin-top: 48px;
        .planeIcon {
          height: 24px;
          width: 26px;
        }
        .descCard {
          width: 90%;
          // text-wrap: wrap;
          .title,
          .desc {
            color: #ffffff;
            font-family: Lato;
            font-size: 9px;
            letter-spacing: 0.2px;
            line-height: 11x;
            width: 80%;
            word-wrap: break-word;
            
          }
          .title {
            font-weight: bold;
            margin-bottom: 2px;
            text-transform: uppercase;
          }
          .desc {
            opacity: 0.8;
          }
        }
      }
    }
    .weatherHead {
      &::before {
        @extend .beforeStyles;
      }
    }
    .flightRedHead {
      &::before {
        @extend .beforeStyles;
        background-color: rgba(255, 0, 0, 0.6);
        // background-color: rgba(204, 9, 0, 0.3);
      }
    }
    .flightBlueHead {
      &::before {
        @extend .beforeStyles;
        background-color: rgba(21, 78, 121, 0.5);
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
