.editDialogContainer {
  @media screen and (min-height: 585px) and (max-height: 640px) {
    padding-top: 10dvh;
    padding-bottom: 3dvh;
  }
  @media screen and (max-height: 584px) {
    padding-top: 18dvh;
    padding-bottom: 3dvh;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    padding: 20px;
    padding-top: 0;
    height: 100vh;
    .title {
      font-weight: 600;
      font-family: Lato;
      font-size: 18px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
    }
    .subTitle {
      font-weight: 500;
      font-family: Lato;
      font-size: 14px;
      color: #f2f2f7;
      letter-spacing: 0.2px;
      text-align: center;
    }
    .btnCard {
      display: flex;
      gap: 20px;
      padding-top: 10px;
      .cancelBtn {
        padding-top: 5px;
        padding-bottom: 6px;
        padding-left: 30px;
        padding-right: 30px;
        background-color: transparent;
        font-size: 12px;
        font-family: Lato;
        font-weight: 500;
        letter-spacing: 0.2px;
        box-shadow: none;
        color: #f9f9f9;
        border: 1px solid #979797;
        outline: none;
        border-radius: 50px;
        cursor: pointer;
      }
      .discBtn {
        padding-top: 5px;
        padding-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        outline: none;
        border: none;
        text-align: center;
        border-radius: 50px;
        background-color: #d31e1e;
        font-size: 12px;
        font-family: Lato;
        font-weight: 500;
        letter-spacing: 0.2px;
        cursor: pointer;
        color: #f2f2f7;
      }
    }
  }
}
