@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .label {
    color: #696969;
    font-family: Lato;
    font-size: $eight-pixel;
    font-weight: bold;
    letter-spacing: 0.58px;
    line-height: $ten-pixel;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-left: 2px;
  }
  .btnStyles {
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    width: 99px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  .btn,
  .activeTravelBtn {
    @extend .btnStyles;
    background-color: #ffffff;

    .calendarIcon {
      height: 15px;
      width: 13px;
    }
    .btnName {
      color: #222222;
      font-family: Lato;
      font-size: $ten-pixel;
      font-weight: bold;
      letter-spacing: 0.71px;
      line-height: $thirteen-pixel;
    }
  }
  .activeTravelBtn {
    @extend .btnStyles;
    background-color: #D8EFFF;
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
}
