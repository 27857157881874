.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 display: flex;
 gap: 10px;
 flex-direction: column;
}

.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: block;
}
