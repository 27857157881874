.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  background-color: #ffffff;
  height: 100vh;
  .container {
    // position: relative;
    // height: 100%;
    .subContainer {
      // width: 100%;
      // height: 100%;
      // overflow: auto;
      // scrollbar-width: thin;
      // scroll-behavior: smooth;
      padding-bottom: 200px;
      .selectedFlightCard {
        position: relative;
        display: block;
        padding: 26px 20px;
        .selectedFlight {
          color: #272727;
          font-family: Lato;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 13px;
          text-transform: capitalize;
        }
      }
      .titleCard {
        position: relative;
        display: block;
        padding: 25px 20px;
        .title {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: bold;
        }
        .desc {
          color: #272727;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 12px;
        }
      }

      .customStyle {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .flightAccord {
        .customFlightStyles {
          @extend .customStyle;
          .summaryFlightClass {
            margin: 0 !important;
            padding-left: 10px !important;
            padding-right: 26px !important;
            .headingFlightComp {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              .dayAndCity {
                color: #272727;
                font-family: Lato;
                font-size: 8.5px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 11px;
              }
              .accordDesc {
                color: #272727;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
              }
              .planeDepartureIcon {
                height: 14px;
                width: 20px;
                cursor: pointer;
                margin-right: 20px;
              }
            }
          }
        }
        .accordFlightDetailsClass {
          padding: 0px !important;
        }
        .stepper {
          padding: 0px !important;
          .stepperDetails {
            padding-left: 40px;
            padding-top: 20px;
            padding-bottom: 25px;
          }
          .divider {
            border-bottom: 1px solid rgba(105, 105, 105, 0.5);
          }
        }
      }

      .accord {
        .customStyles {
          // margin-bottom: 10px;
          // margin-top: 0;
          @extend .customStyle;
          .summaryClass {
            margin: 0 !important;
            padding-left: 10px !important;
            padding-right: 26px !important;
            .headingComp {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding-right: 20px;
              .accordTitleCard {
                position: relative;
                .dayAndCity {
                  color: #272727;
                  font-family: Lato;
                  font-size: 8.5px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 11px;
                  .city {
                    margin-left: 20px;
                  }
                }
                .accordDesc {
                  color: #272727;
                  font-family: Lato;
                  font-size: 12px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 15px;
                }
              }
              .widgets {
                display: flex;
                align-items: center;
                .flightFly {
                  height: 14px;
                  width: 20px;
                  cursor: pointer;
                  margin-right: 10px;
                }
              }
              .accordHeading {
                color: #272727;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 15px;
              }
              .travelerIcon {
                height: 22px;
                width: 22px;
              }
            }
          }
          .accordContent {
            padding-right: 20px;
            .accordContentCard {
              display: flex;
              align-items: center;
              gap: 0px;
              .inputBox {
                width: 60px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .customCheckbox {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 20px;
                  width: 20px;
                }

                .checkmark {
                  height: 20px;
                  width: 20px;
                  background-color: transparent;
                  border: 1.5px solid #696969;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  cursor: pointer;
                }

                .customCheckbox:checked + .checkmark {
                  background-color: transparent;
                }

                .customCheckbox:checked + .checkmark::after {
                  display: block;
                }

                .checkmark::after {
                  content: "";
                  width: 3px;
                  height: 8px;
                  margin-top: -2px;
                  border: solid #696969;
                  border-width: 0 1.5px 2px 0;
                  transform: rotate(45deg);
                  display: none;
                  cursor: pointer;
                }
              }
              .newCard {
                width: 60px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .newFlight {
                  height: 14px;
                  width: 20px;
                  cursor: pointer;
                }
                .new {
                  color: #bf150d;
                  font-family: Lato;
                  font-size: 8px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 10px;
                  text-align: center;
                }
              }
            }
          }
          .accordianDetailsClass {
            padding: 20px 2px;
          }
        }
      }
      .dayWiseItineraryCard,
      .dayWiseItineraryCardRemoveBorder {
        box-sizing: border-box;
        min-height: 70px;
        width: 100%;

        // box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 20px;
        margin-bottom: 10px;
        .morning {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          font-weight: bold;
        }
        .newList {
          margin-left: 12px;
          margin-bottom: 10px;
        }
        .listItem {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;
        }
        .description {
          color: #272727;
          font-family: Lato;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          text-align: left;
          margin-top: 0px !important;
          .highlightText {
            color: #007aff;
          }
        }
      }
      .dayWiseItineraryCard {
        border: 1px solid #efefef;
        border-radius: 3px;
        background-color: #ffffff;
        box-shadow: 0 1.1px 1.1px 0 rgba(0, 0, 0, 0.3);
        // box-shadow:
        //   rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        //   rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        // box-shadow: 0 0.6px 3.1px 0 rgba(0, 0, 0, 0.5);
      }
      .dayWiseItineraryCardRemoveBorder {
        box-shadow: none;
      }
      .activeBorder {
        border: 2px solid #0a84ff;
        box-shadow: none !important;
      }
      .btnCard {
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
        padding-bottom: 50px;
        padding-top: 20px;
        background-color: #ffffff;
        .continueBtn {
          height: 66px;
          border-radius: 3px;
          background-color: #fcb400;
          outline: none;
          border: none;
          color: #222222;
          font-family: Lato;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 22px;
          text-align: center;
          box-shadow: 0 1.2px 1.2px 0 rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
