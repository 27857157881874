@import "/src/variables/font_size";

.main {
  .container {
    .customStyle {
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .customFlightStyles {
      @extend .customStyle;
      margin-bottom: 10px !important;
      .summaryFlightClass {
        margin: 0 !important;
        padding-left: 10px !important;
        padding-right: 26px !important;
        .headingFlightComp {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          gap: 5px;
          .dayAndCity {
            color: #272727;
            font-family: Lato;
            font-size: 8.5px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 11px;
          }
          .accordDesc {
            color: #272727;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 15px;
          }
          .traveler {
            color: #272727;
            font-family: Lato;
            font-size: 9px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 12px;
          }
          .planeDepartureIcon,
          .planeArrivalIcon {
            height: 14px;
            width: 20px;
            cursor: pointer;
          }
          .planeArrivalIcon {
            transform: rotate(180deg);
          }
        }
        .stepContent {
          padding: 40px;
        }
      }
      .flightDetails {
        display: flex;
        gap: 10px;
        z-index: 9999 !important;
        padding: 20px 0px 0px 0px;
        .hideCheck {
          width: 20px;
          height: 20px;
        }
        .flightCard,
        .inActive {
          position: relative;

          .flightSubCard {
            position: relative;
            margin-bottom: 10px;
          }
          .flightCard2 {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            margin-left: 20px;
            margin-bottom: 10px;
            .backIcon {
              width: auto;
              height: 15px;
            }
          }
        }
        .flightCard {
          opacity: 1 !important;
        }
        .inActive {
          opacity: 0.4 !important;
        }
      }
    }
    .modifyCard {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      padding: 0px 0px 40px 0px;
      .info {
        height: 10px;
        opacity: 1;
        color: #cc0900;
        font-family: Lato;
        font-size: $thirteen-pixel;
        font-weight: $bold;
        font-style: Bold;
        letter-spacing: 0.3px;
        text-align: center;
      }
      .subInfo {
        opacity: 1;
        color: rgba(105, 105, 105, 1);
        font-family: Lato;
        font-size: $twelve-pixel;
        font-weight: 400;
        font-style: Regular;
        letter-spacing: 0.21px;
        text-align: center;
      }
    }
  }
}
