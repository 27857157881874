@import "/src/variables/font_size";
.mainRoomContainer {
  // border: 0.01px solid #979797;
  // box-shadow: 0px 0.05px 0.91px #979797 !important;
  border-radius: 3.73px;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  padding-top: 3.1px;
  padding-bottom: 12.45px;
  margin-bottom: 6.2px;

  &:last-child {
    margin-bottom: 0;
  }
}
.imageClass {
  aspect-ratio: 1 / 0.27 !important;
  object-fit: cover;
  // height: 94px;
}

.viewRoomDetailsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-right: 16.2px;

  .roomType {
    color: #252525;
    font-family: lato;
    font-size: $fifteen-pixel;
    //   line-height: 16vw;
    font-weight: bold;
    letter-spacing: 0.34px;
    // margin-top: 13px;
    padding-left: 11px;
    max-width: 67%;
    // width: 130px;
  }

  .amenitiesFooter {
    display: flex;
    align-items: center;
    gap: 7.2px;
    opacity: 0.9;
    cursor: pointer;
    justify-content: flex-end;

    color: #696969;
    font-family: Lato;
    font-size: 30px;
    font-size: $ten-pixel;
    letter-spacing: 0.19px;
    line-height: 11.2px;
    text-align: right;
    min-width: 130px;

    .amenityFooterIcon {
      width: 13px;
    }
  }
}

.showMoreContainer {
  width: 100%;
  text-align: center;
  cursor: pointer;

  color: #696969;
  font-family: Lato;
  font-size: $nine-pixel;
  font-weight: bold;
  letter-spacing: 0.24px;
  line-height: 15.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 15px;

  .expandIcon {
    width: 11px;
    margin-left: 8px;
    // background-color: #aaaaaa;
  }

  .rotateExpandIcon {
    width: 11px;
    margin-left: 8px;
    transform: rotate(180deg);
    transition: transform 1s ease-in-out;
  }
}

.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 13px;
  .container {
    padding-left: 3.11px;
    padding-right: 3.11px;
    width: 100%;
    height: 100%;
    .contentCard {
      width: 100%;
      // @media only screen and (min-width: 767px) {
      //   width: max-content;
      // }
      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      .hotelListCard {
        width: 100%;
        // height: 53px;
        border-radius: 3.8px;
        border: 0.31px solid #979797;
        // border-right: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // background-color: #ffff;
        // gap: 10px;
        overflow-x: auto;
        scrollbar-width: thin;
        // margin-bottom: 8px;
        overflow-y: hidden;
        padding: 15px 4px 15px 4px;
        .card1 {
          width: max-content;
          height: 100%;
          padding: 6px 5px;
          // background-color: #f9e9be;
          //   padding: 20px 10px;
          // padding-left: 6px;
          padding-left: 7.8px;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;

          // width: 180px;

          .typeName {
            font-family: Lato;
            font-size: $eleven-pixel;
            font-weight: bold;
            letter-spacing: 0.24px;
            line-height: 15.6px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            cursor: pointer;

            &.refundableType {
              color: #1b75ba;
            }
            .tickMarkIcon {
              height: 11px;
            }
          }
          .discountAndPriviliges {
            display: flex;
            flex-direction: column;
            gap: 2px;
            margin-top: 5px;
            .discountValidity {
              color: #bf150d;
              font-family: Lato;
              font-size: $six-pixel + 0.54px;
              letter-spacing: 0.14px;
              // line-height: 15.6px;
            }

            .includesArrContainer {
              display: flex;
              flex-direction: column;
              gap: 1.5px;

              color: #252525;
              font-family: Lato;
              font-size: $eight-pixel;
              // font-weight: bold;
              letter-spacing: 0.24px;
              line-height: 10.6px;

              .eachIncludes {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 7px;
                .tickMarkIcon {
                  width: 12px;
                }
              }
            }
          }
        }
        .card2 {
          padding: 1.2vh 0px;
          width: 60px;
          .stops {
            color: #696969;
            font-family: Lato;
            font-size: $eight-pixel;
            line-height: 12px;
            letter-spacing: 0.2px;
            font-weight: 600;
            margin-bottom: 5px;
            .stopInfo {
              font-weight: 500;
            }
          }
        }
        .card3Andcard4Container {
          display: flex;
          align-items: center;
          gap: 6.23px;
          .card3 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: 8.87px;
            .discount {
              background-color: #039627;
              width: 31.5px;
              height: 12.77px;
              display: flex;
              align-items: center;
              justify-content: center;

              color: #ffffff;
              font-family: Lato;
              font-size: $six-pixel + 0.2px;
              font-weight: bold;
              letter-spacing: 0.13px;
              // line-height: 7.47px;
              text-align: center;
            }

            .actualPrice {
              color: #a9a9a9;
              font-family: Lato;
              font-size: $eight-pixel + 0.4px;
              font-weight: bold;
              letter-spacing: 0.18px;
              // line-height: 33px;
              text-align: center;
              text-decoration: line-through;
              opacity: 0.8;
            }
            // .flightBtn {
            //   font-family: Lato;
            //   display: flex;
            //   flex-direction: column;
            //   align-items: center;
            //   justify-content: center;
            //   text-transform: none;
            //   .viewDetails {
            //     color: #696969;
            //     font-family: Lato;
            //     font-size: 6.6px;
            //     font-weight: bold;
            //     letter-spacing: 0.2px;
            //     line-height: 8px;
            //     text-align: center;
            //   }
            //   .iconAndText {
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     opacity: 0.8;
            //     color: #696969;
            //     .expIcon {
            //       width: 14px;
            //       height: 10px;
            //       cursor: pointer;
            //       margin-bottom: 5px;
            //     }
            //   }
            // }
            // .flightBtn:hover {
            //   background-color: transparent !important;
            // }
          }
          .bookBtn {
            padding: 6px 23.7px;
            font-size: 11px;
          }
          .verticalDivider {
            align-self: stretch;
            margin-top: -7px;
            margin-bottom: -7px;
            border-left: 1px solid #d8d8d8;
            transform: translateX(5px);
          }
          .selectedHotelPriceContainer {
            display: flex;
            align-items: center;
            margin: 0 8px 0 8px;
            gap: 5px;

            &.expandedSelectedRoomDetails {
              margin-left: 12px;
            }

            .circleCheckIcon {
              height: 27px;
              transform: translateY(1px);
            }
            .selectedPriceContainer {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              font-family: lato;
              color: #272727;
              // margin: 0 10px 0 15px;
              .hotelPrice {
                font-size: 20px;
                font-weight: bold;
              }

              .taxAndFeesContainer {
                font-size: 9px;
                font-weight: bold;
              }

              .selectBtn {
                padding: 8px 20px;
                margin-top: 15px;
              }
            }
          }
          .card4 {
            // width: 150px;
            // min-width: 100px !important;
            // max-width: 200px;
            width: 125px;
            height: 100%;
            background-color: #1d75bb;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            gap: 9.1px;
            border-top-right-radius: 3.8px;
            border-bottom-right-radius: 3.8px;

            padding-left: 9.3px;
            padding-right: 12.77px;
            padding-top: 9.3px;
            padding-bottom: 8.72px;
            .priceAndFlightContainer {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .price {
              color: #ffffff;
              font-family: Lato;
              font-size: $twelve-pixel + 0.4px;
              letter-spacing: 0.23px;
              line-height: 13px;
              text-align: center;
              font-weight: bold;
            }
            .flightClass {
              color: #ffffff;
              font-family: lato;
              font-size: $six-pixel + 0.2px !important;
              letter-spacing: 0.26px !important;
              line-height: 13.5px !important;
              text-align: center;
              margin-top: -2px !important;
              text-transform: capitalize;
              opacity: 0.95;
            }
            .cancellation {
              color: #ffffff;
              opacity: 0.95;
              font-family: Lato;
              font-size: $six-pixel + 0.23px;
              font-weight: bold;
              letter-spacing: 0.13px;
              line-height: 7.47px;
              text-transform: capitalize;
              // margin-bottom: 5px;
            }
            .bookBtn {
              cursor: pointer;
              width: 48px;
              height: 18.6px;
              border-radius: 9.6px;
              background-color: #fcb400;
              box-shadow: none;
              border: none;
              outline: none;
              font-size: $seven-pixel + 0.26px;
              // line-height: 8.7px;

              // font-size: 6px;
              // line-height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 0.5px;
              text-align: center;
              color: #222222;
              font-family: Lato;
              font-weight: bold;
              letter-spacing: 0.15px;
              font-family: lato;
              text-transform: uppercase;
            }
            .selectedBtn {
              cursor: pointer;
              width: 48px;
              height: 18.6px;
              border-radius: 9.6px;
              box-shadow: none;
              border: none;
              outline: none;
              font-size: $seven-pixel + 0.26px;
              // line-height: 8.7px;

              // font-size: 6px;
              // line-height: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding-top: 0.5px;
              text-align: center;
              font-family: Lato;
              font-weight: bold;
              letter-spacing: 0.15px;
              font-family: lato;
              text-transform: uppercase;
              color: #ffffff;
              background-color: #272727;
            }
          }
        }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
