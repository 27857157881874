.main {
  margin: 0;
  padding: 0;
  overflow-y: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
  background-color: #ffffff;
  // height: calc(100dvh - 65px);
  overscroll-behavior: none;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;
  .container {
    width: 100%;
    height: 100%;
    margin-bottom: 350px;
    .header {
      padding-left: 22px;
      padding-bottom: 5px;
      padding-top: 42px;
      .title {
        color: #252525;
        font-family: Lato;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.3px;
        line-height: 54px;
      }
    }
    .content {
      .customStyles {
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 2px;
        // margin-top: 0;
        // padding: 4px;

        .summaryClass {
          margin: 0 !important;
          padding-left: 10px !important;
          padding-right: 26px !important;
          .heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-right: 20px;
            padding-left: 3px;
            .accordHeading {
              color: #272727;
              font-family: Lato;
              font-size: 12px;
              font-weight: bold;
              font-weight: 800;
              letter-spacing: 0;
              line-height: 15px;
            }
            .travelerIcon {
              height: 22px;
              width: 22px;
            }
          }
        }

        .accordianDetailsClass {
          padding: 20px 2px;
        }
      }
      .btnCard {
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
        margin-top: 15px;
        .continueBtn {
          height: 66px;
          border-radius: 3px;
          background-color: #fcb400;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          outline: none;
          border: none;
          color: #222222;
          font-family: Lato;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.4px;
          line-height: 22px;
          text-align: center;
        }
        .continueBtn:disabled {
          background-color: #d5d5d5 !important;
        }
        // .continueBtn:active {
        //   transform: scale(0.99);
        // }
      }
    }
  }
}
.main::before,
.main::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}
.main::-webkit-scrollbar {
  display: none;
}
