.main {
  margin: 0;
  padding: 0;
  .container {
    height: 56px;
    background-color: #1b75ba;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 20px;
    .card1 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      .searchIcon {
        opacity: 1;
        width: 20px;
        height: 20px;
        pointer-events: none;
      }
      .bedIcon {
        height: 17px;
        width: 20px;
      }
      .details {
        position: relative;
        .detailsCard {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 200px;

          .city {
            color: #ffffff;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .desc {
          color: #ffffff;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0.2px;
          line-height: 12px;
        }
      }
    }
    .dropDowIcon {
      position: relative;
      .dropIcon {
        height: 7.5px;
        width: 14px;
        cursor: pointer;
      }
    }
  }
}
.main::after,
.main::before {
  margin: 0;
  padding: 0;
}
