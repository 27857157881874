@import "/src/variables/font_size";

.headingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  padding-bottom: 5px;
  height: 60px;
  flex-direction: column;
  & h1 {
    font-size: $twenty-pixel;
    color: #696969;
    font-family: Lato;
    font-weight: $bold;
  }
  & p {
    font-size: $seventeen-pixel;
    color: #272727;
    font-family: Lato;
    font-weight: $bold;
    align-self: flex-end;
    padding-right: 5px;
  }
}

.backbtnContainer {
  padding: 10px;
  border-bottom: 1px solid #e2e2e2;
}

.filterContainer {
  // border: 1px solid black;
  background-color: #1b75ba;
  padding: 16px;
}

.hide{
  visibility: hidden;
}

.main {
  .container {
    border: 1px solid rgba(151, 151, 151, 1);
    opacity: 1;
    background-color: rgba(244, 244, 244, 1);
    width: 100%;
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    .searchContainer {
      padding: 20px;
      .headerContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        .checkBox {
          display: flex;
          gap: 20px;
          padding: 20px 20px 20px 20px;
          .checkBoxLabel {
            opacity: 1;
            color: #696969;
            font-family: "Lato-Bold", sans-serif;
            font-size: $thirteen-pixel;
            font-weight: $bold;
            font-style: Bold;
            letter-spacing: 0.2px;
            text-align: left;
            text-transform: uppercase;
          }
        }
        .showDetailsBtn {
          display: block;
        }
      }
      .airportCard {
        padding: 0px 0px 20px 20px;

        .newInputCard2 {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          .rightLeftIcon {
            width: 14px;
            height: 14px;
            margin-top: 10px;
            pointer-events: none;
          }
        }
      }
      .searchBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.emptyCard {
  height: 100dvh;
  background: transparent;
  overflow: scroll;
}