.mainContainer{
  position: relative;
  // background-color: blue;
}

.adjustTrip {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    padding-left: 35px;
    .destinationCard{
      flex-grow: 1;
      // margin-right: 40px;
    }
  }
  
  .reorderCardContainer {
    display: flex;
    flex-direction: row;
  
    .dragIconContainer {
      // border: 1px solid green;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 0px;
      margin-right: -19px;
      height: 65px;
      // padding: 0 20px;
      width: 60px;
      align-self: flex-end;
      cursor: move;
      .dragTag {
        user-select: none;
        width: 12px;
        @media screen and (max-width: 600px) {
        }
      }
    }
  }
  