@import "/src/variables/font_size";

.main {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  height: 100px;
  background-color: #ffff;
  border: 1px solid #979797;
  border-radius: 2px;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 14px;
    padding-right: 14px;
    .innerCard {
      .departureTime {
        opacity: 1;
        color: rgba(34, 34, 34, 1);
        font-family: "Lato-Bold", sans-serif;
        font-size: $twelve-pixel;
        font-weight: $bold;
        font-style: Bold;
        letter-spacing: 0.2px;
        text-align: left;
        margin-bottom: 10px;
      }
      .departureTimes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #a4d1f0;
        border-radius: 20px;
        overflow: hidden;
        background-color: #fff;

        .option {
          flex: 1;
          height: 40px;
          cursor: pointer;
          border: none;
          background: none;
          transition:
            background-color 0.3s,
            color 0.3s;
          border-right: 1px solid #a4d1f0;
          opacity: 1;
          color: rgba(39, 39, 39, 1);
          font-family: "Lato-Bold", sans-serif;
          font-size: $ten-pixel;
          font-weight: $bold;
          font-style: Bold;
          letter-spacing: 0.2px;
          text-align: left;
          padding-left: 10px;
          padding-right: 30px;
          &:hover {
            background-color: #ffff;
          }

          &.selected {
            background-color: #d8efff;
            color: #272727;
          }

          &:first-of-type {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            border-right: 1px solid #a4d1f0;
          }

          &:last-of-type {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            border-right: none;
          }
        }
      }
    }
  }
}
