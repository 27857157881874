.mainContainer {
  position: relative;
  // height: 115px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 60px;
  width: 100vw;
  padding-right: 20px;
  background-color: #ffffff;
  z-index: 10;
  .logoCard {
    position: absolute;
    top: 25px;
    left: 20px;
  }
  .subContainer {
    display: none;
    color: #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 32px;
    padding-top: 24px;
    .logoContainer {
      padding-top: 5px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
      .logo {
        width: 120px;
        // margin-right: 15px;
        cursor: pointer;
        @media screen and (max-width: 767px) {
          cursor: default;
        }
      }
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: max-content;
  font-size: 10px !important;
  // transform: translateY(10px);
  font-weight: 700 !important;
  color: #696969 !important;
  font-family: Lato;
}

.adjustButton {
  margin-top: 20px;
}

.iconButton {
  width: 18px;
}
