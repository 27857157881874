@import "/src/variables/font_size";

.main {
  margin: 0;
  padding: 0;
  .card,
  .activeCard {
    box-sizing: border-box;
    height: 45px;
    width: 65px;
    pointer-events: none;
    border-radius: 5px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    .flightHotelIcon {
      height: 17px;
      width: 20px;
    }
    .selectStyles {
      font-family: Lato;
      font-size: $nine-pixel;
    //   font-weight: bold;
      letter-spacing: 0.3px;
      line-height: $eleven-pixel;
      text-align: center;
      text-transform: uppercase;
    }
    .select {
      color: #ffffff;
      @extend .selectStyles;
    }
    .notSelect {
      @extend .selectStyles;
      color: #cc0900;
    }
  }
  .activeCard {
    border: 1px solid #ffffff;
    background-color: #1f8539;
  }
  .card {
    border: 1px solid #cc0900;
    background-color: #ffffff;
  }
}
