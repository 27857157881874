// font size
$base-size: 0px;

$six-pixel: calc(6px + $base-size);
$seven-pixel: calc(7px + $base-size);
$eight-pixel: calc(8px + $base-size);
$nine-pixel: calc(9px + $base-size);
$ten-pixel: calc(10px + $base-size);
$eleven-pixel: calc(11px + $base-size);
$twelve-pixel: calc(12px + $base-size);
$thirteen-pixel: calc(13px + $base-size);
$fourteen-pixel: calc(14px + $base-size);
$fifteen-pixel: calc(15px + $base-size);
$sixteen-pixel: calc(16px + $base-size);
$seventeen-pixel: calc(17px + $base-size);
$eightteen-pixel: calc(18px + $base-size);
$nineteen-pixel: calc(19px + $base-size);
$twenty-pixel: calc(20px + $base-size);

// view width
$base-viewWidth-size: 0vw;
$sixty-pixel-viewPort: calc(3.75vw + $base-viewWidth-size);

// bold
$bold: 700;
