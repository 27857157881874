.modalHeader {
  //   height: 72px;
  //   width: 317px;
  color: #ffffff;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  //   line-height: 71px;
}

.tabContainer {
  display: flex;
  align-items: start;
  gap: 7%;

  @media screen and (max-width: 767px) {
    gap: 5%;
  }

  .tab {
    color: #9e9e9e;
    font-family: Lato;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.336px;
    line-height: 31.54px;
    text-transform: uppercase;

    @media screen and (max-width: 768px) {
      font-size: 13px;
    }

    &.selectedTab {
      // height: 90px;
      // width: 373px;
      color: #1b75ba;
      font-family: Lato;
      // font-size: 45px;
      font-weight: bold;
      // letter-spacing: 0.96px;
      // line-height: 90px;
    }
  }
}
