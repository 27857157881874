
.arrivalDateContainer{
  display: flex;
  align-items: center;
  gap: 25px;
}
.main {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-bottom: -15px;

  .stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    // width: 100%;
    // width: 325px;
    position: relative;
    
    .step {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      position: relative;

      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 1.5px solid #696969;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #696969;
        z-index: 2;
      }

      .stepContent {
        display: flex;
        flex-direction: column; 
        position: relative;
        width: 320px;
        .title {
          display: flex;
          flex-wrap: wrap; /* Allow items to wrap */
          color: #696969;
          font-family: Lato;
          font-size: 14px;
          letter-spacing: 0.25px;
          line-height: 20px;
          margin-top: -4px;
          overflow-wrap: break-word; /* Ensure long words wrap */
          word-wrap: break-word; /* Legacy support */
          word-break: break-word; /* Ensure words break */
          white-space: normal; /* Ensure wrapping happens */
          padding-bottom: 3px;
          width: 95%;
          .airportCode {
            font-weight: bold;
          }
          .airportName {
            font-weight: 500 !important;
          }
        }

        .description,
        .delayDescription {
          font-family: Lato;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 0.25px;
          line-height: 12px;
        }
        .description {
          color: #696969;
        }
        .delayDescription {
          color: #bf150d;
        }

        .viewDetailsButton {
          margin-left: 230px;
          position: absolute;
          width: max-content;
          // right: 0;
          // left: 55vw;
          top: 20px;
          color: #696969;
          font-family: Lato;
          font-size: 10px;
          letter-spacing: 0.2px;
          line-height: 12px;
          // border: 5px solid green;
          @media screen and (max-width: 700px) {
             left: 55vw;
             margin-left: 0px;
          }
          .viewIcon,
          .hideIcon {
            height: 8px;
            width: 14px;
          }
          .hideIcon {
            transform: rotate(180deg);
          }

          &:hover {
            background-color: transparent !important;
          }
        }

        .card {
          width: 400px;
          // min-height: 100px;
          // padding: 10px;
      
          margin-top: 20px;
          // @media only screen and (max-width: 600px) {
          //   width: 40vw;
          //   min-width: 280px;
          // }
          .subCard {
            display: flex;
            gap: 20px;
            flex-direction: column;
          }
          .seatCard {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 20px;
            .boardingBtn {
              color: #696969;
              font-family: Lato;
              font-size: 7.5px;
              font-weight: bold;
              letter-spacing: 0.2px;
              line-height: 20px;
              // width: 100%;
              margin-top: -10px;
              .passIcon {
                width: 20px;
                height: 28px;
              }
            }
          }
        }
      }

      .line {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        background-color: #696969;
        z-index: 1;
      }
      .dashLine {
        position: absolute;
        top: 16px;
        left: 4.9px;
        width: 1.5px;
        height: 100%;
        border-left: 2px dashed #ffce07;
        z-index: 1;
      }
    }
  }
}
